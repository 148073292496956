import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { __, wait, getElement, forceSatoshiFormat, playAudio } from "../../../Helper";
import { gameCoin } from "../../../actions/gameCoin";
import Wheel from "./components/Wheel";

var HASH = false;
const SPIN_BUTTON = "spin-to-win";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            wheel: new Wheel(this.props.engine, 11, 3, false),
            started: false,
            bet: 'manual',
            playing: false,
            buttonText: 'PLACE BET',
            risk: 3,
            riskName: 'Default',
            amount: forceSatoshiFormat(50.00),
            segment: 11,
            game_status: null,
            lock: false,
            bitError: false,
            outError: false,
            inputDisabled: false
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.setupWheel();

        const { engine } = this.state

        engine.on('play_wheel', (target) => {
            this.state.wheel.target = parseFloat(target);
            let currentTransform = document.querySelector('.eWheel').style.transform
            this.setupWheel();
            document.querySelector('.eWheel').style.transform = currentTransform
            wait(1200).then(() => {
                getElement('.' + SPIN_BUTTON).click();
                playAudio('wheel2.mp3')
                wait(2000).then(() => {
                    playAudio('wheel2.mp3')
                })
            })
        })

        engine.on('wheel_risk', (data) => {
            this.state.wheel.destroy();
            this.setState({
                wheel: new Wheel(this.state.engine, this.state.segment, data.risk, HASH)
            });
            wait(10)
                .then(() => {
                    this.state.wheel.show(getRandomDiv());
                })
        });

        engine.on('wheel_segment', (data) => {
            this.state.wheel.destroy();
            this.setState({
                wheel: new Wheel(this.state.engine, data.segment, data.risk, HASH)
            });
            wait(10)
                .then(() => {
                    this.state.wheel.show(getRandomDiv());
                })
        });
    }

    componentWillUnmount() {
        this.state.wheel.destroy();
        this._isMounted = false;
    }

    setupWheel = () => {
        if (this._isMounted) {

            wait(10)
                .then(() => {
                    this.state.wheel.show(getRandomDiv());
                })
        }
    }

    render() {
        return (<div className="pedramwheel animated fadeIn"></div>);
    }
}

function getRandomDiv() {
    ReactDOM.unmountComponentAtNode(document.querySelector(".pedramwheel"));
    var letters = '56789ABCDEF'.split('');
    var div = 'div';
    for (var i = 0; i < 6; i++) {
        div += letters[Math.floor(Math.random() * letters.length)];
    }
    let create = React.createElement('div', { className: div });
    ReactDOM.render(create, document.querySelector('.pedramwheel'));
    return div;
}

Game.propTypes = {
    coin: PropTypes.string,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin })(Game);
