import React, { Component } from "react";
import { Button, Row, Col, Dropdown } from "react-bootstrap";
import socket from "../../../../Socket";
import { __, decode, encode, isValidNumber, forceSatoshiFormat, wait, sendNotfication, Event } from "../../../../Helper";
import storage from "../../../../Storage";
import coins from "../../../coins";
import WithdrawlArchive from "./WithdrawlArchive";
import C from "../../../../Constant";

//_isMounted can prevent from double socket response

const FEE_WITHDRAWL = forceSatoshiFormat(0.000001);              

class Withdrawl extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      credit: storage.getKey("credit"),
      showArchive: false,
      slide: false,
      final: false,
      pass: "",
      wallet: "",
      amount: 0,
      im: true,
      error: false,
      error2: false,
      loading: false,
      loader: false,
      list: [],
      coin: "INR",
      fee_withdrawal: FEE_WITHDRAWL,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setMaxBits = this.setMaxBits.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    socket.emit(C.CREDIT_COIN, encode({ coin: this.state.coin }));
    socket.on(C.CREDIT_COIN, (data) => this.setCreditCoin(decode(data)));
    socket.on(C.SUBMIT_NEW_WITHDRAWL, (data) => this.response(decode(data)));

    coins.forEach((item, key) => {
      if (item.preffix === "NC") return;

      let list = (
        <Dropdown.Item
          key={key}
          onClick={(e) => this.handleInputCoin(item.active, item.preffix)}
          className={"animated fadeIn"}
        >
          <img
            src={"/assets/images/" + item.image}
            className={"mini-coin-8"}
            alt=""
          />
          {item.preffix}
        </Dropdown.Item>
      );
      this.setState((state) => ({ list: [list, ...state.list] }));
    });

    Event.on("withdraw_archive", () => {
      this.showArchive();
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  response(data) {
    if (this._isMounted) {
      wait(1000).then(() => {
        let message = data.status.toString();
        sendNotfication(message, "info", "top-center");
        socket.emit(C.CREDIT);
        this.setState({
          final: false,
          pass: "",
          showArchive: false,
          loading: false,
        });
      });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name === "coin") {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    }

    if (target.name === "amount") {
      if (!isValidNumber(value)) return false;

      if (value.length > 20) {
        return false;
      }
    }

    if (target.name === "amount") {
      let error = false;

      if (Number(value) > Number(this.maxUserCredit())) error = true;

      this.setState({ error: error });
    }

    this.setState({ [target.name]: value, error3: false });
  }

  getUserCredit() {
    return forceSatoshiFormat(this.state.credit);
  }

  setCreditCoin(data) {
    let { credit } = data;
    this.setState({ credit: credit, amount: credit });    
  }

  maxUserCredit() {
    this.setState({ error: false, error2: false });
    return this.getUserCredit();
  }

  setMaxBits() {
    this.setState({ amount: this.maxUserCredit() });
  }

  submitForm(e) {
    if (this._isMounted) {

      e.preventDefault();

      let { error, coin, pass, wallet, amount, credit, fee_withdrawal } = this.state;

      if (error === true) return

      if (!Number(amount) && !amount) return;


      if (wallet === "" || wallet.length < 10) {
        this.setState({ error3: "Please Enter wallet address" });
        return;
      }

      if (amount <= forceSatoshiFormat(0.0)) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      var check = amount - fee_withdrawal;

      if (check <= 0.0) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      let finalMoney = forceSatoshiFormat(amount - fee_withdrawal);

      if (__.toNumber(finalMoney) <= 0) {
        this.setState({ error3: "Please check amount" });
        return;
      }

      let restrecFee = true, minFee;

      //Check for minimum amount
      coins.forEach((item, key) => {
        if (__.lowerCase(item.preffix) === __.lowerCase(coin)) {
          let min = forceSatoshiFormat(item.min);
          finalMoney = __.toNumber(finalMoney);
          min = __.toNumber(min);
          if (finalMoney < min) {
            minFee = forceSatoshiFormat(min);
            restrecFee = false;
          }
        }
      });

      if (!restrecFee) {
        this.setState({ error2: true, minFee: minFee });
        return;
      }

      //Show Second Form [ Final ]
      if (pass === "") {
        this.setState({ final: true });
        return;
      }

      this.setState({ loading: true });

      wait(500).then(() => {
        socket.emit(
          C.SUBMIT_NEW_WITHDRAWL,
          encode({
            coin: coin,
            wallet: wallet,
            amount: amount,
            immed: fee_withdrawal,
            password: pass,
          })
        );
      });
    }
  }

  onChange(event, list) {
    this.setState({ list: list });
  }

  back(e) {
    e.preventDefault();
    this.setState({ final: false, pass: "" });
  }

  enterPass(e) {
    this.setState({ pass: e.target.value });
  }

  showArchive = (e) => {
    if (this.state.slide) Event.emit("withdraw_archive_back");

    this.setState({ slide: !this.state.slide });

    wait(500).then(() => {
      this.setState({ showArchive: !this.state.showArchive });
    });
  };

  handleInputCoin = (active, value) => {
    if (active) {
      socket.emit(C.CREDIT_COIN, encode({ coin: value }));
    } else {
      this.setState({ amount: forceSatoshiFormat(0) });
    }
    this.setState({ coin: value, error: false, error2: false });
  };

  getFee = (coin) => {
    let find = __.find(coins, function (o) {
      return o.preffix === __.upperCase(coin);
    });

    return find.min;
  };

  render() {
    let UNIT = this.state.coin;
    let {
      loader,
      final,
      error,
      showArchive,
      amount,
      credit,
      error2,
      minFee,
      fee_withdrawal,
      error3,
    } = this.state;
    let details = withdrawlDetail(amount, fee_withdrawal, UNIT, credit, error);
    const top = true;
    return (
      <>
        {showArchive ? (
          <div className={this.state.slide === false ? "animated fadeOut" : ""}>
            <WithdrawlArchive clicked={this.showArchive} />
          </div>
        ) : (
          <>
            <div className={this.state.slide ? "animated fadeOut mt-1" : " mt-1"}
            >
              {final ? (
                <>
                  <Row className={this.state.final === true ? "animated fadeIn" : ""}>
                    <Col md="7" lg="7" sm="12" className="m-auto">
                      <div className={"m-auto text-center"}>
                        <h5 className={"text-success"}>
                          Total Amount to Withdrawl:
                          <b className="ml-2">
                            {forceSatoshiFormat(
                              this.state.amount - this.state.fee_withdrawal
                            )}{" "}
                            {UNIT}
                          </b>
                        </h5>
                      </div>

                      <div className={"m-auto text-center"}>
                        <form
                          className="w-100"
                          onSubmit={(e) => {
                            this.submitForm(e);
                          }}
                        >
                          <div className="form-group text-center text-darker">
                            <label>Enter Your Password</label>
                            <input
                              name={"password"}
                              type="password"
                              value={this.state.pass}
                              onChange={(e) => this.enterPass(e)}
                              className={"form-control bgl2"}
                              required={true}
                            />
                          </div>
                          <div className="form-group mt-3 text-center">
                            <Button
                              variant="btn btn-btn bg-cs shadow-none btn-sm mr-1"
                              type="button"
                              onClick={(e) => this.back(e)}
                            >
                              <i className="mdi mdi-refresh" /> Back
                            </Button>
                            <Button
                              onClick={e => this.submitForm(e)}
                              variant="btn btn-btn bg-cs shadow-none btn-sm"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              <i className="mdi mdi-send" /> Send To Wallet
                            </Button>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className={"m-auto wallet"}>
                    <form
                      className="w-100"
                      onSubmit={(e) => {
                        this.submitForm(e);
                      }}
                    >
                      {loader ? (
                        <></>
                      ) : (
                        <Row className={this.state.final === false ? "animated fadeIn" : ""}>
                          <Col md="7" lg="7" sm="12">
                            <div className="form-group mb-3">
                              <label htmlFor="targetWallet" className="text-white">
                                Enter Your Wallet Address or UPI
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  value={this.state.wallet}
                                  className="form-control bgl2"
                                  name="wallet"
                                  id="targetWallet"
                                  placeholder="..."
                                  onChange={this.handleInputChange}
                                  required={true}
                                  autoComplete={"off"}
                                />
                                <span className="input-group-append">
                                  <div className="input-group fw">
                                    <Dropdown
                                      top={top.toString()}
                                      className={
                                        "drop-select clist3 btn-block bgl2 py-0 pt-2"
                                      }
                                    >
                                      <Dropdown.Toggle
                                        split
                                        variant=""
                                        className={"py-0 mt-0 bgl2"}
                                      >
                                        <span className="text-muted text-center font-12">
                                          <span className="caret mr-1"></span>
                                          <img
                                            src={
                                              "/assets/images/" +
                                              __.upperCase(this.state.coin) +
                                              ".png"
                                            }
                                            className={"mini-coin-9"}
                                            alt="COIN"
                                          />
                                          {this.state.coin}
                                        </span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        className={"dopdown-menu-bottom"}
                                        align={"left"}
                                      >
                                        {this.state.list}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col md="5" lg="5" sm="12">
                            <label htmlFor="targetAmount" className="text-white">
                              Enter Amount ({UNIT})
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                value={this.state.amount}
                                className="form-control bgl2"
                                name="amount"
                                id="targetAmount"
                                placeholder="0"
                                onChange={this.handleInputChange}
                                required={true}
                                autoComplete={"off"}
                              />
                              <div className="input-group-append">
                                <Button
                                  variant="btn bg-greys no-shadow btn-sm btn-clipboard"
                                  onClick={this.setMaxBits}
                                >
                                  <i className="mdi mdi-coins mr-1" /> Max
                                </Button>
                              </div>
                              {error && (
                                <ul className="mt-2 d-inline-block w-100 p-0 m-0">
                                  <li className="text-yellow font-12">
                                    Balance not enough
                                  </li>
                                </ul>
                              )}
                              {error3 ? (
                                <ul className="mt-2 d-inline-block w-100 p-0 m-0">
                                  <li className="text-yellow font-12">
                                    {error3}
                                  </li>
                                </ul>
                              ) : (
                                <ul className="mt-2 d-inline-block w-100 p-0">
                                  <li className="text-yellow font-12">
                                    Minimum : {this.getFee(this.state.coin)} {UNIT}
                                  </li>
                                </ul>
                              )}
                            </div>
                          </Col>
                          <Col sm="12">
                            <div className="form-group text-center">
                              <button
                                onClick={e => this.submitForm(e)}
                                className="btn btn-s-4 btn-sm px-4"
                                type="submit"
                                disabled={this.state.loading}
                              >
                                <i className="far fas fa-dot-circle mr-1" /> Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </form>
                    <div className="form-group mt-2 mb-0">
                      {loader ? (
                        <div className="ycenter text-center"></div>
                      ) : (
                        <div className="withdrawl-detail p-1 bg-soft-dark text-white">
                          {details}
                        </div>
                      )}
                      {loader ? (
                        <></>
                      ) : (
                        <p className="mt-1 mb-0 p-2 bg-soft-dark text-white">
                          Your withdrawal will be sent from the hot wallet, do not
                          withdraw to any site that uses the sending address, or
                          returns to sender, because any returns will probably
                          be credited to a different player.
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

function withdrawlDetail(amount, fee, coin, credit, error) {
  let total = forceSatoshiFormat(amount - fee);

  return (
    <>
      <ul className={"p-2 m-0"}>
        <li>
          Balance available for withdrawal: <b>{forceSatoshiFormat(credit)}</b>{" "}
          {coin}
        </li>
        <li>
          Amount to Withdraw: <b>{amount}</b> {coin}
        </li>
        <li>
          Withdrawal Fee: <b>{forceSatoshiFormat(fee)}</b> {coin}
        </li>
        <li>Total: <span className="text-yellow">{total}</span></li>
      </ul>
    </>
  );
}

export default Withdrawl;
