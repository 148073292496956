import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Scripts from "./Script";
import { Alert } from "react-bootstrap";

class AutoBet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { isLogged, engine } = this.props;
    return (
      <>
        {isLogged ? (
          <Scripts engine={engine} coin={this.props.coin} />
        ) : (
          <Alert className="alert-purple text-darker rounded-0 mx-2 mt-4">
            You most be logged to use this option.
          </Alert>
        )}
      </>
    );
  }
}

AutoBet.propTypes = {
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps)(AutoBet);
