import * as PIXI from "pixi.js";
import { wait, __ } from "../../../Helper";

PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;

class Game {
  constructor(engine) {
    this.engine = engine;
    this.seconds = 0;
    this.numbers = [2, 12, 24, 36, 45];
    this.number = null;
    this.spinUpTime = 1;
    this.spinUpInterval = this.spinUpTime;
    this.spinUpSubinterval = 1 / this.spinUpInterval;
    this.stopInterval = 2;
    this.stopSubinterval = 1 / this.stopInterval;
    this.endTime = null;
    this.isPlaying = false;
    this.wheel = []
    this.round = 2;
    this.target = null;
    this.filter = new PIXI.filters.ColorMatrixFilter();
    this.blurFilter = new PIXI.filters.BlurFilter();
  }

  init() {
    this.app = new PIXI.Application({
      width: 500,
      height: 400,
      resolution: 1,
      transparent: true,
      view: document.getElementById('game')
    });

    this.configureWheel();
    this.configureText();
    this.configureHolder();
    this.configureMarker();
    this.configureEffect();

    const container = new PIXI.Container();
    container.sortableChildren = true
    this.app.stage.sortableChildren = true
    container.addChild(this.wheel[0], this.wheel[1], this.wheel[2], this.effect, this.arrow);
    container.width = container.height = 550
    container.position.set(this.app.screen.width / 2, this.app.screen.height / 2);
    this.app.stage.addChild(container);

    this.app.ticker.add((delta) => this.gameLoop(delta, 0));
  }

  gameLoop(delta, count) {
    this.animation(delta, count);
  }

  animation(delta, count) {
    if (!this.isPlaying) {
      return;
    }
    count += 0.1;

    if (this.holder.scale.x > 1.5) {
      this.holder.scale.x -= 0.01
      this.holder.scale.y -= 0.01
    }

    this.light2.rotation -= 0.02;
    this.light1.rotation += 0.02;
    this.holder.rotation += 0.01;
    const { matrix } = this.filter;
    matrix[1] = Math.sin(count) * 3;
    matrix[2] = Math.cos(count);

    if (this.endTime === null || this.seconds <= this.endTime) {
      if (this.round < 0) {
        return
      }
      const f = this.getFrequency();
      this.wheel[this.round].angle += 10 * delta * f;
      this.getNumberOnWheel(this.wheel[this.round].angle);

      this.wheel[this.round].filters = [this.blurFilter];
      this.blurFilter.blur = 5 * delta * f;
    } else {
      this.filter.blur = 0
      this.wheel[this.round].filters = null
      this.isPlaying = false;

      if (this.target !== this.point[this.round]) {
        return this.play();
      }

      if (this.target === 'NEXT') {
        if (this.round > 0) {
          //Next Round
          wait(1200).then(() => {
            this.round--
            this.play();
          })
        }
        else {
          this.round = 2  // Reset Game
        };
      } else {
        this.engine.init = true
        this.engine.bonus = this.target;
        this.engine.busted();
        this.round = 2  // Reset Game
      }
      return;
    }
    this.seconds += delta / 60;
  }

  configureEffect() {
    this.effect = new PIXI.Container();
    this.light1 = PIXI.Sprite.from('./assets/images/magic-wheel/light_rotate_1.png');
    this.light1.anchor.set(0.5);
    this.effect.addChild(this.light1);
    this.light2 = PIXI.Sprite.from('./assets/images/magic-wheel/light_rotate_2.png');
    this.light2.anchor.set(0.5);
    this.effect.addChild(this.light2);
    this.effect.addChild(this.holder)
    this.holder.filters = [this.filter];
  }

  configureWheel() {
    this.wheel[0] = new PIXI.Sprite.from(`./assets/images/magic-wheel/w2.png`);
    this.wheel[1] = new PIXI.Sprite.from(`./assets/images/magic-wheel/w.png`);
    this.wheel[2] = new PIXI.Sprite.from(`./assets/images/magic-wheel/w.png`);

    for (var i = 0; i < 3; i++) {
      this.wheel[i].anchor.set(0.5);
      this.wheel[i].angle += 40
      if (i === 0) {
        this.wheel[i].width = this.wheel[i].height = 830
      } else
        if (i === 1) {
          this.wheel[i].width = this.wheel[i].height = 660
        } else
          if (i === 2) {
            this.wheel[i].width = this.wheel[i].height = 490
          }
    }
  }

  configureText() {
    const style = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 40,
      fill: 0xFFFFFF
    });
    this.basicText = new PIXI.Text(`0`, style);
    this.basicText.anchor.set(0.5);
  }

  configureHolder() {
    this.holder = new PIXI.Sprite.from(`./assets/images/magic-wheel/holder.png`);
    this.holder.anchor.set(0.5)
    this.holder.width = this.holder.height = 370
    this.holder.y = 5
  }

  configureMarker() {
    this.arrow = new PIXI.Sprite.from(`./assets/images/magic-wheel/arrow.png`);
    this.arrow.anchor.set(0.5)
    this.arrow.y = -160 // 155
  }

  play(point) {
    if (!__.isUndefined(point))
      this.point = point;

    this.endTime = null;
    this.seconds = 0;
    this.stopTime = null;
    this.isPlaying = true;
    let rand = Math.floor(Math.random() * (1500 - 1000 + 1)) + 1000;
    wait(rand).then(() => {
      const nowInSeconds = this.seconds;
      this.stopTime = nowInSeconds;
      this.endTime = this.stopTime + this.stopInterval;
    })
  };

  getFrequency() {
    if (this.seconds <= this.spinUpTime) {
      return this.easeOutSine(this.spinUpSubinterval * this.seconds);
    }

    if (this.stopTime !== null && this.seconds >= this.stopTime) {
      return this.easeOutSine(1 - this.stopSubinterval * (this.seconds - this.stopTime));
    }

    return 1;
  }

  getNumberOnWheel(wheelAngle) {
    let n = this.numbers;
    if (this.round === 2 || this.round === 1) {
      n = [2, 12, 24, 36, 'NEXT'];
    }
    const partOfFullWheel = 360 / (n.length);
    const qtyFullTurn = Math.floor(wheelAngle / 360);
    const partOfFullTurn = wheelAngle - (360 * qtyFullTurn);
    this.number = Math.floor(partOfFullTurn / partOfFullWheel);
    const target = n[this.number];
    this.basicText.text = (target);
    this.target = target;
  }

  easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }
}

export default Game;
