import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat } from "../../../Helper";

/**
 * Game Engine
 */
function Engine() {

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = null;

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Intialize Starting Game
     */
    self.init = false;

    /**
     * Game Status
     * 1 type: busted_limbo
     */
    self.gameStatus = null;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Round Payout
     */
    self.payout = null;

    /**
     * Round Type
     * low or high
     */
    self.type = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Bet Number
     */
    self.betNumber = null;

    /**
     * Increase Amount on Win
     */
    self.onWin = 0;

    /**
     * Increase Amount on Lost
     */
    self.onLost = 0;

    /**
     * Played Game Rounds
     */
    self.played = 0;

    /**
     * Player is the Win or Lost
     */
    self.isWin = false;

    /**
     * Stop Playing if Won Amount
     */
    self.stopOnWin = 0;

    /**
     * Round Profit
     */
    self.roundProfit = 0;

    /*
     * Start Engine
    */
    self.started = true;

    if (self.started) {
        self.ws.on(C.PLAY_HASH_DICE, (data) => {
            data = decode(data);

            switch (data.command) {
                case 'play':
                    return self.trigger.emit(C.PLAY_HASH_DICE, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_HASH_DICE, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_HASH_DICE, data);
            }
        });
    }
}

Engine.prototype.off = function () {
    let self = this;
    self.ws.off(C.PLAY_HASH_DICE);
}

Engine.prototype.on = function (name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
}

/**
 * Start playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if (!self.init)
        return;

    if (self.amount < 50.00) {
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_HASH_DICE, encode({
        amount: self.amount,
        payout: self.payout,
        type: self.type,
        coin: self.coin
    }));
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
    let self = this;

    if (!self.init)
        return;

    self.played++;

    if (parseInt(self.betNumber) <= 0) {
        self.played = 0;
        self.trigger.emit('stop_hash_dice');
        return;
    }

    // Stop On Bet Number
    if (parseInt(self.played) === parseInt(self.betNumber)) {
        self.played = 0;
        self.trigger.emit('stop_hash_dice');
        return;
    }

    // Increase Amount on Win (player set increase amount on win in %)
    if (self.onWin !== 0) {
        // It Mean Player has win in this round
        if (self.isWin) {
            self.amount *= parseInt(self.onWin);
        }
    }

    // Increase Amount on Lost (player set increase amount on lost in %)
    if (self.onLost !== 0) {
        // It Mean Player has lost in this round
        if (!self.isWin) {
            self.amount *= parseInt(self.onLost);
        }
    }

    //Stop On Win (player set amount to stop on win)
    if (self.stopOnWin !== 0) {
        if (forceSatoshiFormat(self.stopOnWin) >= forceSatoshiFormat(self.roundProfit)) {
            self.played = 0;
            self.roundProfit = 0;
            self.trigger.emit('stop_hash_dice');
            return;
        }
    }

    if (self.amount < 50.00) {
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_HASH_DICE, encode({
        amount: self.amount,
        payout: self.payout,
        type: self.type,
        coin: self.coin
    }));
}

/** Start Game Engine **/
export default Engine
