import React, { Component } from "react";

import { withCookies } from "react-cookie";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "../Helper";
import gameData from './wb_game_list_prod_slot.json';
import { Search } from "lucide-react";

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;
const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1B1E22;
  border-radius: 30px;
  padding: 12px 16px;
  width: 100%;
  max-width: 300px;
  transition: outline 0.2s ease-in-out;
  border: ${props => props.isFocused ? '2px solid #4caf50' : '2px solid transparent'};

   @media (min-width: 200px) and (max-width: 500px) {
max-width: 600px;
}
`;
const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #a0a0a0;
  }
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? "none" : "block")};
`;
const ResponsiveImage = styled.img`
  object-fit: cover;
  width: 140px;
  height: 140px;
  border-radius: 20px;

  @media (min-width: 467px) {
    width: 188px;
    height: 188px;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

const GamesTopWrapper = styled.div`
  background-color: #1c1e22;
  padding: 2px;
  border-radius: 0 20px 20px 20px; /* top-left | top-right | bottom-right | bottom-left */
  position: relative;
  margin-top: 40px !important;
  margin-top: 100px !important;
  @media (max-width: 768px) {
    margin-top: 100px !important;
  }
`;
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin: 12px 12px;
  width: 100%;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr);
  // Tablet view (3 columns)
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // Desktop view (4 columns)
  @media (min-width: 1068px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
const GridItem = styled.div`
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const GridContainerNew = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin: 12px 12px;
  width: 100%;
  padding-right: 17px;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr) !important;
  // Tablet view (3 columns)
  @media (min-width: 870px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  // Desktop view (4 columns)
  @media (min-width: 1068px) {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  @media (min-width: 1270px) {
    grid-template-columns: repeat(5, 1fr) !important;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Title = styled.h3`
  padding: 0.5rem;
  margin: 0;
  font-size: 10px;
  text-align: left;
  color: rgb(152, 167, 181);
`;
const TopTitleContainer = styled.h3`
  background-color: #1c1e22;
  border-radius: 15px;
  position: absolute;
  top: -71px;
  left: 0;
  z-index: 1;
  padding: 10px;
  margin: 0;
`;
const AllButtonRigthTitleContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  align-items: center;
  top: -57px;
  right: 0;
  color: #7f898c;
  font-weight: bold;
  z-index: 1;
  margin: 0;
`;








class WB_api_page_slot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            padding: "py-2",
            selectedSection: "home",
            sectionIndex: 0,
            slideSelected: null,
            gamesEvolution: [],
            currentPageJillie: 0, // Track current page
            itemsPerPageJillie: 10, // Number of items per page
            gamesJillie: [],
            gamesJdb: [],
            gamesPragmatic: [],
            gamesPragmaticlive: [],
            gamesEvoplay: [],
            gamesGenisis: [],
            gamesPgsoft: [],
            gamesLiveCasinoProvider: [],
            gamesSpribe: [],
            gamesSports: [],
            gamesMostExpensive: [],
            error: null,
            loading: false,
            message: null,
            showMore: false,
            showMoreP: false,
            showMoreO: false,
            showMoreLCP: false,
            showMoreSP: false,
            showMoreSB: false,
            isMobileView: window.innerWidth <= 768,
            searchQuery: "",
            showIframe: false,
            iframeUrl: "",
            iframeLoading: true,
            showAll: false,
            showAllData: false,

            activeItem: "Lobby",
            searchQuery: "",
        };




        this.sections = [
            "/assets/images/evolutionicon.png",
            "/assets/images/ezubi.png",
            "/assets/images/pragmatic.png",
            "/assets/images/netent.png",
            "/assets/images/novomatic.png",
        ];
        this.livecasino = [
            "/assets/images/live1.png",
            "/assets/images/live2.png",
            "/assets/images/live3.jpg",
            "/assets/images/live4.jpg",
            "/assets/images/live5.jpg",
            "/assets/images/live6.png",
        ];
        this.itemsToShow = 5;
        this.containerPlayRef = React.createRef();
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    filterGames = (games, query) => {
        if (!query) return games;

        return games.filter((game) => {
            const { game_id, game_name } = game;
            const { first, second, third } = this.getTitleWB(Number(game.game_id));
            const titleString = `${first} ${second} ${third}`.toLowerCase();
            console.log(titleString)
            return (
                game_name.toLowerCase().includes(query) ||
                game_id.toString().includes(query) ||
                titleString.includes(query)
            );
        });
    };

    handleSearchChange = (e) => {
        this.setState({ searchQuery: e.target.value.toLowerCase() });
    };

    getTitleWB = (gType) => {
        switch (gType) {
            case 1:
                return { first: "Evolution", second: "Asia", third: "Games" };
            case 2:
                return { first: "Big", second: "Gaming", third: "Games" };
            case 3:
                return { first: "Micro Gaming", second: "Live", third: "Games" };
            case 5:
                return { first: "Asia", second: "Gaming", third: "Games" };
            case 6:
                return { first: "Dream", second: "Gaming", third: "Games" };
            case 9:
                return { first: "Sexy", second: "Gaming", third: "Games" };
            case 10:
                return { first: "Pragmatic", second: "Play", third: "Games" };
            case 11:
                return { first: "Live", second: "88", third: "Games" };
            case 12:
                return { first: "Playtech", second: "Live", third: "Games" };
            case 17:
                return { first: "Ezugi", second: "Live", third: "Games" };
            case 19:
                return { first: "Skywind", second: "Live", third: "Games" };
            case 28:
                return { first: "PragmaticPlay", second: "Live", third: "Games" };
            case 40:
                return { first: "Evolution", second: "SEA", third: "Games" };
            case 41:
                return { first: "Bet", second: "games", third: "Games" };
            case 100:
                return { first: "Illustrate Analytics", second: "Sports", third: "Games" };
            case 105:
                return { first: "BTI", second: "Sports", third: "Games" };
            case 200:
                return { first: "PP", second: "Slot", third: "Games" };
            case 201:
                return { first: "Habanero", second: "All", third: "Games" };
            case 202:
                return { first: "Elysium", second: "All", third: "Games" };
            case 205:
                return { first: "Spade", second: "Gaming", third: "Games" };
            case 207:
                return { first: "Play'N Go", second: "All", third: "Games" };
            case 208:
                return { first: "World", second: "Match", third: "Games" };
            case 209:
                return { first: "Micro Gaming", second: "Slot", third: "Games" };
            case 211:
                return { first: "OneTouch", second: "All", third: "Games" };
            case 213:
                return { first: "Red Tiger", second: "Asia", third: "Games" };
            case 214:
                return { first: "Netent", second: "Asia", third: "Games" };
            case 216:
                return { first: "YGGDrasil", second: "All", third: "Games" };
            case 217:
                return { first: "BNG", second: "All", third: "Games" };
            case 218:
                return { first: "Playson", second: "All", third: "Games" };
            case 219:
                return { first: "Playtech", second: "Slot", third: "Games" };
            case 220:
                return { first: "CQ9", second: "Slot", third: "Games" };
            case 221:
                return { first: "Skywind", second: "Slot", third: "Games" };
            case 222:
                return { first: "Wazdan", second: "All", third: "Games" };
            case 223:
                return { first: "PG", second: "Soft", third: "Games" };
            case 225:
                return { first: "Big Time Gaming", second: "Asia", third: "Games" };
            case 226:
                return { first: "Pragmatic Play", second: "Slot", third: "Games" };
            case 227:
                return { first: "No Limit City", second: "Asia", third: "Games" };
            case 229:
                return { first: "Relax", second: "Gaming", third: "Games" };
            case 231:
                return { first: "NextSpin", second: "Gaming", third: "Games" };
            case 232:
                return { first: "Spinix", second: "All", third: "Games" };
            case 249:
                return { first: "Naga", second: "All", third: "Games" };
            case 255:
                return { first: "Hacksaw", second: "Gaming", third: "Games" };
            case 263:
                return { first: "JiLi", second: "All", third: "Games" };
            case 271:
                return { first: "Booming", second: "All", third: "Games" };
            case 300:
                return { first: "Spribe", second: "All", third: "Games" };
            case 302:
                return { first: "Alize", second: "All", third: "Games" };
            case 303:
                return { first: "Aviatrix", second: "All", third: "Games" };
            case 304:
                return { first: "Fast", second: "Game", third: "Games" };
            default:
                return { first: "WB", second: "Other", third: "Games" };
        }
    };

    handleGameLaunchWB = async (game, categoryId) => {
        this.setState({ loading: true, message: null });

        console.log(game, categoryId, "game, categoryId")
        try {
            const { cookies } = this.props;
            const uid = cookies.get("uid");

            //if slot
            let prd;
            if (game && game.table_id) {
                prd = {
                    id: categoryId,
                    type: 0,
                    table_id: game.table_id,
                    is_mobile: 0,
                };
            } else {
                prd = {
                    id: categoryId,
                    type: game.game_id,
                    is_mobile: 0,
                };
            }




            // Check if user is logged in
            if (!uid) {
                throw new Error("Please login to play live game");
            }

            // Call the API to play the Jillie game
            const response = await fetch("https://api.ekjeet.com/authWBGames", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_code: String(uid),
                    prd
                }),
            });

            // If the response is not OK, throw an error
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            // Parse the JSON response
            const result = await response.json();
            console.log(result, "result");
            console.log(result.data, "resultData");
            // Check the status and handle accordingly
            if (result.data) {
                // If the game URL is returned, show the iframe
                this.setState({
                    iframeUrl: result.data.launch_url,
                    showIframe: true,
                    iframeLoading: true,
                });
            } else {
                // If the response structure is unexpected, handle the error
                throw new Error("Unexpected response from server");
            }
        } catch (error) {
            // Handle any errors during the API call
            this.setState({ message: { type: "error", text: error.message } });
        } finally {
            // Reset loading state and clear the message after 5 seconds
            this.setState({ loading: false });
            setTimeout(() => {
                this.setState({ message: null });
            }, 5000);
        }
    };




    handleShowAllData = () => {
        this.setState((prevState) => ({ showAllData: !prevState.showAllData }));
    };

    handleMouseDown(e) {
        this.isDown = true;
        this.startX = e.pageX - this.containerPlayRef.current.offsetLeft;
        this.scrollLeft = this.containerPlayRef.current.scrollLeft;
    }

    handleShowAllClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        this.setState({ showAll: !this.state.showAll });
    };

    handleMouseLeave() {
        this.isDown = false;
    }

    handleMouseUp() {
        this.isDown = false;
    }

    handleMouseMove(e) {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.containerPlayRef.current.offsetLeft;
        const walk = (x - this.startX) * 2;
        this.containerPlayRef.current.scrollLeft = this.scrollLeft - walk;
    }
    handleItemClick = (item) => {
        this.setState({ activeItem: item });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeItem !== this.state.activeItem) {
            console.log(`Active item changed to: ${this.state.activeItem}`);
            // Add any additional side effects or logic you want to run on activeItem change
        }
    }

    componentDidMount() {
        const containerPlay = this.containerPlayRef.current;
        containerPlay.addEventListener("mousedown", this.handleMouseDown);
        containerPlay.addEventListener("mouseleave", this.handleMouseLeave);
        containerPlay.addEventListener("mouseup", this.handleMouseUp);
        containerPlay.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
        const containerPlay = this.containerPlayRef.current;
        containerPlay.removeEventListener("mousedown", this.handleMouseDown);
        containerPlay.removeEventListener("mouseleave", this.handleMouseLeave);
        containerPlay.removeEventListener("mouseup", this.handleMouseUp);
        containerPlay.removeEventListener("mousemove", this.handleMouseMove);
    }



    handleBackButton = () => {
        this.setState({ showIframe: false, iframeUrl: "", iframeLoading: true });
    };

    handleIframeLoad = () => {
        this.setState({ iframeLoading: false });
    };

    componentDidMount() {
        this._isMounted = true;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (isMobile()) {
            this.setState({ padding: "p-1" });
        }

        window.addEventListener("resize", this.handleResize);
        this.handleResize();



    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        this._isMounted = false;
    }





    handleResize = () => {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        this.setState({ isMobileView: width <= 768 });
    };

    handleClick = (e) => {
        this.setState({ selectedSection: e });
    };

    handleArrowClick = (direction) => {
        const { sectionIndex } = this.state;
        const { sections, itemsToShow } = this;
        const totalSections = sections.length;

        let newIndex;

        if (direction === "left") {
            newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
        } else {
            newIndex = (sectionIndex + itemsToShow) % totalSections;
        }

        this.setState({
            selectedSection: sections[newIndex],
            sectionIndex: newIndex,
        });
    };

    handleShowMore = () => {
        this.setState({ showMore: !this.state.showMore });
    };

    handleShowMoreP = () => {
        this.setState({ showMoreP: !this.state.showMoreP });
    };

    handleShowMoreO = () => {
        this.setState({ showMoreO: !this.state.showMoreO });
    };

    handleShowMoreLCP = () => {
        this.setState({ showMoreLCP: !this.state.showMoreLCP });
    };

    handleShowMoreSP = () => {
        this.setState({ showMoreSP: !this.state.showMoreSP });
    };

    handleShowMoreSB = () => {
        this.setState({ showMoreSB: !this.state.showMoreSB });
    };

    handleSearch = (query) => {
        this.setState({ searchQuery: query });
    };

    getFilteredGames = (games) => {
        const { searchQuery } = this.state;
        if (!searchQuery) return games;
        return games.filter(
            (game) =>
                game.game_name &&
                game.game_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    getFilteredGames2 = (games) => {
        const { searchQuery } = this.state;
        if (!searchQuery) return games;
        return games.filter(
            (game) =>
                game.name.en - US &&
                game.name.en - US.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    handlePreviousPage = () => {
        this.setState((prevState) => ({
            currentPageJillie: Math.max(prevState.currentPageJillie - 1, 0), // Prevent going below page 0
        }));
    };

    handleNextPage = () => {
        this.setState((prevState) => ({
            currentPageJillie: Math.min(
                prevState.currentPageJillie + 1,
                Math.ceil(prevState.gamesJillie.length / prevState.itemsPerPageJillie) -
                1 // Correctly access gamesJillie from state
            ),
        }));
    };
    render() {
        const { game_list } = gameData; // Access game_list from JSON
        const { searchQuery } = this.state;
        const { showIframe, iframeUrl, iframeLoading } = this.state;
        let { content, t } = this.props;



        const imageStyle = {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "250px",
            animation: "fadeinout 2s infinite",
        };
        const animationStyle = `
      @keyframes fadeinout {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
      }
    `;
        if (showIframe) {
            return (
                <FullScreenContainer>
                    <style>{animationStyle}</style>
                    {this.state.iframeLoading && (
                        <img src="assets/images/ekjeet_logo.png" alt="Loading" style={imageStyle} />
                    )}
                    <FullScreenIframe
                        src={iframeUrl}
                        title="Game Iframe"
                        onLoad={this.handleIframeLoad}
                        loading={this.state.iframeLoading}
                    />
                    <BackButton onClick={this.handleBackButton}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </BackButton>
                </FullScreenContainer>
            );
        }







        function generateGradient(gameName) {
            // Convert the game name into a hash or use any string-based property
            const hash = [...gameName].reduce((acc, char) => acc + char.charCodeAt(0), 0);
            const hue = hash % 360;  // Use the hash to generate a unique hue value

            // Return a gradient based on the generated hue
            return `linear-gradient(45deg, hsl(${hue}, 100%, 60%), hsl(${(hue + 90) % 360}, 100%, 60%))`;
        }

        // const groupedGames = groupGamesByCategory(gamesJillie);
        return (


            <div style={{ marginTop: "10px" }}>
                {/* <div style={{ width: "100%", display: "flex", justifyContent: "right",alignItems:"center" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Total Games:{" "}
                        {Object.keys(game_list)
                            .reduce((total, gType) => {
                                const filteredGames = this.filterGames(game_list[gType], searchQuery).filter(
                                    (game) => game.game_name !== "N/A" && game.is_enabled !== 0
                                );
                                return total + filteredGames.length;
                            }, 0)}
                    </div>
                    <SearchBarContainer style={{ marginTop: "30px" }}>
                        <Search size={18} color="#a0a0a0" />
                        <SearchInput
                            placeholder="Game name | Provider | Category Tag"
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                        />
                    </SearchBarContainer>
                </div> */}

                {Object.keys(game_list).map((gType) => {
                    //   const filteredGames = this.filterGames(game_list[gType], searchQuery);
                    const filteredGames = this.filterGames(game_list[gType], searchQuery).filter(
                        (game) => game.game_name !== "N/A" && game.is_enabled !== 0
                    );


                    if (filteredGames.length === 0) return null; // Hide empty categories

                    const { first, second, third } = this.getTitleWB(Number(gType));
                    const totalGames = filteredGames.length;
                    return ( // Don't forget the return statement
                        <GamesTopWrapper key={gType}>
                            <TopTitleContainer>
                                <div>
                                    <div
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {first}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {second}{" "}
                                       <span style={{ color: "#E70A6C" }}>{third} {" "}</span>
                                    </div>
                                </div>
                            </TopTitleContainer>

                            <AllButtonRigthTitleContainer>

                                <div
                                    style={{
                                        display: "flex",
                                        gap: "4px",
                                        alignItems: "center",
                                    }}
                                >
                                    <p style={{ fontSize: "15px" }}>
                                        Games: {totalGames} 
                                    </p>
                                </div>
                            </AllButtonRigthTitleContainer>

                            <GridContainerNew>
                                {filteredGames.map((game) => (
                                    <GridItem key={game.game_id}>
                                        {game.game_icon_link ? (
                                            <ResponsiveImage
                                                src={game.game_icon_link}
                                                alt={game.game_name}
                                                onClick={() => this.handleGameLaunchWB(game, gType)}
                                            />
                                        ) : (
                                            <div
                                                onClick={() => this.handleGameLaunchWB(game, gType)}
                                                style={{
                                                    width: "188px", // Adjust size as needed
                                                    height: "188px", // Adjust size as needed
                                                    background: `url(/assets/wsImages/${gType}/${game.game_id}.png)`,
                                                    backgroundSize: "100% 100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: "white",
                                                    fontSize: "16px", // Adjust font size
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    borderRadius: "4px",
                                                    padding: "5px",
                                                    fontFamily: "fangsong", // Apply the same font
                                                    letterSpacing: "0.7px"
                                                }}
                                            >

                                            </div>
                                        )}
                                        <Title>{game.game_name} ({game.game_id})</Title>
                                    </GridItem>
                                ))}
                            </GridContainerNew>

                        </GamesTopWrapper>
                    );
                })}
            </div>
        );

    }
}

export default withCookies(WB_api_page_slot);
