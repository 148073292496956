import React from 'react';
import { Row, Col } from "react-bootstrap";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/icons-material/FeaturedPlayList';

import useStyles from "../../../../Style";
import storage from "../../../../Storage";
import General from "./General";
import Security from "./Security";
import Sessions from "./Sessions";
import Avatar from "./Avatar";

class Setting extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey('logged'),
      tab: 0
    }
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.state.isLogged !== 'true')
      this.props.history.push('../')
  }

  change = (tab) => {
    this.setState({ tab })
  }

  render() {
    const classes = useStyles({
      backgroundColor: '#121725',
      position: 'relative',
      borderRadius: 10
    });
    return <>
      <div>
        <div className={"container mt-2 p-3 " + classes}>
          <Row>
            <Col sm={5} md={3}>
              <div className="setting-tab">
                <List component="nav" aria-label="main setting">
                  <ListItem className={this.state.tab === 0 ? 'active' : ''} button onClick={e => this.change(0)}>
                    <i className="mdi mdi-account-circle" />
                    <span className="ml-2">General</span>
                  </ListItem>
                  <ListItem className={this.state.tab === 1 ? 'active' : ''} button onClick={e => this.change(1)}>
                    <i className="mdi mdi-shield-key" />
                    <span className="ml-2">Security</span>
                  </ListItem>
                  <ListItem className={this.state.tab === 2 ? 'active' : ''} button onClick={e => this.change(2)}>
                    <i className="mdi mdi-alert" />
                    <span className="ml-2">Sessions</span>
                  </ListItem>
                </List>
              </div>
            </Col>
            <Col sm={7} md={9}>
              <div className="mt-4">
                {
                  this.state.tab === 0 &&
                  <>
                  {/* <Avatar /> */}
                  <General />
                  </>
                }
                {
                  this.state.tab === 1 &&
                  <Security />
                }
                {
                  this.state.tab === 2 &&
                  <Sessions />
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  }
}

export default Setting;
