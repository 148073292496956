import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1E2024;
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  overflow-x: auto; /* Allows scrolling on smaller screens */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
  
  /* Ensure the table is scrollable on small screens */
  @media (max-width: 768px) {
    display: block;
    white-space: nowrap;
    table-layout: auto;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #99A4B0;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  font-size: 14px;
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 12px;
  }
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const RedText = styled.span`
  color: #f44336;
`;

const AnimatedTableRow = styled.tr`
  background-color: transparent;
  transition: background-color 0.3s ease;
`;



const formatDate = (date) => {
  return date.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });
};

// Helper function to format only the time (HH:mm:ss)
const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', { hour12: false });
};

// Function to generate random betting data
const generateRandomData = (count) => {
  const data = [];
  let currentTime = new Date();
  for (let i = 0; i < count; i++) {
    const bet = (Math.random() * 99 + 1).toFixed(2); // Bet between 1 and 100
    const profit = Math.random() < 0.5 ?
      (Math.random() * 200).toFixed(2) : // Win scenario
      "0.00"; // Loss scenario

    data.push({
      id: Math.floor(Math.random() * 1000000),
      username: "Hidden",
      bet: `${bet}`,
      profit: profit,
      dateTime: formatTime(currentTime) // Use only time here
    });
    
    // Decrease time by a random amount between 5 and 60 seconds
    currentTime = new Date(currentTime.getTime() - Math.floor(Math.random() * 55000 + 5000));
  }
  return data;
};

const AnimatedTable = ({ visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setAllData(generateRandomData(20));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData(prevData => {
        const newData = [...prevData];
        newData.pop();
        newData.unshift(generateRandomData(1)[0]);
        return newData;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setVisibleData(allData.slice(0, visibleRowsCount));
  }, [allData, visibleRowsCount]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>ID</TableHeader>
          <TableHeader>USERNAME</TableHeader>
          <TableHeader>BET</TableHeader>
          <TableHeader>PROFIT</TableHeader>
          <TableHeader>TIME</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${row.id}-${index}`}>
            <TableCell style={{ color: '#99A4B0', fontWeight: 'bold' }}>{row.id}</TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>{row.username}</TableCell>
            <TableCell style={{ color: '#99A4B0', fontWeight: 'bold' }}>
              <div style={{ display: 'flex', gap: "4px", alignItems: 'center' }}>
                <img src="/assets/images/USDT.png" style={{ height: '16px', width: '16px', borderRadius: '50%' }} />
                <div style={{ color: '#99A4B0', fontWeight: 'bold' }}>{row.bet}</div>
              </div>
            </TableCell>
            <TableCell>
              {parseFloat(row.profit) > 0 ?
                <div style={{ display: 'flex', gap: "4px", alignItems: 'center' }}>
                  <img src="/assets/images/USDT.png" style={{ height: '16px', width: '16px', borderRadius: '50%' }} />
                  <GreenText>{row.profit}</GreenText>
                </div>
                :

                <div style={{ display: 'flex', gap: "4px", alignItems: 'center' }}>
                  <img src="/assets/images/USDT.png" style={{ height: '16px', width: '16px', borderRadius: '50%' }} />
                  <RedText>{row.profit}</RedText>
                </div>
              }
            </TableCell>
            <TableCell style={{ color: '#99A4B0', fontWeight: 'bold' }}>{row.dateTime}</TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  );
};

function BlackjackTable() {
  return (
    <div style={{ marginTop: '12px' }}>
      <Container>
        <AnimatedTable />
      </Container>
    </div>
  );
}

export default BlackjackTable;