import MyEmitter from "events";
import Swal from "sweetalert2";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {encode, decode, forceSatoshiFormat, randomString} from "../../../Helper";
import C from "../../../Constant";

/**
 * Game Engine
 */
function Engine(){

    var self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Gamr Name
     */
    self.game = 'crash';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();
    // self.trigger.setMaxListeners();

    /**
     * Intialize Player
     * @type {boolean}
     */
    self.init = false;

    /**
     * Script Form Data
     * including 2 value ( amount / Payout )
     * name / value / type
     * @type {Array}
     */
    self.formData = [];

    /**
     * Amount for the round
     * @type {null}
     */
    self.amount = null;

    /**
     * Payout value for the round
     * @type {null}
     */
    self.payout = null;

    /**
     * Target User for following
     * @type {null}
     */
    self.targetUser = null;

    /**
     * Game Status
     * 3 type: waiting | started | busted
     */
    self.gameStatus = null;

    /**
     * Player coin
     */
    self.coin = null;

    /**
     * Player Status
     * 3 type: playing | Waiting | null
     */
    self.playerStatus = null;

    /**
     * Game History
     */
    self.history = [];

    /**
     * User Is Playing Game
     */
    self.isPlaying = false;

    /**
     * User Is Holding for Game
     */
    self.isHolding = false;

    /**
     * Game Options
     */
    self.time = null
    self.md5 = null;
    self.amount = null;
    self.force = null;

    /*
     * Start Engine
    */
    self.started = true;

    /**
     * Socket Events
     */

    if(self.started)
    {
        /**
         * Event on Getting Status game
         */
        self.ws.on(C.STATUS_CRASH, function (data) {
            data =  decode(data);
            self.gameStatus = data.status;
            self.history.push(data.crashes);
            self.time = data.time
            data.md5 = (data.md5 !== undefined) ? data.md5: null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('game_status', data);
        });
		
        /**
         * Event on Getting Full History
         */
        self.ws.on(C.HISTORY_CRASH, function (data) {
            data =  decode(data);
            self.emit(C.HISTORY_CRASH, data);
        });

        /**
         * Event on Getting All Players
         */
        self.ws.on(C.PLAYERS_CRASH, function (data) {
            data =  decode(data);
            self.emit('game_players', data);
        });

        /**
         * Event on Playing Game by User
         */
        self.ws.on(C.PLAY_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = true;
            self.emit('play_crash', data);
        });

        /**
         * Event on CashOut Game by User
         */
        self.ws.on(C.FINISH_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = false;
            self.emit('finish_crash', data);
        });

        /**
         * Event on busted game
         */
        self.ws.on(C.BUSTED_CRASH, function (data) {
            data = decode(data);
            self.isPlaying = false;
            self.gameStatus = 'busted';
            self.history.unshift(data);
            self.time = data.time
            data.md5 = (data.md5 !== undefined) ? data.md5: null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('busted_crash', data);
            self.emit('busted_crash_history', data);
        });

        /**
         * Event on started game
         */
        self.ws.on(C.STARTED_CRASH, function (data) {
            data = decode(data);
            self.gameStatus = 'started';
            self.time = data.time
            data.md5 = (data.md5 !== undefined) ? data.md5: null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('started_crash', data);
        });

        /**
         * Event on waiting game
         * Script Excution from here
         */
        self.ws.on(C.WAITING_CRASH, function (data) {
            data = decode(data);
            self.gameStatus = 'waiting';
            self.time = data.time
            data.md5 = (data.md5 !== undefined) ? data.md5: null;
            data.force = data.force;
            data.amount = data.amount;
            self.emit('waiting_crash', data);
        });

        /**
         * Event on Error
         */
        self.ws.on(C.ERROR_CRASH, function (data) {
            data = decode(data);
            self.emit('error_crash', data);
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.STATUS_CRASH);
    self.ws.off(C.ERROR_CRASH);
    self.ws.off(C.WAITING_CRASH);
    self.ws.off(C.STARTED_CRASH);
    self.ws.off(C.BUSTED_CRASH);
    self.ws.off(C.FINISH_CRASH);
    self.ws.off(C.PLAY_CRASH);
    self.ws.off(C.PLAYERS_CRASH);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.play = function () {
    let self = this;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_CRASH, encode({
        amount: self.amount,
        payout: self.payout,
        coin: self.coin
    }));
}

/**
 * Cashout / Finish Round
 */
Engine.prototype.finish = function (time) {
    let self = this;
    self.ws.emit(C.FINISH_CRASH, encode({
        token2: randomString(25) + parseFloat(time).toFixed(2),
    }));
}

/**
 * Get the Game Status
 */
Engine.prototype.getStatus = function () {
    let self = this;
    self.ws.emit(C.STATUS_CRASH);
}

/**
 * Get the Game Players
 */
Engine.prototype.getPlayers = function () {
    let self = this;
    self.ws.emit(C.PLAYERS_CRASH);
}

/**
 * Get the Game History
 */
Engine.prototype.getHistory = function () {
    let self = this;
    self.ws.emit(C.HISTORY_CRASH);
}

/** Start Engine **/
export default Engine;