/* eslint-disable */
import React from "react";
import $ from "jquery";
import "./Lib/myWheel";
import {__, wait, isMobile} from "../../../../Helper";

const SPIN_BUTTON = "spin-to-win";

let items = []

class Wheel {
    constructor(engine, segment, risk, hash){
        this._isMounted = true;
        this.engine = engine;
        this.hash = hash;
        this.segment = segment;
        this.risk = risk;
        this.width = 350;
        this.target = null
    }

    show(tag){
        return this.loadWheel(tag)
    }

    destroy(){
        this._isMounted = false;
    }
    
    loadWheel(tag){
        if(isMobile()){
            this.width = 230;
        }

        let self = this;

        $("." + tag).pedramWheel({
            destroy: true,
            items: this.loadPiece(),
            width: self.width,
            // random: true,
            rotates: 16,

            // TRICK
            random: false,
            selector: self.target,
            selected: self.target,
            
            button: "." + SPIN_BUTTON,
            shadow : '#FFF',
            centerBackground: '#0b0f1b',
            outerLineColor: "transparent",
            sliceLineColor: "#1a2236",
            onStart: function(res) {
                $('#sloader').removeClass('SLoader');
                $('#sloader').addClass('Loader');
            },
            onComplete: function(results) {
                $('#sloader').removeClass('Loader');
                $('#sloader').addClass('SLoader');

                if(results.message === 'Winner')
                {
                    $('#cimg').attr('src', '/assets/images/ripple-win.png');
                    wait(1000).then(() => {
                        $('#cimg').attr('src', '/assets/images/ripple.png');
                    })
                }

                $('#sloader').html(results.name.toFixed(2) + 'x');

                self.busted(results.name.toFixed(2), results.win)
            }
        });
    }

    busted(name, win){
        if(this._isMounted){
            const engine = this.engine;
            engine.init = true;
            engine.win = win;
            engine.resultNumber = name
            engine.busted();
        }
    }

    loadPiece() {
        let count = parseFloat(this.segment);
        let calcRisk = Math.round(count / this.risk);
        items = [];

        //Green
        items.push({
            id: 100,
            name: 2,
            message: "Winner",
            color: "#5fd034",
            win: true
        });

        //Black
        for ( let i = 0; i < count - calcRisk; i++ ) {
            items.push({
                id: i,
                name: 1,
                message: "Winner",
                color: "#1b2336",
                win: true
            });
        }

        //Red
        for ( let i = 1; i < calcRisk; i++ )
        {
            items.push({
                id: i,
                name: 0,
                message: "Lost",
                color: "#e91e63",
                win: false
            });
        }

        return _.orderBy(items, ['id'], ['asc']);
    }
}

export default Wheel;
