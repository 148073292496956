import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { forceSatoshiFormat, encode, decode } from "../../../Helper";

/**
 * Game Engine
 */
function Engine() {

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'roulette';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Itialize Game
     */
    self.init = false;

    /**
     * Game Status
     * 1 type: busted_roulette
     */
    self.gameStatus = null;

    /**
     * Current User id
     */
    self.token = null;

    /**
     * Round data
     */
    self.data = [];

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Bet Number
     */
    self.betNumber = 0;

    /**
     * Increase Amount on Win
     */
    self.onWin = 0;

    /**
     * Increase Amount on Lost
     */
    self.onLost = 0;

    /**
     * Played Game Rounds
     */
    self.played = 0;

    /**
     * Player is the Win or Lost
     */
    self.isWin = false;

    /**
     * Stop Playing if Won Amount
     */
    self.stopOnWin = 0;

    /**
     * Round Profit
     */
    self.roundProfit = 0;

    /*
     * Start Engine
    */
    self.started = true;

    if (self.started) {
        self.ws.on(C.PLAY_ROULETTE, (data) => {
            data = decode(data);
            switch (data.command) {
                case 'play':
                    return self.trigger.emit(C.PLAY_ROULETTE, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_ROULETTE, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_ROULETTE, data);
            }
        });
    }
}

Engine.prototype.off = function () {
    this.ws.off(C.PLAY_ROULETTE);
}

Engine.prototype.on = function (name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if (!self.init)
        return;

    self.ws.emit(C.PLAY_ROULETTE, encode({
        command: 'play',
        data: self.data,
        coin: self.coin
    }));
};

/**
 * Busted Game
 */
Engine.prototype.busted = function (result, win) {
    let self = this;

    self.ws.emit(C.PLAY_ROULETTE, encode({
        command: 'busted',
        result: result,
        profit: win
    }));
};

/** Start Game Engine **/
export default Engine
