import * as PIXI from "pixi.js";
import Effect from "./components/Effect";

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export const rand = (length) => Math.floor(Math.random() * length);

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

class Game {
  constructor() {
    this.app = new PIXI.Application({
      width: 600,
      height: 250,
      resolution: 1,
      transparent: true,
      view: document.getElementById('game')
    });
    this.number = [];
    this.started = false;
  }

  init() {
    PIXI.Loader.shared.load(e => this.load());
  }

  load() {
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true
    this.container.sortableChildren = true
    this.app.stage.addChild(this.container);

    this.configAnim()
    this.configMachine()
    this.configLabel()
    this.configNumbers()
  }

  configAnim() {
    let time = 0;
    this.app.ticker.add((delta) => {
      if (this.started) {
        if (time % 5 == 0) {
          for (var i = 0; i < 5; i++) {
            this.number[i].text = rand(9)
          }
        }
        time++
      }
    })
  }

  changeLabel(label) {
    this.label.texture = PIXI.Texture.from(`assets/images/hashdice/${label}.png`);
  }

  play(target) {
    this.started = true;

    if (target.length === 4) {
      target = target + '0'
    }

    wait(2000).then(() => {
      for (var i = 0; i < 5; i++) {
        this.number[i].text = target[i]
      }
      this.started = false;
    })
  }

  configMachine() {
    let machine = new PIXI.Sprite.from('assets/images/hashdice/machine.png');
    machine.x = 30
    machine.y = 60
    this.container.addChild(machine)
  }


  configLabel() {
    this.label = new PIXI.Sprite.from('assets/images/hashdice/low.png');
    this.label.x = 45
    this.label.y = 135
    this.container.addChild(this.label)
    Effect.FadeIn(this.label)
  }

  configNumbers() {
    const style = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 65,
      fill: [0xFFFFFF, 0xFFFFFF],
      fontWeight: 700,
    });
    for (var i = 0; i < 5; i++) {
      this.number[i] = new PIXI.Text(i, style);
      this.number[i].y = 40;
      this.number[i].x = 113 * i + 70
      this.container.addChild(this.number[i]);
      // Effect.FadeIn(this.number[i]);
      Effect.MoveIn(this.number[i], 113 * i + 70, 75);
    }
  }

  destroy() {
    this.app = null;
  }
}

export default Game;