import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import socket from "../../../../Socket";
import {
  __,
  addNewStyle,
  CRISTP_ID,
  decode,
  defaultAvatar,
  encode,
  getUID,
  sendNotfication,
  toUSD,
  wait,
} from "../../../../Helper";
import UserModal from "../../../Components/User/Stat/Modal";
import C from "../../../../Constant";
import {
  Dropdown,
  ButtonGroup,
  Form,
  Modal,
  Spinner,
  Image,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { X } from "lucide-react";
import ManualDeposit from "./ManualDeposit";
import axios from "axios";
import { Select } from "@mui/material";

// Styled components

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #0056b3;
  }

  &.active {
    background-color: #0056b3;
  }
`;

const Input = styled.input`
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
`;

const Container = styled.div`
  background-color: #1e2024;
  padding: "20px";
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

const CubBalance = styled.div`
  color: #99999;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

const ModalTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181b;
    border-radius: 15px;
    border: none;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
    @media (min-width: 1200px) {
      max-width: 75%;
      width: 900px;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
  background-color: #17181b;
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledButtonNew = styled.button`
  background-color: #56cf16;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #56cf16;
    transform: scale(1.05);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family: normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => (props.primary ? "#6C5CE7" : "#4A4D56")};
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  padding: 12px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${(props) => (props.primary ? "#5849C2" : "#3A3D46")};
  }
`;

const HertContainer = styled.div`
  background-color: #1c1d21;
  padding: 0px 0.75rem;
  border-radius: 20px;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const FlexContainerStar = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

const Pencil = styled.div`
display:flex;
justify-content:center
background-color:#1C1D21;
padding: 0.75rem;
border-radius:50%;
`;

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  margin-right: 12px;
  align-items: center;
`;

const MenuItemContent = styled.div`
  color: #676f78;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 12px;
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #222428;
  }
`;

const IconWrapperNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`;

const StyledImg = styled.img`
  height: 18px;
  width: 18px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg)
    brightness(94%) contrast(87%);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #1e2024;
  border-radius: 20px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ProgressBarText = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
`;

const ProgressBarWrapper = styled.div`
  background-color: #424a55;
  border-radius: 20px;
  height: 8px;
  width: 85%;
`;

const ProgressBarFill = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
  width: ${(props) => props.progress}%;
`;

// Styled components
const Container1 = styled.div`
  color: white;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  background-color: #292c32;
  border-radius: 50px;
  margin-bottom: 16px;
  width: 100%;
`;

const HeaderButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
  ${(props) =>
    props.active
      ? `
        background-color: #3B5432;
        color: white;
      `
      : `
        background-color: transparent;
        color: #9ca3af;
        &:hover {
          background-color: #3B5432;
          color: white;
        }
      `}
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  color: #9ca3af;
  font-size: 14px;
  margin-bottom: 16px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
`;

const PlaceholderImage = styled.img`
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
`;

const PlaceholderText = styled.p`
  color: #9ca3af;
`;

// Main ModalBody content
const ModalContent = () => {
  const [activeTab, setActiveTab] = useState("Deposit");
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch API data based on the active tab and uid
  const fetchData = async (tab) => {
    const uid = getUID; // Get UID from your function
    setLoading(true);
    setError(null);
    const req_type = activeTab === "Deposit" ? "deposit" : "withdrawal";
    try {
      const response = await axios.get(
        `https://api.ekjeet.com/api/manual-${tab.toLowerCase()}New`,
        {
          params: { uid, req_type }, // Pass the UID as a query parameter
        }
      );
      console.log("API Response manual deposit:", response.data);
      setTransactionData(response?.data?.data);
    } catch (err) {
      console.error("API call failed", err);
      setError("Failed to fetch data.");
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options); // Format based on locale
  };
  // Fetch data whenever activeTab changes
  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  return (
    <Container1 fluid>
      {/* Header Buttons */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <HeaderButtonGroup>
          <HeaderButton
            active={activeTab === "Deposit"}
            onClick={() => setActiveTab("Deposit")}
          >
            Deposit History
          </HeaderButton>
          <HeaderButton
            active={activeTab === "Withdraw"}
            onClick={() => setActiveTab("Withdraw")}
          >
            Withdraw History
          </HeaderButton>
        </HeaderButtonGroup>
      </div>

      {/* Content */}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" />
          <p className="ms-3">Loading data...</p>
        </div>
      ) : error ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <p>{error}</p>
        </div>
      ) : transactionData.length === 0 ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Image
            src="/assets/images/emptyPanda.webp"
            style={{ height: "200px", width: "100px", objectFit: "contain" }}
          />
          <p>No transactions available.</p>
        </div>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr style={{ color: "#9ca3af" }}>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>{activeTab === "Deposit" ? "UTR No/Order No" : "UPI NO?Order No"}</th>
              <th>{activeTab === "Deposit" ? "Deposit Mode" : "Withdrawal Mode"}</th>
            </tr>
          </thead>
          <tbody>
            {transactionData.map((transaction, index) => (
              <tr key={index} style={{ color: "#9ca3af" }}>
                <td>
                  {activeTab === "Deposit"
                    ? formatDateTime(transaction.transaction_date)
                    : formatDateTime(transaction.transaction_date)}
                </td>
                <td>{transaction.amount}</td>
                <td>{transaction.status}</td>
                <td>
                  {activeTab === "Deposit"
                    ? transaction.utr_no
                    : transaction.utr_no}
                </td>
                <td>
                  {activeTab === "Deposit"
                    ? transaction.transaction_type
                    : transaction.transaction_type}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container1>
  );
};

let order_id;

const DepositModel = () => {
  const [amount, setAmount] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [depositPaymentSetting, setDepositPaymentSetting] = useState({});
  const [step, setStep] = useState(1); // Step 1 for amount entry, Step 2 for QR/UTR entry
  const [depositType, setDepositType] = useState("manual"); // New state for deposit type selection
  const [loading, setLoading] = useState(false)
   // Handle socket connection and submission
  useEffect(() => {
    socket.on(C.SUBMIT_MANUAL_DEPOSIT, (data) => {
      setLoading(false)
      const decodedData = decode(data);
      setManualDeposit(decodedData);

    });

    socket.on(C.SUBMIT_ONLINE_DEPOSIT, (data) => {
      setLoading(false)
      const decodedData = decode(data);
      console.log(decodedData)
      if (decodedData.data.status === "SUCCESS") {
        
          window.location.href = decodedData.data.payment_link; // Redirect to payment link
        } else {
          alert("Failed to create payment link", "error");
        }
    });
    
    socket.emit(C.DEPOSIT_PAYMENT_SETTING_INFO, encode({
      id: getUID,
    }));

    socket.on(C.DEPOSIT_PAYMENT_SETTING_INFO, (data) => {
      const settingInfo = decode(data);
      setDepositPaymentSetting(settingInfo);
    });

    // Clean up the socket listener on component unmount
    return () => {
      socket.off(C.SUBMIT_MANUAL_DEPOSIT);
    };
  }, []);

  const setManualDeposit = (data) => {
    console.log(data);
    if (data.status === true) {
      alert(data.msg, "success", "bottom-left");
    } else {
      alert(data.status, "error", "bottom-left");
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleUtrChange = (event) => {
    setUtrNumber(event.target.value);
  };

  const handleSubmitAmount = (event) => {
    event.preventDefault();

  // Validate the amount before proceeding to the next step
  if (parseFloat(amount) < 500 || parseFloat(amount) > 49000) {
    sendNotfication("Amount must be between 500 and 49,000", "success", "bottom-left");
    return;
  }

  setStep(2); // Move to step 2 to show QR and UTR entry
};

  const handleSubmitDeposit = (event) => {
    event.preventDefault();

     // Validate the amount before submitting the deposit
  if (parseFloat(amount) < 500 || parseFloat(amount) > 49000) {
    sendNotfication("Amount must be between 500 and 49,000", "success", "bottom-left");
    return;
  }

    setLoading(true)

    const data = {
      id: getUID,
      amount: parseFloat(amount),
      utr_no: utrNumber,
      req_type: "deposit",
      status: "pending",
      transaction_type: "manual",
    };

    // Emit the socket event to submit deposit
    socket.emit(C.SUBMIT_MANUAL_DEPOSIT, encode(data));
  };

  function generateTransactionId(phoneNumber) {
    const seed = Date.now();
    const combinedString = String(phoneNumber) + seed;
    const hashCode = combinedString.split("").reduce((hash, char) => {
      return (hash << 5) - hash + char.charCodeAt(0);
    }, 0);
    return Math.abs(hashCode % 100000000).toString().padStart(8, "0");
  }

  order_id = generateTransactionId(getUID)

  const handleOnlineDeposit = (event) => {
    setLoading(true)
    event.preventDefault();
    console.log(event)
   const data = {
      id: getUID,
      amount: parseFloat(amount),
      order_id: order_id,
      req_type: "deposit",
      status: "pending",
      transaction_type: "online",
    };

    localStorage.setItem("orderId", order_id)

    console.log(data)
    // Emit the socket event to submit deposit
    socket.emit(C.SUBMIT_ONLINE_DEPOSIT, encode(data));
  };

  return (
    <Container1 style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
    <div style={{ width: "100%", textAlign: "center" }}>
      <h4>Select Deposit Type</h4>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "20px" }}>
        <Button
          onClick={() => setDepositType("manual")}
          style={{ padding: "10px 20px", fontSize: "16px" }}
        >
          Manual Deposit
        </Button>
        <Button
          onClick={() => setDepositType("online")}
          style={{ padding: "10px 20px", fontSize: "16px" }}
        >
          Online Deposit
        </Button>
      </div>
  
      {depositType === "manual" ? (
        step === 1 ? (
          <form onSubmit={handleSubmitAmount} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Form.Group>
              <Label style={{ fontSize: "16px", marginBottom: "8px" }}>Amount:</Label>
              <Input
                type="text"
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter amount"
                required
                min={500}
                max={49000}
                style={{ padding: "10px", fontSize: "14px", borderRadius: "8px", border: "1px solid #ccc" }}
              />
            </Form.Group>
            <Button type="submit" style={{ padding: "12px 20px", fontSize: "16px", borderRadius: "8px", backgroundColor: "#007bff", color: "#fff" }}>
              Continue to Payment
            </Button>
          </form>
        ) : (
          <form onSubmit={handleSubmitDeposit} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Form.Group>
              <Label style={{ fontSize: "16px", marginBottom: "8px" }}>Scan QR code or enter UTR Number</Label>
              <img
                src={`https://api.ekjeet.com/uploads/${depositPaymentSetting.qr_img}`}
                alt="Scan QR Code"
                style={{ width: "250px", height: "200px", objectFit: "contain", margin: "0 auto" }}
              />
              <p style={{ fontSize: "14px", textAlign: "center" }}>
                Scan the QR code above to make the payment or enter UTR below to confirm:
              </p>
              <Label style={{ fontSize: "16px", marginBottom: "8px" }}>UTR Number:</Label>
              <Input
                type="text"
                value={utrNumber}
                onChange={handleUtrChange}
                placeholder="Enter UTR number"
                required
                style={{ padding: "10px", fontSize: "14px", borderRadius: "8px", border: "1px solid #ccc" }}
              />
            </Form.Group>
            <Button
              type="submit"
              style={{
                padding: "12px 20px",
                fontSize: "16px",
                borderRadius: "8px",
                backgroundColor: "#28a745",
                color: "#fff",
              }}
            >
              {loading ? "Processing..." : "Submit Deposit"}
            </Button>
          </form>
        )
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Label style={{ fontSize: "16px", marginBottom: "8px" }}>Amount:</Label>
          <Input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount for online deposit"
            required
            style={{ padding: "10px", fontSize: "14px", borderRadius: "8px", border: "1px solid #ccc" }}
          />
          <Button
            onClick={handleOnlineDeposit}
            style={{
              padding: "12px 20px",
              fontSize: "16px",
              borderRadius: "8px",
              backgroundColor: "#17a2b8",
              color: "#fff",
            }}
          >
            {loading ? "Processing..." : "Proceed to Online Payment"}
          </Button>
        </div>
      )}
    </div>
  </Container1>
  
  );
};

const WithdrawalModel = () => {
  const [amount, setAmount] = useState("");
  const [utrNumber, setUtrNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankname, setBankName] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState("manual");  // Default is manual

  // Handle socket connection and submission
  useEffect(() => {
    
    socket.on(C.SUBMIT_MANUAL_WITHDRAWL, (data) => {
      const decodedData = decode(data);
      if (decodedData?.status === true) {
        setTimeout(() => {
          sendNotfication(decodedData?.msg, "success", "bottom-left");
        }, 1000);
       
      } else {

        setTimeout(() => {
          sendNotfication(decodedData?.msg, "error", "bottom-left");
         
        }, 1000);
        
      }
      setLoading(false)
    });
    socket.on(C.SUBMIT_ONLINE_WITHDRAWL, (data) => {
      const decodedData = decode(data);
      console.log(decodedData.message);
      if (decodedData?.message?.status == 200) {
        setTimeout(() => {
          sendNotfication(decodedData?.message?.success, "success", "bottom-left");
         
        }, 1000);

        } else {
          
            sendNotfication(decodedData?.message?.error, "error", "bottom-left");
           
        }
        setLoading(false)
    });

    // Clean up the socket listener on component unmount
    return () => {
      socket.off(C.SUBMIT_MANUAL_WITHDRAWL);
      socket.off(C.SUBMIT_ONLINE_WITHDRAWL);
      setLoading(false)
    };
  }, []);



  const handleAmountChange = (event) => {
      setAmount(event.target.value);
  };

  const handleUtrChange = (event) => {
    setUtrNumber(event.target.value);
  };

  const handleAccountNumberChange = (event) => {
    setAccountNumber(event.target.value);
  };

  const handleIfscCodeChange = (event) => {
    setIfscCode(event.target.value);
  };

  const handlebanknameChange = (event) => {
    setBankName(event.target.value);
  };

  const handleTransactionTypeChange = (event) => {
    setTransactionType(event.target.value);
  };


  function generateTransactionId(phoneNumber) {
    const seed = Date.now();
    const combinedString = String(phoneNumber) + seed;
    const hashCode = combinedString.split("").reduce((hash, char) => {
      return (hash << 5) - hash + char.charCodeAt(0);
    }, 0);
    return Math.abs(hashCode % 100000000).toString().padStart(8, "0");
  }

  order_id = generateTransactionId(getUID)


  const handleSubmit = (event) => {

    // Check if value is a valid number and within the required range
  if (amount && (parseFloat(amount) < 500 || parseFloat(amount) > 49000)) {
      setLoading(false)
      // Optionally, you could set an error state here
      sendNotfication("Amount must be between 500 and 49,000", "success", "bottom-left");
      return
    } else {
      setAmount(amount);
    }

    setLoading(true)
    event.preventDefault();

    if (transactionType === "manual") {
      const data = {
        amount: parseFloat(amount),
        utr_no: utrNumber,
        req_type: "withdrawal",
        status: "pending",
        transaction_type: "manual",
      };

      // Emit the socket event for manual withdrawal
      socket.emit(C.SUBMIT_MANUAL_WITHDRAWL, encode(data));
    } else if (transactionType === "online") {
      const data = {
        amount: parseFloat(amount),
        account_no: accountNumber,
        ifsccode: ifscCode,
        bankname: bankname, // Use dynamic data if needed
        orderid:  order_id,
        req_type: "withdrawal",
        status: "pending",
        transaction_type: "online",
      };

      console.log(data)

      // Emit the socket event for online withdrawal
      socket.emit(C.SUBMIT_ONLINE_WITHDRAWL, encode(data));
    }
  };

  return (
    <Container1 style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
    <div style={{ width: "100%" }}>
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      
      <Label style={{ fontSize: "16px", marginBottom: "8px" }}>Payment Mode:</Label>
      <select
        value={transactionType}
        onChange={handleTransactionTypeChange}
        required
        style={{
          padding: "10px 14px",
          fontSize: "14px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          backgroundColor: "#f9f9f9",
          width: "100%",
          maxWidth: "300px",
          marginBottom: "4px",  // Add space between select and next elements
        }}
      >
        <option value="manual" style={{ fontSize: "14px" }}>
          Manual Withdrawal
        </option>
        <option value="online" style={{ fontSize: "14px" }}>
          Online Withdrawal
        </option>
      </select>

      <Label style={{ fontSize: "16px", marginBottom: "0px" }}>Amount:</Label>
      <Input
        type="text"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter amount"
        required
        style={{
          padding: "10px",
          fontSize: "14px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          backgroundColor: "#f9f9f9",
        }}
      />

      {transactionType === "manual" ? (
        <>
          <Label style={{ fontSize: "16px", marginBottom: "0px" }}>UPI Id:</Label>
          <Input
            type="text"
            value={utrNumber}
            onChange={handleUtrChange}
            placeholder="Enter UPI Id"
            required
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          />
        </>
      ) : (
        <>
          <Label style={{ fontSize: "16px", marginBottom: "8px" }}>Bank Name:</Label>
          <Input
            type="text"
            value={bankname}
            onChange={handlebanknameChange}
            placeholder="Enter Bank Name"
            required
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          />

          <Label style={{ fontSize: "16px", marginBottom: "0px" }}>Account Number:</Label>
          <Input
            type="text"
            value={accountNumber}
            onChange={handleAccountNumberChange}
            placeholder="Enter Account Number"
            required
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          />

          <Label style={{ fontSize: "16px", marginBottom: "0px" }}>IFSC Code:</Label>
          <Input
            type="text"
            value={ifscCode}
            onChange={handleIfscCodeChange}
            placeholder="Enter IFSC Code"
            required
            style={{
              padding: "10px",
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          />
        </>
      )}

      <Button
        type="submit"
        style={{
          padding: "12px 20px",
          fontSize: "16px",
          borderRadius: "8px",
          backgroundColor: "#007bff",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {loading ? "Processing..." : "Submit Withdrawal"}
      </Button>
    </form>
    </div>
</Container1>

  );
};


class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionType: "deposit", // Default to deposit
      amount: "",
      upiNumber: "",
      utrNumber: "",
      isModalOpenDeposit: false,
      isModalOpenWithdrawal: false,
    };
  }

  toggleModalDeposit = () => {
    this.setState((prevState) => ({
      isModalOpenDeposit: !prevState.isModalOpenDeposit,
    }));
  };
  toggleModalWithdrawal = () => {
    this.setState((prevState) => ({
      isModalOpenWithdrawal: !prevState.isModalOpenWithdrawal,
    }));
  };

  render() {
    const {
      transactionType,
      amount,
      upiNumber,
      utrNumber,
      isModalOpenDeposit,
      isModalOpenWithdrawal,
    } = this.state;

    return (
      <Container>
        {/* deposit */}
        <StyledModal
          show={isModalOpenDeposit}
          onHide={this.toggleModalDeposit}
          centered
        >
          <ModalHeader>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>Deposit</div>

            <X onClick={this.toggleModalDeposit} size={24} />
          </ModalHeader>
          <ModalBody>
            <DepositModel />
          </ModalBody>
        </StyledModal>

        {/* withdrawal */}
        <StyledModal
          show={isModalOpenWithdrawal}
          onHide={this.toggleModalWithdrawal}
          centered
        >
          <ModalHeader>
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              Withdrawal
            </div>

            <X onClick={this.toggleModalWithdrawal} size={24} />
          </ModalHeader>
          <ModalBody>
            <WithdrawalModel />
          </ModalBody>
        </StyledModal>

        <div style={{ padding: "16px", display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button
            onClick={() => this.setState({ isModalOpenDeposit: true })}
            style={{
              padding: "12px 24px",
              fontSize: "16px",
              borderRadius: "8px",
              backgroundColor: "#28a745", // Green for Deposit
              color: "#fff",
              border: "none",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              transition: "background-color 0.3s ease, transform 0.3s ease",
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = "#218838"} // Darker green on hover
            onMouseLeave={(e) => e.target.style.backgroundColor = "#28a745"} // Reset to original green
            onMouseDown={(e) => e.target.style.transform = "scale(0.98)"} // Subtle click effect
            onMouseUp={(e) => e.target.style.transform = "scale(1)"}
          >
            <span style={{ fontSize: "18px" }}>💰</span> {/* Optional Icon */}
            Deposit
          </Button>
          
          <Button
            onClick={() => this.setState({ isModalOpenWithdrawal: true })}
            style={{
              padding: "12px 24px",
              fontSize: "16px",
              borderRadius: "8px",
              backgroundColor: "#dc3545", // Red for Withdrawal
              color: "#fff",
              border: "none",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              transition: "background-color 0.3s ease, transform 0.3s ease",
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = "#c82333"} // Darker red on hover
            onMouseLeave={(e) => e.target.style.backgroundColor = "#dc3545"} // Reset to original red
            onMouseDown={(e) => e.target.style.transform = "scale(0.98)"} // Subtle click effect
            onMouseUp={(e) => e.target.style.transform = "scale(1)"}
          >
            <span style={{ fontSize: "18px" }}>💸</span> {/* Optional Icon */}
            Withdrawal
          </Button>
        </div>


        <ModalContent />
      </Container>
    );
  }
}

export default Payment;
