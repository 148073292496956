/* eslint-disable */
import React from 'react';
import storage from "../../../Storage";
import {wait, chkd, isMobile, getRandomInt, Event, playAudio} from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./components/Canvas";

let loaded = false;

class Game extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            engine: this.props.engine,
            token: storage.getKey('token'),
            started: false,
            canvas: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;
        this.setUpCanvas();

        engine.on(C.PLAY_MINE, () => this.play());
        engine.on(C.CLICKED_MINE, (data) => {this.clicked(data)
    console.log(data)});
        engine.on(C.BUSTED_MINE, (data) => this.endGame(data));
        engine.on(C.CASHOUT_MINE, (data) => this.endGame(data));

        if(isMobile()){
            let canvas = document.getElementById('game');
            canvas.style.width = '100%'
            canvas.style.height = '100%'
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if(this.state.canvas !== null)
            this.state.canvas.destroy();
    }

    setUpCanvas(){
        if(this._isMounted){
            let canvas = new Canvas()
            this.setState({ canvas: canvas })
            canvas.init(this.select);
        }
    }

    select = (id) => {
        let { engine, started } = this.state;
       
        if(started)
            
        {
            engine.init = true;
            engine.land = id;
            engine.clicked();
        }
    };

    play = () => {
        if(this._isMounted){
            this.state.canvas.play();
            this.setState({ started: true });
        }
    };

    clicked(data){
        if(this._isMounted){
            let { id } = data;
            this.state.canvas.afterSelect(id);
            playAudio('win.mp3');
        }
    }

    endGame(data){
        if(this._isMounted){
            let { mines } = data;
            
            this.state.canvas.busted(mines);

            this.setState({ started: false });

            wait(2200).then(() => {
                if(this.state.canvas !== null)
                    this.state.canvas.reset();
            })
        }
    }

    render() {
        return(
            <div className="_mine_container animated fadeIn">
                <div className="_cf_info _cf_info_m">
                    <div className="text-center" id="cc">
                        <canvas id="game" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Game;
