import * as PIXI from "pixi.js";
import { __ } from "../../../../Helper";

function Canvas() {
    let self = this;

    self.pixi = PIXI;
    self.app = null;
    self.container = null;
    self.stage = false;
    self.ticker = false;
    self.started = false;
    self.coined = false;
    self.ended = false;
    self.callback = null;
    self.target_coin = null;
    self.space = 51;
    self.landWidth = 47;
    self.landHeight = 47;
    self.target_bomb = [];
    self.land = [];
    self.hover = [];
    self.coin = [];
    self.target = {}

    self.init = function(callback) {
        self.callback = callback;
        self.renderer = new self.pixi.Renderer({
            view: document.getElementById("game"),
            width: 380,
            height: 380,
            transparent: true
        });

        self.stage = new self.pixi.Container();
        self.container = new self.pixi.Container();
        self.container.sortableChildren = true;
        self.stage.addChild(self.container);

        let id = 0;
        for (var i = 0; i < 5; i++) {
            for (var j = 0; j < 5; j++) {
                let x = j * self.space;
                let y = i * self.space;
                self.addLand(id, x, y);
                id++;
            }
        }

        const ticker = new self.pixi.Ticker();
        ticker.add(animate)
        ticker.start();
        self.ticker = ticker;

        let count = 0;

        function animate(delta) {
            count += 0.1;

            if (self.ended) {
                for (var i = 0; i < self.target_bomb.length; i++) {
                    if (!__.isUndefined(self.target_bomb[i])) {
                        if (self.target_bomb[i].scale.x < 0.7) {
                            self.target_bomb[i].scale.x += 0.02 * delta
                            self.target_bomb[i].scale.y += 0.02 * delta
                            self.target_bomb[i].x -= 0.5 * delta
                            self.target_bomb[i].y -= 0.5 * delta
                        }
                        self.target_bomb[i].x += Math.cos(count) * delta / Math.PI
                        self.target_bomb[i].y += Math.sin(count) * delta / Math.PI
                    }
                }
            }

            if (self.coined) {
                if (self.target_coin.scale.x < 0.6) {
                    self.target_coin.scale.x += 0.01 * delta
                    self.target_coin.scale.y += 0.01 * delta
                    self.target_coin.x -= 0.5 * delta
                    self.target_coin.y -= 0.5 * delta
                }
            }

            self.renderer.render(self.stage);
        }
    }


    self.addLand = function(id, x, y) {
        const graphics = new PIXI.Graphics();
        graphics.lineStyle(0);
        graphics.beginFill(0x1b2336, 1);
        graphics.drawRoundedRect(x, y, self.landWidth, self.landHeight, 5);
        graphics.endFill();
        graphics.interactive = true;  // Makes the land interactive (clickable)
        graphics.buttonMode = true;
        graphics.scale.x = 1.5;
        graphics.scale.y = 1.5;
        graphics.sortChildren(1);
        graphics.zIndex = 1;  // Ensure correct stacking order
    
        graphics.xx = x;
        graphics.yy = y;
    
        // Add debug logging for clicks
        graphics.on("pointerover", (event) => self.onHover(id, x, y));
        graphics.on("pointerout", (event) => self.onOut(id));
        graphics.on("click", (event) => {
            console.log(`Clicked on box with id: ${id}`);  // Debug logging
            self.onClick(id, x, y);
        });
        graphics.on("touchstart", (event) => {
            console.log(`Touched on box with id: ${id}`);  // Debug logging
            self.onClick(id, x, y);
        });
    
        self.land.push(graphics);
        self.container.addChild(graphics);
    }
    

    self.onHover = function(id, x, y) {
        if (self.ended) return;

        if (self.coin[id]) return;

        const graphics = new PIXI.Graphics();
        graphics.lineStyle(0);
        graphics.beginFill(0x273452, 1);
        graphics.drawRoundedRect(x, y, self.landWidth, self.landHeight, 5);
        graphics.endFill();
        graphics.sortChildren(2);
        graphics.zIndex = 1;
        self.hover[id] = graphics;
        self.land[id].addChild(graphics);
    }

    self.onOut = function(id) {
        self.land[id].removeChild(self.hover[id]);
    }

    self.onClick = function(id, x, y) {
        if (!self.started) return;
        self.target = {
            x,
            y
        };
        self.callback(id);
    }

    self.afterSelect = function(id) {
        let {
            x,
            y
        } = self.target;
        self.addCoin(id, x, y);
    }

    self.play = function() {
        self.started = true;
    }

    self.addCoin = function(id, x, y) {
        console.log(id, x, y)
        if (self.coin[id] || self.ended || !self.started) return;
        self.coined = true;

        let coin = PIXI.Sprite.from('./assets/images/cm.png');

            coin.x = x + 5;
            coin.y = y + 5;
            coin.width = 40
            coin.height = 40

        coin.sortChildren(3);
        coin.zIndex = 2;
        self.target_coin = coin;
        self.coin[id] = coin;
        self.land[id].addChild(coin);
    }

    self.addBomb = function(id) {
        let bomb = PIXI.Sprite.from('./assets/images/bm.png');

            bomb.x = self.land[id].xx + 5;
            bomb.y = self.land[id].yy + 5;
            bomb.width = 40
            bomb.height = 40

        bomb.sortChildren(3);
        bomb.zIndex = 2;
        self.target_bomb[id] = bomb;
        self.land[id].addChild(bomb);
    }

    self.busted = function(mines) {
        self.ended = true;

        mines.forEach((mine, i) => {
            self.addBomb(mine.num);
        })
    }

    self.reset = function() {
        for (var i = 0; i < self.land.length; i++) {
            self.land[i].removeChild(self.target_bomb[i]);
            self.land[i].removeChild(self.coin[i]);
            self.container.removeChild(self.land[i]);
        }
        self.container = null;
        self.stage = false;
        self.coined = false;
        self.ended = false;
        self.started = false;
        self.target_coin = null;
        self.target_bomb = [];
        self.land = [];
        self.hover = [];
        self.coin = [];
        self.ticker.stop()
        return this.init(self.callback);
    }

    self.destroy = function() {
        for (var i = 0; i < self.land.length; i++) {
            self.land[i].removeChild(self.target_bomb[i]);
            self.land[i].removeChild(self.coin[i]);
            self.container.removeChild(self.land[i]);
        }
        self.container = null;
        self.stage = false;
        self.coined = false;
        self.ended = false;
        self.started = false;
        self.target_coin = null;
        self.target_bomb = [];
        self.land = [];
        self.hover = [];
        self.coin = [];
        self.ticker.stop()
        self.renderer = null;
    }

}

export default Canvas;
