import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const GSContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 10px;

  @media(max-width:415px){
  margin-top: 10px;
  }
`;

const GSCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: 200px;
  height: 62px; // 52px (icon) + 10px (padding)
  margin-bottom: 10px; // Add gap between cards

   @media(max-width:415px){
   padding:0px;
  //  padding-top:10px;
  }
`;

const GSIconPlaceholder = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 10px;

  @media(max-width:415px){
   width: 40px;
   height: 40px;
  }
`;

const GSTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GSProfitText = styled.span`
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, sans-serif;

   @media(max-width:415px){
   font-size:9px !important;
  }
`;

const GSDescriptionText = styled.span`
  font-size: 14px;
  font-family: Arial, sans-serif;

  @media(max-width:415px){
   font-size:10px !important;
  }
`;

const SwipeContainer = styled(GSContainer)`
  height: 134px; // Exact height for two cards: 62px * 2 + 10px gap
  overflow: hidden;
`;

const SwipeWrapper = styled.div`
  transition: transform 0.5s ease;
  transform: translateY(${props => props.translateY}px);
`;


const cardData = [
  {
    iconSrc: '/assets/images/nano/gs1.webp',
    profitText: 'Won ₹48664.27',
    profitColor: '#5DDB1C',
    descriptionText: 'In Plinko',
  },
  {
    iconSrc: '/assets/images/nano/gs2.webp',
    profitText: 'Profit: ₹432.47',
    descriptionText: 'In Mines',
  },
  {
    iconSrc: '/assets/images/nano/gs2.webp',
    profitText: 'Won ₹15320.89',
    profitColor: '#5DDB1C',
    descriptionText: 'In Crash',
  },
  {
    iconSrc: '/assets/images/nano/gs2.webp',
    profitText: 'Profit: ₹789.12',
    descriptionText: 'In Roulette',
  },
  {
    iconSrc: '/assets/images/nano/gs2.webp',
    profitText: 'Won ₹6543.21',
    profitColor: '#5DDB1C',
    descriptionText: 'In Blackjack',
  },
  {
    iconSrc: '/assets/images/nano/gs2.webp',
    profitText: 'Profit: ₹321.98',
    descriptionText: 'In Slots',
  },
];

// Function to generate random profit text
const getRandomProfitText = () => {
  const randomAmount = (Math.random() * 50000).toFixed(2); // Random amount between 0 and 50,000
  const profitType = Math.random() > 0.5 ? 'Won' : 'Profit'; // Randomly choose between 'Won' or 'Profit'
  return `${profitType} ₹${randomAmount}`;
};

const GsCard1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 2) % cardData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const translateY = -currentIndex * 72; // 62px card height + 10px margin

  return (
    <SwipeContainer>
      <SwipeWrapper translateY={translateY}>
        {cardData.map((card, index) => {
          // Randomize profitText if it's not already set
          const profitText = card.profitText ? card.profitText : getRandomProfitText();
          return (
            <GSCard key={index}>
              <GSIconPlaceholder src={card.iconSrc} alt="Game icon" />
              <GSTextContainer>
                <GSProfitText>
                  {profitText.includes('Won') ? (
                    <>
                      Won <span className='small-fonts' style={{ color: card.profitColor }}>{profitText.split(' ')[1]}</span>
                    </>
                  ) : (
                    profitText
                  )}
                </GSProfitText>
                <GSDescriptionText>{card.descriptionText}</GSDescriptionText>
              </GSTextContainer>
            </GSCard>
          );
        })}
      </SwipeWrapper>
    </SwipeContainer>
  );
};

export default GsCard1;