import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";
import socket from "../../../../Socket";
import {
  __,
  addNewStyle,
  CRISTP_ID,
  decode,
  defaultAvatar,
  encode,
  getUID,
  sendNotfication,
  toUSD,
  wait,
} from "../../../../Helper";
import C from "../../../../Constant";
import { useHistory } from "react-router-dom";  // For redirection
import axios from "axios";
import { Spinner, Container } from "react-bootstrap";

// ModalContent Component
const ModalContent = () => {
  const [activeTab, setActiveTab] = useState("Deposit");
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentVerified, setPaymentVerified] = useState(false);

  const history = useHistory(); // For handling redirects


  useEffect(() => {
    socket.on(C.SUBMIT_VERIFY_ONLINE_DEPOSIT, (data) => {
      setLoading(false)
      const decodedData = decode(data);
      console.log(decodedData)
      if (decodedData.data.status === "success") {
        setTimeout(() => {
          sendNotfication(decodedData?.msg, "success", "bottom-left");
          history.push("/payment"); // Redirect to transaction page
        }, 1000);

        } else {
          alert("Failed to create payment link", "error");
        }
    });

    // Clean up the socket listener on component unmount
    return () => {
      socket.off(C.SUBMIT_VERIFY_ONLINE_DEPOSIT);
    };
  }, [history]);

  // Fetch API data based on the active tab and uid
  const fetchData = async () => {
    
    let orderId = parseInt(localStorage.getItem("orderId"));

    setLoading(true);
    setError(null);
    // try {
    //   const response = await axios.get(
    //     `https://indianpay.co.in/admin/payinstatus?order_id=${orderId}`
    //   );
    //   if (response.data.status === "success") {
    //     console.log("Payment verified:", response);
    //     setPaymentVerified(true);
    //   } else {
    //     setError("Payment verification failed.");
    //   }
    // } catch (err) {
    //   console.error("API call failed", err);
    //   setError("Failed to fetch payment status.");
    // } finally {
    //   setLoading(false);
    // }

    const data = {
      id: getUID,
      orderId: orderId,
    };

    // Emit the socket event to submit deposit
    socket.emit(C.SUBMIT_VERIFY_ONLINE_DEPOSIT, encode(data));
  };

  // Fetch data when component mounts or when activeTab changes
  useEffect(() => {
    fetchData();
  }, []); // Empty array ensures this runs once on mount


  return (
    <Container fluid>
      {/* Loading, Error, or Success */}
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" />
          <p className="ms-3">Verifying payment...</p>
        </div>
      ) : error ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <p>{error}</p>
        </div>
      ) : (
        <div className="text-center">
          <p>Payment verification successful!</p>
        </div>
      )}
    </Container>
  );
};

class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize any necessary state here
    };
  }

  render() {
    return (
      <Container>
        {/* Render ModalContent */}
        <ModalContent />
      </Container>
    );
  }
}

export default PaymentSuccess;
