import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1E2024;
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
  overflow-x: auto; /* Allows scrolling on smaller screens */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
  
  /* Ensure the table is scrollable on small screens */
  @media (max-width: 768px) {
    display: block;
    white-space: nowrap;
    table-layout: auto;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #99A4B0;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  font-size: 14px;
  @media (max-width: 768px) {
    padding: 8px;
    font-size: 12px;
  }
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const RedText = styled.span`
  color: #f44336;
`;

const AnimatedTableRow = styled.tr`
  background-color: transparent;
  transition: background-color 0.3s ease;
`;

const NumbersCell = styled.div`
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const generateRandomKenoNumbers = () => {
  const count = Math.floor(Math.random() * 3) + 3; // 3 to 5 numbers
  const numbers = [];
  while (numbers.length < count) {
    const num = Math.floor(Math.random() * 40) + 1;
    if (!numbers.includes(num)) {
      numbers.push(num);
    }
  }
  return numbers.sort((a, b) => a - b).join(',');
};

const generateRandomKenoData = (count) => {
  const data = [];
  for (let i = 0; i < count; i++) {
    const bet = (Math.random() * 99 + 1).toFixed(2); // Bet between 1 and 100
    const isWin = Math.random() < 0.5;
    const profit = isWin ? 
      (Math.random() * 200).toFixed(2) : // Win scenario, up to 200
      "0.00"; // Loss scenario
    const numbers = generateRandomKenoNumbers();
    
    data.push({
      player: "Hidden",
      bet: `$${bet}`,
      profit: profit,
      numbers: numbers
    });
  }
  return data;
};

const AnimatedTable = ({ visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    setAllData(generateRandomKenoData(20));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData(prevData => {
        const newData = [...prevData];
        newData.pop();
        newData.unshift(generateRandomKenoData(1)[0]);
        return newData;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setVisibleData(allData.slice(0, visibleRowsCount));
  }, [allData, visibleRowsCount]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>PLAYER</TableHeader>
          <TableHeader>BET</TableHeader>
          <TableHeader style={{textAlign: 'center'}}>PROFIT</TableHeader>
          <TableHeader style={{textAlign: 'center'}}>NUMBERS</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={index}>
            <TableCell style={{fontWeight:'bold'}}>{row.player}</TableCell>
            <TableCell style={{color:'#99A4B0', fontWeight:'bold'}}>{row.bet}</TableCell>
            <TableCell style={{textAlign: 'center'}}>
              {parseFloat(row.profit) > 0 ? 
                <GreenText>${row.profit}</GreenText> : 
                <RedText>${row.profit}</RedText>}
            </TableCell>
            <TableCell style={{color:'#99A4B0', fontWeight:'bold', textAlign: 'center',}}>
              <NumbersCell>{row.numbers}</NumbersCell>
            </TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  );
};

function KenoMultiplayerTable() {
  return (
    <div style={{marginTop:'12px'}}>
      <Container>
        <AnimatedTable />
      </Container>
    </div>
  );
}

export default KenoMultiplayerTable;