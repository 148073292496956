import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown, Modal, Row, Col } from "react-bootstrap";
import PerfectScrollbar from "perfect-scrollbar";
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { setCoin, gameCoin, setCredit } from "../../../../actions/gameCoin";
import {
  __,
  wait,
  decode,
  encode,
  forceSatoshiFormat,
  Event,
  getUID,
  sendNotfication,
} from "../../../../Helper";
import C from "../../../../Constant";
import styled from "styled-components";
// import {  } from "lucide-react";
import {
  Search,
  Wallet,
  ArrowDownUp,
  Vault,
  ChevronRight,
  WalletCards,
  X,
} from "lucide-react";
import Deposit from "../../../Components/User/Wallet/Deposit";
import DepositHello from "../../../Components/User/Wallet/Deposithello";
import WithdrawlHello from "../../../Components/User/Wallet/Withdrawhello";
import CoinSelectionModal from "../../../Components/CoinSelectionModal";
import SwapHello from "../../../Components/User/Wallet/SwapHello";
import Cookies from "js-cookie";
import axios from "axios";
import { Link } from "react-router-dom";

const Container = styled.div`
  background-color: #1e2024;
  display: flex;
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 8px;

  /* Keep the row layout on smaller screens */
  @media (max-width: 1200px) {
    padding: 4px 6px; /* Adjust padding for medium screens */
  }

  @media (max-width: 768px) {
    padding: 4px 6px; /* Adjust padding for mobile screens */
    justify-content: flex-start; /* Align items to the left */
    gap: 10px; /* Space out items in the row */
  }

  @media (max-width: 480px) {
    padding: 4px 4px; /* Reduce padding on very small screens */
    gap: 8px; /* Adjust space between elements */
  }
`;

const CubBalance = styled.div`
  color: #99999;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; /* Adjust font size for mobile */
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    font-size: 10px; /* Further reduce font size for small screens */
    margin-right: 6px;
  }
`;

const WalletButton = styled.button`
  background-color: #7b61ff;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 13px;
    padding: 9px 18px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    font-size: 11px; /* Reduce font size for very small screens */
    padding: 6px 12px;
    width: auto; /* Keep button size proportional */
  }
`;

const WalletConatiner = styled.div`
  /* Ensure container stays in a row and elements are spaced out */
  display: flex;
  gap: 12px; /* Space between elements in the row */

  @media (max-width: 768px) {
    gap: 10px; /* Slightly smaller gap on smaller screens */
  }

  @media (max-width: 480px) {
    gap: 8px; /* Even smaller gap for small screens */
  }
`;


const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family: normal;
`;



const ManageListButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7438f7;
  border-radius: 30px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  margin-bottom: 10px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  span {
    margin-right: 8px;
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dfdfdf; /* Light gray, you can adjust as needed */
  margin: 20px 0; /* Adds some spacing above and below */
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #222327;
  border-radius: 13px;
  padding: 5px 10px;

  input {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #333;
    flex: 1;
    padding-left: 8px;

    &::placeholder {
      color: #999;
    }
  }
`;

const ResponsiveModal = styled(Modal)`
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
    @media (min-width: 1200px) {
      max-width: 75%;
      width: 500px;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const ModalSubtitle = styled.div`
  font-size: 14px;
  color: #616770;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e2024;
  border-radius: 14px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px; /* Default for larger screens */

  @media (max-width: 1200px) {
    /* For tablets or medium screens */
    gap: 24px;
  }

  @media (max-width: 768px) {
    /* For mobile screens */
    gap: 16px;
  }

  @media (max-width: 480px) {
    /* For smaller mobile screens */
    gap: 0px;
  }
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;

  background-color: ${(props) => (props.active ? "#4caf50" : "transparent")};
  color: ${(props) => (props.active ? "white" : "#8c8c8c")};
  border: none;
  padding: 12px 20px;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  gap: 8px;

  &:hover {
    background-color: ${(props) => (props.active ? "#4caf50" : "#2c2c2c")};
  }

  svg {
    margin-right: 8px;
  }
`;

const ContentArea = styled.div`
  background-color: #1e2024;
  color: white;
  border-radius: 14px;
  padding: 12px 8px;
`;

const ContainerDeposit = styled.div`
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: #8c8c8c;
  font-size: 14px;
  margin-bottom: 12px;
`;

const CurrencyBox = styled.div`
  background-color: #25282c;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const CurrencyInfo = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencyIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: #4caf50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-weight: bold;
  color: white;
`;

const CurrencyName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const BalanceInfo = styled.div`
  text-align: right;
`;

const BalanceLabel = styled.div`
  font-size: 12px;
  color: #8c8c8c;
`;

const BalanceAmount = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const ArrowIcon = styled.span`
  color: #8c8c8c;
  font-size: 18px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const LabelSwap = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: #676f78;
  //   font-family:normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282c;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const ButtonGroup = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  align-items: center;
`;

const ButtonWithdrawl = styled.button`
  background-color: #32363f;
  border: none;
  color: #a0a0a0;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a202c;
  padding: 1rem;
`;

const FeeText = styled.div`
  color: #a0aec0;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;

const GreenText = styled.span`
  color: #56cf16;
`;

const StyledButton = styled.button`
  background-color: #56cf16;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #56cf16;
    transform: scale(1.05);
  }
`;
const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SwitcherContainer = styled.div`
  display: flex;
  background-color: #2b2e36;
  border-radius: 9999px;
  width: 256px;
  justify-content: center;
`;

const SwitcherButton = styled.div`
  flex: 1;
  padding: 8px 16px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => (props.active ? "#3C3F49" : "#2B2E36")};
  cursor: pointer;

  &:hover {
    background-color: #444854; /* Add a hover effect if needed */
  }
`;
// Styled components
const Container1 = styled.div`
  //   background-color: #1f2937;
  color: white;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  background-color: #292c32;
  border-radius: 50px;
  margin-bottom: 16px;
  width: 50%;
`;

const HeaderButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
  ${(props) =>
    props.active
      ? `
        background-color: #3B5432;
        color: white;
      `
      : `
        background-color: transparent;
        color: #9ca3af;
        &:hover {
          background-color: #3B5432;
          color: white;
        }
      `}
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  color: #9ca3af;
  font-size: 14px;
  margin-bottom: 16px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;
`;
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181b;
    border-radius: 15px;
    border: none;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
    @media (min-width: 1200px) {
      max-width: 75%;
      width: 800px;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
  background-color: #17181b;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
`;

const PlaceholderImage = styled.img`
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
`;

const PlaceholderText = styled.p`
  color: #9ca3af;
`;

// Main ModalBody content
const ModalContent = () => {
  const [activeTab, setActiveTab] = useState("Deposit");
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch API data based on the active tab and uid
  const fetchData = async (tab) => {
    const uid = getUID; // Get UID from your function
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://metananobackend.metablocktechnologies.org/api/${tab.toLowerCase()}New`,
        {
          params: { uid }, // Pass the UID as a query parameter
        }
      );
      console.log("API Response:", response.data);
      setTransactionData(response.data);
    } catch (err) {
      console.error("API call failed", err);
      setError("Failed to fetch data.");
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options); // Format based on locale
  };
  // Fetch data whenever activeTab changes
  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  return (
    <Container1>
      {/* Header Buttons */}
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <HeaderButtonGroup>
          <HeaderButton
            active={activeTab === "Deposit"}
            onClick={() => setActiveTab("Deposit")}
          >
            Deposit
          </HeaderButton>
          <HeaderButton
            active={activeTab === "Withdraw"}
            onClick={() => setActiveTab("Withdraw")}
          >
            Withdraw
          </HeaderButton>
        </HeaderButtonGroup>
      </div>

      {/* Content */}
      {loading ? (
        <EmptyState>
          <PlaceholderText>Loading data...</PlaceholderText>
        </EmptyState>
      ) : error ? (
        <EmptyState>
          <PlaceholderText>{error}</PlaceholderText>
        </EmptyState>
      ) : transactionData.length === 0 ? (
        <EmptyState>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              width: "100%",
            }}
          >
            <img
              src="/assets/images/emptyPanda.webp"
              style={{ height: "400px", width: "200px", objectFit: "contain" }}
            />
            <PlaceholderText>No transactions available.</PlaceholderText>
          </div>
        </EmptyState>
      ) : (
        <>
          <div style={{ height: "300px" }}>
            {/* Table Header */}
            <TableHeader style={{ color: "#9ca3af" }}>
              <div>Date</div>
              <div>Amount</div>
              <div>Status</div>
              <div>
                {activeTab === "Deposit" ? "Transaction" : "Address"}
              </div>{" "}
              {/* For deposit it's orderid, for withdraw it's wallet */}
            </TableHeader>

            {/* Table Rows */}
            {transactionData.map((transaction, index) => (
              <TableRow style={{ color: "#9ca3af" }} key={index}>
                <div>
                  {activeTab === "Deposit"
                    ? formatDateTime(transaction.created_at)
                    : formatDateTime(transaction.date)}
                </div>{" "}
                {/* Time */}
                <div>{transaction.amount}</div> {/* Amount */}
                <div>{transaction.status}</div> {/* State */}
                <div>
                  {activeTab === "Deposit"
                    ? transaction.orderid
                    : transaction.wallet}
                </div>{" "}
                {/* Transaction */}
              </TableRow>
            ))}
          </div>
        </>
      )}
    </Container1>
  );
};

class Credit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showManage: false,
      isTransferIn: true,
      token: storage.getKey("token"),
      coin: storage.getKey("coin") ? storage.getKey("coin") : "INR",
      activeClientCoins: storage.getKey("active_coins")
        ? storage.getKey("active_coins")
        : null,
      selectedCoin: storage.getKey("coin"),
      credits: {},
      coins: [],
      uid: getUID,
      manage_coins: [],
      different: [],
      credit: false,
      inr: storage.getKey("inr") === "true" ? true : false,
      searchTerm: "",
      showWalletModal: false,
      showCurrencyModal: false,
      activeSection: "deposit",
      activeTab: "deposit", // Default to Deposit
      selectedCoinSwapSend: "INR",
      creditSWapSend: 0,
      isModalOpenSwapSend: false,
      selectedCoinSwapApprox: "INR",
      creditSWapApprox: 0,
      isModalOpenSwapApprox: false,
      selectedCoinTransferIn: "INR",
      creditTransferIn: 0,
      isModalOpen: false,
      isModalOpenTransferIn: false,
      selectedCoinTransferOut: "INR",
      creditTransferOut: 0,
      isModalOpenTransferOut: false,
      amountTransferOut: "",
      amountTransferIn: "",
      vaultAmount: null,
      errorSend: "",
      openTransacationModal: false,
    };
    this.selectCoin = this.selectCoin.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.sections = [
      { id: "deposit", label: "Deposit", icon: Wallet },
      { id: "withdraw", label: "Withdraw", icon: WalletCards },
    ];
  }

  handleVualtInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  // Toggle Modal Function
  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen, // Toggle modal open/close
    }));
  };
  toggleModalTransaction = () => {
    this.setState((prevState) => ({
      openTransacationModal: !prevState.openTransacationModal, // Toggle modal open/close
    }));
  };

  // Open Modal with active section (Deposit/Withdraw)
  openModal = (tab) => {
    this.setState({
      isModalOpen: true,
      activeTab: tab, // Set active tab when modal opens
    });
  };

  handleTransfer = async () => {
    const {
      isTransferIn,
      selectedCoinTransferIn,
      selectedCoinTransferOut,
      amountTransferIn,
      amountTransferOut,
      uid,
    } = this.state;
    const coin = isTransferIn
      ? selectedCoinTransferIn
      : selectedCoinTransferOut;
    const amount = isTransferIn
      ? Number(amountTransferIn)
      : Number(amountTransferOut);
    const endpoint = isTransferIn ? "transfer-in" : "transfer-out";

    if (
      amount <= 0 ||
      (isTransferIn && this.state.creditTransferIn < amount) ||
      (!isTransferIn && this.state.creditTransferOut < amount)
    ) {
      this.setState({ errorSend: "Please enter a valid amount" });
      return;
    }

    try {
      const response = await axios.post(
        `https://metananobackend.metablocktechnologies.org/${endpoint}`,
        {
          uid,
          coin,
          amount,
        }
      );

      console.log(
        `Transferred ${isTransferIn ? "to" : "from"} Vault Pro:`,
        response
      );
      sendNotfication(response?.data.message, "success", "bottom-left");
      // this.handleSuccessfulTransfer(response.data);
    } catch (error) {
      console.error("Transfer failed:", error);
      // this.handleTransferError(error);
    }
  };

  handleCoinChange = (coin) => {
    this.setState({ selectedCoinSwapSend: coin });
  };

  handleCreditChange = (newCredit) => {
    this.setState({ creditSWapSend: newCredit });
  };

  openModal = () => {
    this.setState({ isModalOpenSwapSend: true });
  };

  closeModal = () => {
    this.setState({ isModalOpenSwapSend: false });
  };

  handleCoinChangeApprox = (coin) => {
    this.setState({ selectedCoinSwapApprox: coin });
  };

  handleCreditChangeApprox = (newCredit) => {
    this.setState({ creditSWapApprox: newCredit });
  };

  openModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: true });
  };

  closeModalApprox = () => {
    this.setState({ isModalOpenSwapApprox: false });
  };

  handleCoinTranserferIn = (coin) => {
    this.setState({ selectedCoinTransferIn: coin });
  };

  handleCreditTransferIn = (newCredit) => {
    this.setState({ creditTransferIn: newCredit });
  };

  openModalTransferIn = () => {
    this.setState({ isModalOpenTransferIn: true });
  };

  closeModalTransferIn = () => {
    this.setState({ isModalOpenTransferIn: false });
  };

  handleCoinTranserferOut = async (coin) => {
    this.setState({ selectedCoinTransferOut: coin });
    try {
      const response = await axios.post(
        "https://metananobackend.metablocktechnologies.org/vault-data",
        {
          uid: this.state.uid,
          coin: this.state.selectedCoinTransferOut,
        }
      );

      console.log("vaulelee", response);
      this.setState({ vaultAmount: response.data.vaultBalance });
    } catch (error) {
      console.error("Error fetching vault amount:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  handleCreditTransferOut = (newCredit) => {
    this.setState({ creditTransferOut: newCredit });
  };

  openModalTransferOut = () => {
    this.setState({ isModalOpenTransferOut: true });
  };

  closeModalTransferOut = () => {
    this.setState({ isModalOpenTransferOut: false });
  };

  setActiveSection = (sectionId) => {
    this.setState({ activeSection: sectionId });
  };

  removeSocketListeners = () => {
    this.socket.off(C.CREDIT, this.handleCredit);
    this.socket.off(C.UPDATE_CREDIT, this.updateCredit);
  };

  componentWillUnmount() {
    this.removeSocketListeners();
  }

  componentDidMount() {
    //Get Redux Coin
    this.props.gameCoin();

    Event.on("other_coins", () => {
      this.setState({ showManage: true });
    });

    console.log("uid is", this.state.uid);

    if (this.state.token !== null)
      socket.emit(
        C.CREDIT,
        encode({ token: this.state.token, coin: this.state.coin })
      );

    socket.on(C.CREDIT, (data) => this.getCredit(decode(data)));
    socket.on(C.UPDATE_CREDIT, (data) => this.updateCredit(decode(data)));

    if (this.state.activeClientCoins === null) {
      var arr = [];
      for (var i = 1; i < 8; i++) {
        arr.push(i);
      }
      storage.setKey("active_coins", arr);
    }

    this.setUpManageCoins();

    if (this.state.showManage) {
      wait(1000).then(() => {
        const ps = new PerfectScrollbar(".ccoins", {
          wheelSpeed: 1,
          suppressScrollX: true,
          wheelPropagation: true,
          minScrollbarLength: 2,
        });
        ps.update();
      });
    }

    window.addEventListener("wheel", this.handleScroll, { passive: false });
    window.addEventListener("mousedown", this.handleClickOutside);
  }

  //  const sessionCookie = Cookies.get("session");

  toggleWalletModal = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      showWalletModal: !prevState.showWalletModal,
      show: false,
      showWallet: false,
    }));
  };
  toggleCurrencytModal = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      // showWalletModal:false,
      showCurrencyModal: !prevState.showCurrencyModal,
      show: false,
      showWallet: false,
    }));
  };

  handleScroll(e) {
    function sicimsc(e) {
      e = e || window.event;
      if (e.preventDefault) e.preventDefault();
      e.returnValue = false;
    }
    let creditBody = document.querySelector(".prevscrol");
    if (creditBody) {
      creditBody.scrollTop -= e.wheelDeltaY;
      sicimsc(e);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ show: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll, { passive: false });
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  activeCoinChange = (id, active) => {
    if (id === 1) return;
    const activeClientCoins = storage.getKey("active_coins");
    let update;

    if (!active) {
      update = activeClientCoins + "," + id;
    } else {
      update = __.replace(activeClientCoins, "," + id, "");
    }

    update = __.replace(update, null, "");

    const compareNumbers = (a, b) => a - b;

    let arr = [];

    const regex = /(\d+)/gi;
    let m;

    while ((m = regex.exec(update)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      m.forEach((match, groupIndex) => {
        match = parseFloat(match);
        arr.push(match);
      });
    }

    arr.sort(compareNumbers);
    var c = __.xor(arr);

    storage.setKey("active_coins", c);

    this.setUpManageCoins();
  };

  setUpManageCoins = () => {
    const { t } = this.props;
    let self = this;

    this.setState({ manage_coins: [] });

    const activeCoins = storage.getKey("active_coins");
    let ac = __.split(activeCoins, ",");

    coins.forEach((item, key) => {
      //Default
      if (activeCoins === null) {
        let add = (
          <Dropdown.Item key={key} className={"num-style"}>
            <div
              style={{ color: "white" }}
              className="float-left text-white ncpt"
            >
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-12"}
              />
              {item.preffix}
            </div>
            <div className="float-right">
              <div className="custom-control custom-switch switch-success">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"switch_" + key}
                  checked={true}
                />
                <label
                  onClick={(e) =>
                    self.activeCoinChange(parseFloat(item.id), true)
                  }
                  className={"custom-control-label"}
                  htmlFor={"switch_" + key}
                >
                  {item.name}
                </label>
              </div>
            </div>
          </Dropdown.Item>
        );
        return self.setState((state) => ({
          manage_coins: [add, ...state.manage_coins],
        }));
      }

      let active = false;

      let check = ac.some(
        (element) => parseFloat(element) === parseFloat(item.id)
      );

      if (check) {
        active = true;
      }

      var disabled = false;

      if (item.preffix === "BTC") disabled = true;

      let add = (
        <Dropdown.Item
          style={{ color: "white" }}
          key={key}
          className={"num-style"}
        >
          <div
            style={{ color: "white" }}
            className="float-left text-white ncpt"
          >
            <img
              src={"/assets/images/" + item.image}
              alt="coin"
              className={"mini-coin-12"}
            />
            {item.preffix}
          </div>
          <div className="float-right">
            <div className="custom-control custom-switch switch-success">
              <input
                type="checkbox"
                className="custom-control-input"
                id={"switch_" + key}
                checked={active}
                disabled={disabled}
              />
              <label
                onClick={(e) =>
                  self.activeCoinChange(parseFloat(item.id), active)
                }
                className="custom-control-label"
                htmlFor={"switch_" + key}
              >
                {item.name}
              </label>
            </div>
          </div>
        </Dropdown.Item>
      );
      self.setState((state) => ({
        manage_coins: [add, ...state.manage_coins],
      }));
    });
  };

  updateAllCoins(newCoin = null, newVal = null) {
    const activeClientCoins = storage.getKey("active_coins");
    this.setState({ coins: [] });

    let ac = null,
      style,
      value,
      coin;

    if (activeClientCoins !== null) {
      ac = activeClientCoins.split(",");

      __.reverse(ac).forEach((num, key) => {
        var item = __.find(coins, { id: parseFloat(num) });
        if (__.isUndefined(item)) return;
        style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";

        if (newVal !== null) value = newVal;
        else
          value = forceSatoshiFormat(
            this.state.credits[__.lowerCase(item.preffix)]
          );

        if (__.lowerCase(item.preffix) === newCoin) {
          this.state.credits[newCoin] = value;
        }

        if (newCoin !== null) {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix, newCoin, newVal)}
              key={key}
              className={"num-style " + style}
            >
              <div style={{ color: "white" }} className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              {__.lowerCase(item.preffix) === newCoin ? (
                <div style={{ color: "white" }} className="float-right">
                  {value}
                </div>
              ) : (
                <div style={{ color: "white" }} className="float-right">
                  {forceSatoshiFormat(
                    this.state.credits[__.lowerCase(item.preffix)]
                  )}
                </div>
              )}
            </Dropdown.Item>
          );
        } else {
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={"num-style " + style}
            >
              <div style={{ color: "white" }} className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div style={{ color: "white" }} className="float-right">
                {value}
              </div>
            </Dropdown.Item>
          );
        }

        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    } else {
      coins.forEach((item, key) => {
        if (ac !== null) {
          let check = ac.some(
            (element) => parseFloat(element) === parseFloat(item.id)
          );

          if (check) {
            style =
              __.lowerCase(item.preffix) ===
              __.lowerCase(this.state.selectedCoin)
                ? "actc"
                : "";
            value = forceSatoshiFormat(
              this.state.credits[__.lowerCase(item.preffix)]
            );
            coin = (
              <Dropdown.Item
                onClick={(e) => this.selectCoin(item.preffix)}
                key={key}
                className={"num-style " + style}
              >
                <div style={{ color: "white" }} className="float-left">
                  <img
                    src={"/assets/images/" + item.image}
                    alt="coin"
                    className={"mini-coin-7"}
                  />
                  {item.preffix}
                </div>
                <div style={{ color: "white" }} className="float-right">
                  {value}
                </div>
              </Dropdown.Item>
            );
          }
        } else {
          style =
            __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
              ? "actc"
              : "";
          value = forceSatoshiFormat(
            this.state.credits[__.lowerCase(item.preffix)]
          );
          coin = (
            <Dropdown.Item
              onClick={(e) => this.selectCoin(item.preffix)}
              key={key}
              className={"num-style " + style}
            >
              <div style={{ color: "white" }} className="float-left">
                <img
                  src={"/assets/images/" + item.image}
                  alt="coin"
                  className={"mini-coin-7"}
                />
                {item.preffix}
              </div>
              <div style={{ color: "white" }} className="float-right">
                {value}
              </div>
            </Dropdown.Item>
          );
        }
        this.setState((state) => ({ coins: [coin, ...state.coins] }));
      });
    }
  }

  openWalletModal = (e) => {
    e.stopPropagation();
    this.setState({ showWalletModal: true, show: false });
  };

  closeWalletModal = () => {
    this.setState({ showWalletModal: false });
  };
  getCredit(data) {
    let { credit } = data;

    this.setState({ credits: credit });

    let currentCoin = __.lowerCase("inr");
    let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
    this.setState({ credit: currentCredit });
    storage.setKey("credit", currentCredit);

    //Set for Redux
    this.props.setCredit(currentCredit);
    this.updateAllCoins();
  }

  updateCredit(data) {
    let credit = data.value;
    let coin = data.coin;

    let different;
    let arr;

    if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
      different = __.toNumber(credit) - __.toNumber(this.state.credit);
      arr = {
        amount: different,
        color: "text-success",
      };
    } else {
      different = __.toNumber(this.state.credit) - __.toNumber(credit);
      arr = {
        amount: different,
        color: "text-danger",
      };
    }

    let check = forceSatoshiFormat(different);

    if (check.toString() !== "0.00000000") {
      this.setState((state) => ({ different: [arr, ...state.different] }));
    }

    var realCredit = credit
    credit = forceSatoshiFormat(credit);

    this.setState({ credit: credit });
    storage.setKey("credit", credit);

    this.updateParentCoin(__.lowerCase(coin), credit);
    this.updateAllCoins(__.lowerCase(coin), realCredit);

    //Set for Redux
    this.props.setCredit(credit);
  }

  updateParentCoin(coin, amount) {
    this.setState({ coins: [] });

    coins.forEach((item, key) => {
      if (__.lowerCase(item.preffix) === coin) {
        const style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(__.upperCase(coin))}
            key={key}
            className={"num-style " + style}
          >
            <div style={{ color: "white" }} className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div style={{ color: "white" }} className="float-right">
              {forceSatoshiFormat(amount)}
            </div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      } else {
        const style =
          __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin)
            ? "actc"
            : "";
        let value = forceSatoshiFormat(
          this.state.credits[__.lowerCase(item.preffix)]
        );
        let update = (
          <Dropdown.Item
            onClick={(e) => this.selectCoin(item.preffix)}
            key={key}
            className={"num-style " + style}
          >
            <div style={{ color: "white" }} className="float-left">
              <img
                src={"/assets/images/" + item.image}
                alt="coin"
                className={"mini-coin-7"}
              />
              {item.preffix}
            </div>
            <div style={{ color: "white" }} className="float-right">
              {value}
            </div>
          </Dropdown.Item>
        );
        this.setState((state) => ({ coins: [update, ...state.coins] }));
      }
    });
  }

  selectCoin(name, newCoin, newVal) {
    //Fix For Double selection
    if (storage.getKey("coin") === name) return;
    storage.setKey("coin", name);

    let credit = this.state.credits[__.lowerCase(name)];
    this.setState({
      coin: name,
      show: false,
      credit: credit,
      selectedCoin: name,
      showCurrencyModal: false,
    });

    //Set Coin For Redux
    this.props.setCoin(name);
    this.props.setCredit(credit);

    wait(200).then(() => {
      this.updateAllCoins(newCoin, newVal);
      Event.emit("coin_changed", name);
    });
  }

  addDiff(data, i) {
    let id = "id_" + Math.floor(Math.random() * 1000 + 1);

    wait(2000).then(() => {
      try {
        document.getElementById(id).classList.remove("frd");
        document.getElementById(id).classList.add("fadeOutDown");
      } catch (e) {}
      this.state.different.splice(i, 1);
    });

    return (
      <li
        key={i}
        id={id}
        className={"list-inline w-100 text-right animated frd " + data.color}
      >
        {" "}
        {data.color === "text-danger" ? "-" : "+"}
        {forceSatoshiFormat(data.amount, this.state.coin)}
      </li>
    );
  }

  closeManage = () => {
    this.setState({ showManage: false });
    wait(200).then(() => {
      this.updateAllCoins();
    });
  };

  manageCoins = () => {
    this.setState({ showManage: true });
  };

  open = (e) => {
    this.setState({ show: !this.state.show });
  };

  usdformat = () => {
    this.setState({ inr: !this.state.inr });
    wait(1000).then(() => {
      storage.setKey("inr", this.state.inr);
    });
  };
  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  filteredCoins = () => {
    const { searchTerm, coins } = this.state;
    return coins.filter((coin) => {
      const coinText = coin.props.children
        .map((child) => {
          if (typeof child === "string") {
            return child.toLowerCase();
          }
          return "";
        })
        .join(" ");
      return coinText.includes(searchTerm.toLowerCase());
    });
  };

  filteredCoinsCurrency = () => {
    const { searchTerm, coins } = this.state;
    return coins.filter((coin) => {
      const coinText = coin.props.children
        .map((child) => {
          if (typeof child === "string") {
            return child.toLowerCase();
          }
          return "";
        })
        .join(" ");
      return coinText.includes(searchTerm.toLowerCase());
    });
  };

  openCoinDropdown = (e) => {
    e.stopPropagation();
    this.setState({ show: !this.state.show, showWallet: false });
  };

  openWalletDropdown = (e) => {
    e.stopPropagation();
    this.setState({ showWallet: !this.state.showWallet, show: false });
  };
  toggleTransfer = async () => {
    const newIsTransferIn = !this.state.isTransferIn;
    this.setState({ isTransferIn: newIsTransferIn });

    try {
      const response = await axios.post(
        "https://metananobackend.metablocktechnologies.org/vault-data",
        {
          uid: this.state.uid,
          coin: this.state.selectedCoinTransferOut,
        }
      );

      console.log("vaulelee", response);
      this.setState({ vaultAmount: response.data.vaultBalance });
    } catch (error) {
      console.error("Error fetching vault amount:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  render() {
    let {
      credit,
      different,
      coin,
      coins,
      show,
      showWallet,
      searchTerm,
      isModalOpen,
    } = this.state;
    const { selectedCoinSwapSend, creditSWapSend } = this.state;
    credit = forceSatoshiFormat(credit, coin, this.state.inr);
   
    const { isTransferIn } = this.state;
    let diff = different.map((data, i) => this.addDiff(data, i));

    return (
      <>
        <li style={{ position: "relative" }}>
          <div className="crypto-balance" ref={this.wrapperRef}>
            <ul id="cup2" className={"mt-2 mr-1"}>
              {diff}
            </ul>
            <div className="btc-balance">
              <div className={"clist mt-0 text-right float-right cpt"}>
                <Container>
                  <CubBalance onClick={this.openCoinDropdown}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        padding: "8px"
                      }}
                    >
                      <img
                        src={"/assets/images/INR.png"}
                        className={"mini-coin-9 mr-1"}
                        alt=""
                      />
                      <span className="text-white d-block font-14">
                        {Number(credit).toFixed(2)}
                      </span>
                    </div>
                  </CubBalance>
                  <WalletButton>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        fontFamily: "Arial, sans-serif !important",
                      }}
                    >
                      <Wallet
                        size={14}
                        className="mr-1 text-white group-hover:text-[#646ECD]"
                      />
                      <Link
                        to={"/payment"}
                        style={{
                          fontFamily: "Arial, sans-serif !important",
                          fontSize: "12px",
                        }}
                      >
                        Wallet
                      </Link>
                    </div>
                  </WalletButton>
                </Container>
              </div>
            </div>
          </div>
        </li>
        {/* Modal */}
      </>
    );
  }
}

Credit.propTypes = {
  setCoin: PropTypes.func.isRequired,
  coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(
  Credit
);
