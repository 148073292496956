import React, { Component } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import storage from "../../../../Storage";
import { sendNotfication, Event, isMobile } from "../../../../Helper";
import Deposit from "./Deposit";
import Withdrawl from "./Withdrawl";
import Swap from "./Swap";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      wallet: null,
      headerText: "",
      padding: "",
      paddingHeader: "",
    };
    this.handleWallet = this.handleWallet.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showArchive = this.showArchive.bind(this);
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ padding: "p-1", paddingHeader: "px-2" });
    }

    Event.on("deposit_archive_back", () => {
      this.setState({ headerText: "Deposit History" });
    });

    Event.on("withdraw_archive_back", () => {
      this.setState({ headerText: "Withdraw History" });
    });
  }

  handleWallet(wallet) {
    let text = wallet === "deposit" ? "Deposit History" : "Withdraw History";
    this.setState({ show: true, wallet: wallet, headerText: text });
  }

  handleClose() {
    this.setState({ show: false });
  }

  showArchive() {
    this.setState({ headerText: "Back" });
    if (this.state.wallet === "deposit") Event.emit("deposit_archive");
    else if (this.state.wallet === "withdraw") Event.emit("withdraw_archive");
  }


  render() {
    return (
      <>
        <button
          onClick={(e) => this.handleWallet("deposit")}
          className={"dropdown-item mb-2"}
        >
          <i className="mdi mdi-briefcase-outline text-muted mr-2 text-drop" />
          Deposit
        </button>
        <button
          onClick={(e) => this.handleWallet("withdraw")}
          className={"dropdown-item"}
        >
          <i className="dripicons-wallet text-muted mr-2 text-drop" />
          Withdraw
        </button>
        <button
          onClick={(e) => this.handleWallet("swap")}
          className={"dropdown-item"}
        >
          <i className="dripicons-swap text-muted mr-2 text-drop" />
          Swap
        </button>
        <Modal
          size={"lg"}
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          aria-labelledby="wallet-md-modal"
        >
          <Modal.Header className={this.state.paddingHeader} style={{marginTop:40}} >
            {this.state.wallet != "swap" && (
              <><button
                onClick={this.showArchive}
                className={"btn bg-cs2 btn-xs text-white"}
              >
                {" "}
                {this.state.headerText}
              </button>
              
              </>
            )}
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"mdi mdi-close"} />
            </button>
          </Modal.Header>

          <Modal.Body className={this.state.padding + " wallet-modal"}>
            {this.state.wallet === "deposit" && <Deposit />}
            {this.state.wallet === "withdraw" && <Withdrawl />}
            {this.state.wallet === "swap" && <Swap />}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Main;
