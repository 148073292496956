import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChartArea, MessageCircle, MessageSquare, Search } from 'lucide-react';


import User from './../User';
import Credit from './../Credit';
import Logo from './../Logo';

import { Menu, X } from 'lucide-react';
import { addNewStyle, CRISTP_ID, wait } from '../../../../Helper';
import Payment_wallet from '../Payment_wallet';

const ToggleButton = styled.button`

  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
`;


const HeaderContainer = styled.header`
  background-color: #23262B;
  height: 80px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1B1E22;
  border-radius: 30px;
  padding: 12px 16px;
  width: 100%;
  max-width: 600px;
  transition: all 0.2s ease-in-out;
  border: ${props => props.isFocused ? '2px solid #4caf50' : '2px solid transparent'};
  margin-right:10px;
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #a0a0a0;
  }
`;

const LogoContainer = styled.div`
  margin-right: 10px;
`;

const FullLogo = styled.img`
  height: auto;
  width: 150px;
`;

const ShortLogo = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
`;
const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 300px;

  @media (max-width: 1200px) {
    padding-right: 150px;
  }

  @media (max-width: 992px) {
    padding-right: 100px;
  }

  @media (max-width: 768px) {
    padding-right: 50px;
  }

  @media (max-width: 576px) {
    padding-right: 20px;
  }
`;


const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width:100%;
`;

const StyledImg = styled.img`
  height: 20px;
  width: 20px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg) brightness(94%) contrast(87%);
`;
const StyledImg2 = styled.img`
  height: 25px;
  width: 23px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg) brightness(94%) contrast(87%);
`;


const Header = ({ isCollapsedMobile, setIsCollapsedMobile }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showFullLogo, setShowFullLogo] = useState(window.innerWidth > 768);

  const [loaded, setLoaded] = useState(false);

  const support = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = CRISTP_ID;

    if (!loaded) {
      console.log("support");
      setLoaded(true);
      const script = document.createElement('script');
      script.src = "https://client.crisp.chat/l.js";
      script.id = "chtt";
      script.async = true;
      document.head.appendChild(script);
    }

    addNewStyle("#crisp-chatbox {display:block !important;}");
    addNewStyle(
      ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
    );

    wait(2000).then(() => {
      const close = document.querySelector(".cc-wdhl");
      if (close === null) return;
      close.addEventListener("click", function (event) {
        addNewStyle("#crisp-chatbox {display:none !important;}");
        document.getElementById("chtt").src = "";
      });
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setShowFullLogo(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <HeaderContainer>
      <FlexContainer>

        <LogoContainer>
          <Link to="/">
            {showFullLogo ? (
              <FullLogo src="/assets/images/ekjeet_logo.png" alt="Logo" />
            ) : (
              <ShortLogo>
              <span style={{ color: '#00aeef' }}>EK</span>
              <span style={{ color: '#ffffff' }}>JEET</span>
        </ShortLogo>
            )}
          </Link>
        </LogoContainer>

        {
          !showFullLogo &&(
            <ToggleButton onClick={() => setIsCollapsedMobile(!isCollapsedMobile)}>
            {isCollapsedMobile ? (
              <X size={24} style={{ position: 'absolute', left: '191px' }} />
            ) : (
              <Menu size={24} />
            )}
          </ToggleButton>
          )
        }
       
      </FlexContainer>


      
        {
          showFullLogo  && (
            <SearchBarContainer isFocused={isFocused}>
              <Search size={18} color="#a0a0a0" />
              <SearchInput
                placeholder="Game name | Provider | Category Tag"
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </SearchBarContainer>
          )
        }

        <RightWrapper>
          {/* <Credit /> */}
          <Payment_wallet/>
          <ButtonGroup>
            <User />
          </ButtonGroup>
          {/* <MessageSquare size={20}/ */}
          {/* <StyledImg src='/assets/images/nanoicons/notification.png' />
          <StyledImg2 style={{cursor:'pointer'}} onClick={support} src='/assets/images/nanoicons/nanochat.png' /> */}
          <Link to={"/chat"}>
          <StyledImg2 style={{cursor:'pointer'}}  src='/assets/images/nanoicons/nanochat.png' />
          </Link>
     
        </RightWrapper>

    </HeaderContainer>
  );
};

export default Header;