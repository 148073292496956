import MyEmitter from "events";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {encode, decode, forceSatoshiFormat} from "../../../Helper";
import C from "../../../Constant";

/**
 * Plinko Engine
 */
function Engine(){

    var self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'plinko';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Game Status
     * 1 type: started
     */
    self.gameStatus = null;

    /**
     * Intialize Auto Mode
     */
    self.init = false;

    /**
     * Current User Coin
     */
    self.coin = null;

    /**
     * Round amount
     */
    self.amount = 50.00;

    /**
     * Bonus
     */
    self.bonus = null;

    /**
     * Win or Lost
     */
    self.win = null;

    /**
     * Busted Game Hash
     */
    self.hash = null;

    /**
     * Bet Number
     */
    self.betNumber = null;

    /**
     * Increase Amount on Win
     */
    self.onWin = 0;

    /**
     * Increase Amount on Lost
     */
    self.onLost = 0;

    /**
     * Played Game Rounds
     */
    self.played = 0;

    /**
     * Player is the Win or Lost
     */
    self.isWin = false;

    /**
     * Stop Playing if Won Amount
     */
    self.stopOnWin = 0;

    /**
     * Round Profit
     */
    self.roundProfit = 0;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_PLINKO, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_PLINKO, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_PLINKO, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_PLINKO, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_PLINKO);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_PLINKO, encode({
        command: 'play',
        auto: false,
        coin: self.coin,
        amount: self.amount
    }));
};

/**
 * End Playing Game
 */
Engine.prototype.busted = function () {
    let self = this;

    if(!self.init)
        return;

    self.ws.emit(C.PLAY_PLINKO, encode({
        command: 'busted',
        bonus: self.bonus,
        hash: self.hash
    }));
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_PLINKO, encode({
        command: 'play',
        auto: true,
        coin: self.coin,
        amount: self.amount
    }));
};

/** Start Game Engine **/
export default Engine
