import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import RangeCredit from "../../Components/Game/Addons";
import { gameCoin } from "../../../actions/gameCoin";
import { setRouletteNumber } from "../../../actions/gameRoulette";
import { setWallet } from "../../../actions/gameWallet";
import storage from "../../../Storage";
import C from "../../../Constant";
import { Event, __, isValidNumber, wait, forceSatoshiFormat, sendNotfication, hasClass } from "../../../Helper";

class Bet extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            bet: 'manual',
            started: false,
            token: storage.getKey('token') !== null ? storage.getKey('token') : false,
            amount: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : 50.00),
            inputDisabled: false,
            buttonText: 'Spin',
            betNumber: 10,
            firstBetNumber: 10,
            onWin: 0,
            onLost: 0,
            stopOnWin: 0,
            total_bet: forceSatoshiFormat(storage.getKey('lam') ? storage.getKey('lam') : 50.00),
            chips: [1, 2, 3, 4, 5, 6, 7]
        };
        this.handleBet = this.handleBet.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        engine.init = false;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;
        this.props.gameCoin();
        engine.on(C.ERROR_ROULETTE, (data) => this.error(data));
        engine.on('busted_roulette', () => this.busted());
        engine.on('roulette_add_amount', (data) => this.addAmount(data));
        engine.on('roulette_reset_amount', () => this.resetAmount());
        this.props.setRouletteNumber(this.state.amount);
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        if (target.name === 'amount' || target.name === 'stopOnWin' || target.name === 'payout') {
            this.setState({amount:value})
            if (!isValidNumber(value)) return;
        }
        if (target.name === 'amount') {
            storage.setKey('lam', value);
        }
        this.setState({ [target.name]: value });
        this.props.setRouletteNumber(this.state.amount);
    }

    busted() {
        this.setState({ inputDisabled: false, total_bet: this.state.amount });
    }

    addAmount(value) {
        let add = parseFloat(this.state.total_bet) + parseFloat(value)
        this.setState({ total_bet: forceSatoshiFormat(add) });
    }

    resetAmount() {
        this.setState({ total_bet: forceSatoshiFormat(this.state.amount) });
    }

    placeBet() {
        if (this._isMounted) {
            let { token, engine } = this.state;

            wait(100)
                .then(() => {
                    engine.init = true;
                    engine.token = token;
                    engine.coin = this.props.coin;
                    engine.start();
                })
        }
    }

    error(data) {
        if (this._isMounted) {
            sendNotfication(data.message, 'info', 'top-center');

            Event.emit('reset_table')

            if (data.code === 'credit')
                this.props.setWallet(true, data.uid);

            this.setState({ inputDisabled: false });
        }
    }

    handleBet(e) {
        e.preventDefault();
        let { token, amount, bet, started } = this.state;

        // Check User
        if (!token) {
            return Event.emit('showAuthModal', true);
        }

        if (parseFloat(amount) <= 0) {
            this.setState({ amount: forceSatoshiFormat(50.00) });
        }
        else {
            amount = Math.min(parseFloat(storage.getKey('credit')), parseFloat(amount));
            this.setState({ amount: forceSatoshiFormat(amount) });
        }

        this.setState({ inputDisabled: true });
        this.placeBet();
    }
    
    setAmount = (val) => {
        var am = forceSatoshiFormat(val);
        this.setState({ amount: am });
        storage.setKey('lam', am );
    }

    handleDivideBy2 = () => {
        this.setState(prevState => ({
            amount: prevState.amount / 2
        }));
    }

    handleMultiplyBy2 = () => {
        this.setState(prevState => ({
            amount: prevState.amount * 2
        }));
    }

    render() {
        let { inputDisabled } = this.state;

        return (
            <>
                <form className="w-100 mt-1" onSubmit={(e) => { this.handleBet(e) }}>
                    <Row>
                        <Col sm={12} className={'d-md-none d-sm-block'}>
                            <Button variant={'btn btn-xs btn-block btn-bet py-2 mb-2 '}
                                disabled={inputDisabled}
                                type="submit">
                                {this.state.buttonText}
                            </Button>
                        </Col>
                        <Col xl={6} md={7}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            BET
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        disabled={inputDisabled}
                                        className="form-control"
                                        id="amount" name="amount"
                                        value={this.state.amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange} />
                                    <div className="input-group-append">
                                        <RangeCredit set={this.setAmount} />
                                    </div>
                                    <div className="input-group-append">
                            <button type="button" className="input-group-text"  onClick={this.handleDivideBy2}>
                                /2
                            </button>
                            <button type="button" className="input-group-text" onClick={this.handleMultiplyBy2}>
                                x2
                            </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} md={5}>
                            <div className={"form-group mb-1 bet-input"}>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <img src={'/assets/images/' + this.props.coin + '.png'} className={'mini-coin-7'} alt="" />
                                            TOTAL BET
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        value={this.state.total_bet}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} sm={12} className="m-auto hidden-sm">
                            <div className={"form-group rev mb-1"}>
                                <Button variant={'btn btn-md btn-block mt-2 btn-bet'}
                                    disabled={inputDisabled}
                                    type="submit">
                                    {this.state.buttonText}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </>
        );
    }
}

Bet.propTypes = {
    coin: PropTypes.string.isRequired,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    credit: state.items.credit
});

export default connect(mapStateToProps, { gameCoin, setRouletteNumber, setWallet })(Bet);
