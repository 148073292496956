import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Engine from '../../Games/Crash/Engine';

const Container = styled.div`
  background-color: #1E2024;
  color: white;
  padding: 20px;
  border-radius: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #8a8d92;
  font-weight: normal;
  text-transform: uppercase !important;
`;

const TableCell = styled.td`
  padding: 10px;
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const moveUpAnimation = keyframes`
  0% { transform: translateY(10px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const AnimatedTableRow = styled.tr`
  background-color: transparent;
  transition: background-color 0.3s ease;
  ${props => props.animate && css`
    animation: ${moveUpAnimation} 0.5s ease-out;
  `}
`;

const FadeInOut = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const AnimatedContainer = styled(Container)`
  animation: ${props => props.show ? css`${FadeInOut} 0.5s ease-in` : css`${FadeInOut} 0.5s ease-out`};
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
`;

export default function TableBotHashDice() {
  const [tableData, setTableData] = useState([]);
  const [animateRows, setAnimateRows] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const engine = React.useRef(new Engine()).current;

  useEffect(() => {
    engine.on('table_updated', (newData) => {
      // Sort the data by profit in descending order
      const sortedData = newData.sort((a, b) => parseFloat(b.profit) - parseFloat(a.profit));
      setTableData(sortedData);
    });

    engine.on('started_crash', () => {
      setAnimateRows(true);
      setShowTable(true);
      engine.startAutoUpdateTable();
    });

    engine.on('busted_crash', () => {
      setAnimateRows(false);
      
      setTimeout(() => {
        setShowTable(false);
      }, 500);

      setTimeout(() => {
        setTableData([]);
      }, 1000);

      engine.stopAutoUpdateTable();
    });

    return () => {
      engine.off();
    };
  }, []);

  return (
    <div style={{marginTop:'12px'}}>
      <AnimatedContainer show={showTable}>
        <Table>
          <thead>
            <tr>
              <TableHeader style={{color:'#99A4B0', fontWeight:'bold'}}>Player</TableHeader>
              <TableHeader style={{color:'#99A4B0', fontWeight:'bold'}}>Bet</TableHeader>
              <TableHeader style={{color:'#99A4B0', fontWeight:'bold'}}>Payout</TableHeader>
              <TableHeader style={{color:'#99A4B0', fontWeight:'bold'}}>Profit</TableHeader>
            </tr>
          </thead>
          <tbody>
            {tableData.slice(0, 10).map((row, index) => (
              <AnimatedTableRow key={index} animate={animateRows}>
                <TableCell style={{ fontWeight:'bold' }}>{row.user}</TableCell>
                <TableCell style={{color:'#99A4B0', fontWeight:'bold'}}>
                  <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                    <img src='/assets/images/USDT.png' style={{width:'12px', height:'12px', objectFit:'contain'}} alt="USDT"/>
                    {row.betAmount}
                  </div>
                </TableCell>
                <TableCell style={{color:'#99A4B0', fontWeight:'bold'}}>{row.multiplier}</TableCell>
                <TableCell>
                  <GreenText>
                    <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                      <img src='/assets/images/USDT.png' style={{width:'12px', height:'12px', objectFit:'contain'}} alt="USDT"/>
                      {row.profit}
                    </div>
                  </GreenText>
                </TableCell>
              </AnimatedTableRow>
            ))}
          </tbody>
        </Table>
      </AnimatedContainer>
    </div>
  );
}