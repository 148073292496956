import React, { Component } from "react";

import { BRAND, isMobile } from "../../Helper";
import { withCookies } from "react-cookie";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? "none" : "block")};
`;
const ResponsiveImage = styled.img`
  object-fit: cover;
  width: 140px;
  height: 140px;
  border-radius: 20px;

  @media (min-width: 467px) {
    width: 188px;
    height: 188px;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

const GamesTopWrapper = styled.div`
  background-color: #1c1e22;
  padding: 2px;
  border-radius: 0 20px 20px 20px; /* top-left | top-right | bottom-right | bottom-left */
  position: relative;
  margin-top: 40px !important;
  margin-top: 100px !important;
  @media (max-width: 768px) {
    margin-top: 100px !important;
  }
`;
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin: 12px 12px;
  width: 100%;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr);
  // Tablet view (3 columns)
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // Desktop view (4 columns)
  @media (min-width: 1068px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;
const GridItem = styled.div`
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const GridContainerNew = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin: 12px 12px;
  width: 100%;
  padding-right: 17px;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr) !important;
  // Tablet view (3 columns)
  @media (min-width: 870px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  // Desktop view (4 columns)
  @media (min-width: 1068px) {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  @media (min-width: 1270px) {
    grid-template-columns: repeat(5, 1fr) !important;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Title = styled.h3`
  padding: 0.5rem;
  margin: 0;
  font-size: 10px;
  text-align: left;
  color: rgb(152, 167, 181);
`;
const TopTitleContainer = styled.h3`
  background-color: #1c1e22;
  border-radius: 15px;
  position: absolute;
  top: -71px;
  left: 0;
  z-index: 1;
  padding: 10px;
  margin: 0;
`;
const AllButtonRigthTitleContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  align-items: center;
  top: -57px;
  right: 0;
  color: #7f898c;
  font-weight: bold;
  z-index: 1;
  margin: 0;
`;

const AllButtonTopTitleContainer = styled.div`
  background-color: #1c1e22;
  border-radius: 15px;
  display: flex;
  justify-caontent: center;
  align-items: center;
  color: #7f898c;
  font-weight: bold;
  z-index: 1;
  padding: 5px 23px;
  margin: 0;
  cursor: pointer;
`;
const PaginationButton = styled.div`
  background-color: #1c1e22;
  border-radius: 50%;
  display: flex;
  justify-caontent: center;
  align-items: center;
  color: #7f898c;
  font-weight: bold;
  z-index: 1;
  padding: 5px;
`;

const NavItem = styled.button`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.active ? "#23262B" : "transparent")};
  color: ${(props) => (props.active ? "#ffffff" : "#888888")};
  border: none;
  padding: 12px 16px;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    background-color: #2a2a2a;
  }
`;

const NavBar = styled.nav`
  display: inline-flex; // Changed to inline-flex
  justify-content: flex-end;
  background-color: #191a1e;
  padding: 4px;
  border-radius: 26px;
  overflow: hidden; // Changed from overflow-x
`;

const NavBarEnd = styled.div`
  display: flex;
  width: 100%;
`;

const TabSwitcher = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.button`
  background-color: ${(props) => (props.active ? "#2c3137" : "transparent")};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 26px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#2c3137" : "#1c1e22")};
  }
`;

const FlexArrange = styled.div`
  display: flex;
  align-items: center;
`;

const TabImage = styled.img`
  height: 28px;
  width: 28px;
  object-fit: contain;
  margin-right: 8px;
  filter: ${(props) => (props.active ? "none" : "grayscale(100%)")};
  transition: filter 0.3s ease;
`;

const TabText = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

class ApiSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      padding: "py-2",
      selectedSection: "home",
      sectionIndex: 0,
      slideSelected: null,
      gamesEvolution: [],
      currentPageJillie: 0, // Track current page
      itemsPerPageJillie: 10, // Number of items per page
      gamesJillie: [],
      gamesJdb: [],
      gamesPragmatic: [],
      gamesPragmaticlive: [],
      gamesEvoplay: [],
      gamesGenisis: [],
      gamesPgsoft: [],
      gamesLiveCasinoProvider: [],
      gamesSpribe: [],
      gamesSports: [],
      gamesMostExpensive: [],
      error: null,
      loading: false,
      message: null,
      showMore: false,
      showMoreP: false,
      showMoreO: false,
      showMoreLCP: false,
      showMoreSP: false,
      showMoreSB: false,
      isMobileView: window.innerWidth <= 768,
      searchQuery: "",
      showIframe: false,
      iframeUrl: "",
      iframeLoading: true,
      showAll: false,
      showAllData: false,

      activeItem: "Lobby",
    };
    this.sections = [
      "/assets/images/evolutionicon.png",
      "/assets/images/ezubi.png",
      "/assets/images/pragmatic.png",
      "/assets/images/netent.png",
      "/assets/images/novomatic.png",
    ];
    this.livecasino = [
      "/assets/images/live1.png",
      "/assets/images/live2.png",
      "/assets/images/live3.jpg",
      "/assets/images/live4.jpg",
      "/assets/images/live5.jpg",
      "/assets/images/live6.png",
    ];
    this.itemsToShow = 5;
    this.containerPlayRef = React.createRef();
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  handleShowAllData = () => {
    this.setState((prevState) => ({ showAllData: !prevState.showAllData }));
  };

  handleMouseDown(e) {
    this.isDown = true;
    this.startX = e.pageX - this.containerPlayRef.current.offsetLeft;
    this.scrollLeft = this.containerPlayRef.current.scrollLeft;
  }

  handleShowAllClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    this.setState({ showAll: !this.state.showAll });
  };

  handleMouseLeave() {
    this.isDown = false;
  }

  handleMouseUp() {
    this.isDown = false;
  }

  handleMouseMove(e) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.containerPlayRef.current.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.containerPlayRef.current.scrollLeft = this.scrollLeft - walk;
  }
  handleItemClick = (item) => {
    this.setState({ activeItem: item });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeItem !== this.state.activeItem) {
      console.log(`Active item changed to: ${this.state.activeItem}`);
      // Add any additional side effects or logic you want to run on activeItem change
    }
  }

  componentDidMount() {
    const containerPlay = this.containerPlayRef.current;
    containerPlay.addEventListener("mousedown", this.handleMouseDown);
    containerPlay.addEventListener("mouseleave", this.handleMouseLeave);
    containerPlay.addEventListener("mouseup", this.handleMouseUp);
    containerPlay.addEventListener("mousemove", this.handleMouseMove);
  }

  componentWillUnmount() {
    const containerPlay = this.containerPlayRef.current;
    containerPlay.removeEventListener("mousedown", this.handleMouseDown);
    containerPlay.removeEventListener("mouseleave", this.handleMouseLeave);
    containerPlay.removeEventListener("mouseup", this.handleMouseUp);
    containerPlay.removeEventListener("mousemove", this.handleMouseMove);
  }

  handleGameLaunch = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get("uid");

      if (!uid) {
        throw new Error("Please login to play live game");
      }

      const response = await fetch("https://api.ekjeet.com/game_launch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_code: String(uid),
          provider_code: provider,
          game_code: gameCode,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === 1) {
        this.setState({
          iframeUrl: result.launch_url,
          showIframe: true,
          iframeLoading: true,
        });
      } else if (
        result.status === 0 &&
        result.detail === "user_code is not allowed to be empty"
      ) {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.msg);
      }
    } catch (error) {
      this.setState({ message: { type: "error", text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleGameLaunchJillie = async (gameId) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get("uid");

      // Check if user is logged in
      if (!uid) {
        throw new Error("Please login to play live game");
      }

      // Call the API to play the Jillie game
      const response = await fetch("https://api.ekjeet.com/playJillieGame", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_code: String(uid),
          gameId: gameId,
        }),
      });

      // If the response is not OK, throw an error
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Parse the JSON response
      const result = await response.json();
      console.log(result, "result");
      console.log(result.Data, "resultData");
      // Check the status and handle accordingly
      if (result.Data) {
        // If the game URL is returned, show the iframe
        this.setState({
          iframeUrl: result.Data,
          showIframe: true,
          iframeLoading: true,
        });
      } else {
        // If the response structure is unexpected, handle the error
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      // Handle any errors during the API call
      this.setState({ message: { type: "error", text: error.message } });
    } finally {
      // Reset loading state and clear the message after 5 seconds
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleGameLaunchJdb = async ({ gType, mType }) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get("uid");

      // Check if user is logged in
      if (!uid) {
        throw new Error("Please login to play live game");
      }

      // Call the API to play the Jillie game
      const response = await fetch("https://api.ekjeet.com/playJdbGame", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_code: String(uid),
          gType: gType,
          mType: mType,
        }),
      });

      // If the response is not OK, throw an error
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Parse the JSON response
      const result = await response.json();
      console.log(result, "result");
      console.log(result.Data, "resultData");
      // Check the status and handle accordingly
      if (result.Data) {
        // If the game URL is returned, show the iframe
        this.setState({
          iframeUrl: result.Data,
          showIframe: true,
          iframeLoading: true,
        });
      } else {
        // If the response structure is unexpected, handle the error
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      // Handle any errors during the API call
      this.setState({ message: { type: "error", text: error.message } });
    } finally {
      // Reset loading state and clear the message after 5 seconds
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleBackButton = () => {
    this.setState({ showIframe: false, iframeUrl: "", iframeLoading: true });
  };

  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };

  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (isMobile()) {
      this.setState({ padding: "p-1" });
    }

    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    this.getAllJillieGames().then((games) => {
      this.setState({ gamesJillie: games });
    });
    this.getAllJdbGames().then((games) => {
      this.setState({ gamesJdb: games });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this._isMounted = false;
  }

  getAllJillieGames = async () => {
    console.log("first");
    try {
      const response = await fetch(
        `https://api.ekjeet.com/getAllJillieGames`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result.Data; // Access the 'Data' field instead of 'games'
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return []; // Returning empty array in case of error
    }
  };

  getAllJdbGames = async () => {
    console.log("first");
    try {
      const response = await fetch(`https://api.ekjeet.com/getAllJdbGames`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result, "allgamesListdata");
      return result.Data; // Access the 'Data' field instead of 'games'
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return []; // Returning empty array in case of error
    }
  };

  handleResize = () => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.setState({ isMobileView: width <= 768 });
  };

  handleClick = (e) => {
    this.setState({ selectedSection: e });
  };

  handleArrowClick = (direction) => {
    const { sectionIndex } = this.state;
    const { sections, itemsToShow } = this;
    const totalSections = sections.length;

    let newIndex;

    if (direction === "left") {
      newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
    } else {
      newIndex = (sectionIndex + itemsToShow) % totalSections;
    }

    this.setState({
      selectedSection: sections[newIndex],
      sectionIndex: newIndex,
    });
  };

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleShowMoreP = () => {
    this.setState({ showMoreP: !this.state.showMoreP });
  };

  handleShowMoreO = () => {
    this.setState({ showMoreO: !this.state.showMoreO });
  };

  handleShowMoreLCP = () => {
    this.setState({ showMoreLCP: !this.state.showMoreLCP });
  };

  handleShowMoreSP = () => {
    this.setState({ showMoreSP: !this.state.showMoreSP });
  };

  handleShowMoreSB = () => {
    this.setState({ showMoreSB: !this.state.showMoreSB });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  getFilteredGames = (games) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return games;
    return games.filter(
      (game) =>
        game.game_name &&
        game.game_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  getFilteredGames2 = (games) => {
    const { searchQuery } = this.state;
    if (!searchQuery) return games;
    return games.filter(
      (game) =>
        game.name.en - US &&
        game.name.en - US.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  handlePreviousPage = () => {
    this.setState((prevState) => ({
      currentPageJillie: Math.max(prevState.currentPageJillie - 1, 0), // Prevent going below page 0
    }));
  };

  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPageJillie: Math.min(
        prevState.currentPageJillie + 1,
        Math.ceil(prevState.gamesJillie.length / prevState.itemsPerPageJillie) -
          1 // Correctly access gamesJillie from state
      ),
    }));
  };
  render() {
    const { currentPageJillie, itemsPerPageJillie, gamesJillie } = this.state;
    let { content, t } = this.props;
    const { activeItem } = this.state;
    const { showAll } = this.state;
    const {
      selectedSection,
      sectionIndex,
      slideSelected,
      gamesEvolution,
      gamesLiveCasinoProvider,
      gamesSpribe,
      gamesPragmatic,
      gamesPragmaticlive,
      gamesJdb,
      gamesSports,
      gamesEvoplay,
      gamesGenisis,
      gamesPgsoft,
      gamesMostExpensive,
      loading,
      message,
      searchQuery,
      showMore,
      showMoreP,
      showMoreO,
      showMoreLCP,
      showMoreSP,
      showMoreSB,
      isMobileView,
      padding,
      showIframe,
      iframeUrl,
      iframeLoading,
    } = this.state;
    const { sections, itemsToShow, livecasino } = this;
    const tabs = [
      {
        name: "Lobby",
        image: "/assets/images/metablocktechnologies/lobby.webp",
      },
      {
        name: "Original",
        image: "/assets/images/metablocktechnologies/originalcasino.webp",
      },
      {
        name: "Slots",
        image: "/assets/images/metablocktechnologies/slots.webp",
      },
      {
        name: "Live Casino",
        image: "/assets/images/metablocktechnologies/livecasino.webp",
      },
    ];
    const companies = [
      "/assets/images/Providers/evolutiongaming.webp",
      "/assets/images/Providers/netent.webp",
      "/assets/images/Providers/bet.webp",
      "/assets/images/Providers/quickspin.webp",
      "/assets/images/Providers/gameart.webp",
      "/assets/images/Providers/playstone.webp",
      "/assets/images/Providers/bgaming.webp",
      "/assets/images/Providers/ka.webp",
      "/assets/images/Providers/wazdan.webp",
      "/assets/images/Providers/spinn.webp",
      "/assets/images/Providers/evoplay.webp",
      "/assets/images/Providers/thunder.webp",
      "/assets/images/Providers/endrophina.webp",
      "/assets/images/Providers/bgaming.webp",
      "/assets/images/Providers/habanero.webp",
      "/assets/images/Providers/becom.webp",
      "/assets/images/Providers/platipus.webp",
      "/assets/images/Providers/tomhron.webp",
    ];

    const visibleSections = sections.slice(
      sectionIndex,
      sectionIndex + itemsToShow
    );

    const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
    const filteredGamesJillie = this.getFilteredGames2(gamesJillie);
    const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
    const { showAllData } = this.state;

    const filteredGamesEvoplay = this.getFilteredGames(gamesEvoplay);
    const filteredGamesGenisis = this.getFilteredGames(gamesGenisis);
    const filteredGamesPgsoft = this.getFilteredGames(gamesPgsoft);

    const displayGamesEvoplay = showAllData
      ? filteredGamesEvoplay
      : filteredGamesEvoplay.slice(0, 6);
    const displayGamesGenisis = showAllData
      ? filteredGamesGenisis
      : filteredGamesGenisis.slice(0, 6);
    const displayGamesPgsoft = showAllData
      ? filteredGamesPgsoft
      : filteredGamesPgsoft.slice(0, 6);

    const filteredSpribe = this.getFilteredGames(gamesSpribe);
    const filteredSports = this.getFilteredGames(gamesSports);
    const filtergamespragamaticlive = this.getFilteredGames(gamesPragmaticlive);
    const filtergamesLiveCasinoProvider = this.getFilteredGames(
      gamesLiveCasinoProvider
    );

    const displayedGames = isMobileView
      ? gamesEvolution
      : showMore
      ? gamesEvolution
      : gamesEvolution.slice(0, 5);
    // const displayedGamesJillie = isMobileView ? gamesJillie : (showMore ? gamesJillie : gamesJillie.slice(0, 10));

    // Calculate the current slice
    const displayedGamesJillie =
      gamesJillie && gamesJillie.length > 0
        ? gamesJillie.slice(
            currentPageJillie * itemsPerPageJillie,
            (currentPageJillie + 1) * itemsPerPageJillie
          )
        : [];

    const displayedGamesSP = isMobileView
      ? gamesSpribe
      : showMoreSP
      ? gamesSpribe
      : gamesSpribe.slice(0, 5);
    const displayedGamesSB = isMobileView
      ? gamesSports
      : showMoreSB
      ? gamesSports
      : gamesSports.slice(0, 5);
    const displayedGamesLCP = isMobileView
      ? gamesLiveCasinoProvider
      : showMoreLCP
      ? gamesLiveCasinoProvider
      : gamesLiveCasinoProvider.slice(0, 5);
    const displayGamesP = isMobileView
      ? gamesPragmatic
      : showMoreP
      ? gamesPragmatic
      : gamesPragmatic.slice(0, 5);
    const displayGamesPL = isMobileView
      ? gamesPragmaticlive
      : showAll
      ? gamesPragmaticlive
      : gamesPragmaticlive.slice(0, 12);

    console.log("display", displayGamesPL);

    console.log("image url", displayGamesPL);

    const imageStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "250px",
      animation: "fadeinout 2s infinite",
    };
    const animationStyle = `
      @keyframes fadeinout {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
      }
    `;
    if (showIframe) {
      return (
        <FullScreenContainer>
          <style>{animationStyle}</style>
          {this.state.iframeLoading && (
            <img src="/assets/images/ekjeet_logo.png" alt="Loading" style={imageStyle} />
          )}
          <FullScreenIframe
            src={iframeUrl}
            title="Game Iframe"
            onLoad={this.handleIframeLoad}
            loading={this.state.iframeLoading}
          />
          <BackButton onClick={this.handleBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackButton>
        </FullScreenContainer>
      );
    }

    const getTitle = (gType) => {
      switch (gType) {
        case 0:
          return { first: "JDB", second: "Slot", third: "Games" };
        case 7:
          return { first: "JDB", second: "Fish", third: "Games" };
        case 9:
          return { first: "JDB", second: "Arcade", third: "Games" };
        case 12:
          return { first: "JDB", second: "Lottery", third: "Games" };
        case 18:
          return { first: "JDB", second: "Card", third: "Games" };
        case 22:
          return { first: "Spribe", second: "ARCADE", third: "Games" };
        case 66:
          return { first: "GTF", second: "Slot", third: "Games" };
        case 67:
          return { first: "GTF", second: "Fish", third: "Games" };
        case 30:
          return { first: "FC", second: "Arcade", third: "Games" };
        case 31:
          return { first: "GTF", second: "Fish", third: "Games" };
        case 32:
          return { first: "GTF", second: "Slot", third: "Games" };
        case 41:
          return { first: "HRG", second: "Live", third: "Games" };
        case 58:
          return { first: "YB", second: "Slot", third: "Games" };
        case 59:
          return { first: "YB", second: "Fish", third: "Games" };
        case 60:
          return { first: "YB", second: "Lottery", third: "Games" };
        case 57:
          return { first: "MANCALA", second: "Slot", third: "Games" };
        case 75:
          return { first: "MANCALA", second: "Arcade", third: "Games" };
        case 80:
          return { first: "ONLYPLAY", second: "Slot", third: "Games" };
        case 81:
          return { first: "ONLYPLAY", second: "Arcade", third: "Games" };
        case 90:
          return { first: "INJOY", second: "SLOT", third: "Games" };
        case 91:
          return { first: "INJOY", second: "FISH", third: "Games" };
        case 92:
          return { first: "INJOY", second: "ARCADE", third: "Games" };
        case 93:
          return { first: "INJOY", second: "CARD", third: "Games" };
        case 101:
          return { first: "CREEDROOMZ", second: "Live", third: "Games" };
        case 50:
          return { first: "AMB", second: "Slot", third: "Games" };
        case 120:
          return { first: "ZESTPLAY", second: "ARCADE", third: "Games" };
        default:
          return { first: "JDB", second: "Other", third: "Games" };
      }
    };

    const getTitleJillie = (category) => {
      switch (category) {
        case 1:
          return { first: "JILLIE", second: "Slot", third: "Games" };
        case 2:
          return { first: "JILLIE", second: "Poker", third: "Games" };
        case 3:
          return { first: "JILLIE", second: "Lobby", third: "Games" };
        case 5:
          return { first: "JILLIE", second: "Fishing", third: "Games" };
        case 8:
          return { first: "JILLIE", second: "Casino", third: "Games" };
        default:
          return { first: "JILLIE", second: "Other", third: "Games" };
      }
    };

    const groupGamesByCategory = (games) => {
      return games.reduce((groups, game) => {
        const category = game.GameCategoryId;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(game);
        return groups;
      }, {});
    };

    const groupedGames = groupGamesByCategory(gamesJillie);
    return (
      <>
        {this._isMounted && (
          <>
            <div>
                {gamesJdb.map((gameTypeData) => {
                  const { gType, list } = gameTypeData;
                  const { first, second, third } = getTitle(gType); // Get title parts

                  const categoryNumber = Number(gType);

                  if (![22].includes(categoryNumber)) {
                    return null; // Skip rendering this category
                  }

                  const totalGames = list.length;

                  return (
                    <GamesTopWrapper key={gType}>
                      <TopTitleContainer>
                        <div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            {first}
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              fontStyle: "italic",
                            }}
                          >
                            {second}
                            <span style={{ color: "#E70A6C" }}>{third}</span>
                          </div>
                        </div>
                      </TopTitleContainer>

                      <AllButtonRigthTitleContainer>
                        <div
                          style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ fontSize: "15px" }}>
                            Games: {totalGames}
                          </p>
                        </div>
                      </AllButtonRigthTitleContainer>

                      <GridContainerNew>
                        {list.map((game) => (
                          <div key={game.mType}>
                            <GridItem>
                              <ResponsiveImage
                                onClick={() =>
                                  this.handleGameLaunchJdb({
                                    gType,
                                    mType: game.mType,
                                  })
                                }
                                src={game.image}
                                alt={game.name}
                              />
                            </GridItem>
                          </div>
                        ))}
                      </GridContainerNew>
                    </GamesTopWrapper>
                  );
                })}
              </div>
          </>
        )}
      </>
    );
  }
}

export default withCookies(ApiSlider);
