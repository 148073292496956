/* eslint-disable */
import React from 'react';
import { __ } from "../../../Helper";
import C from "../../../Constant";
import Blackjack from "./components/Blackjack";

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      game: null,
      engine: this.props.engine
    };
  }

  componentDidMount() {
    let { engine } = this.state;
    const blackjack = new Blackjack(engine);
    this.setState({ game: blackjack });
    engine.on(C.PLAY_BLACKJACK, () => this.play());
  }

  play = () => {
    this.state.game.play();
  }

  render() {
    return (
      <div className="blackjack animated fadeIn">
        <div id="alert" className="alert" style={{ display: 'none' }}><span>Welcome</span></div>
        <div id="wrapper">
          <div id="game">
            <div id="dealer">
              <div id="dhand"></div>
            </div>
            <div id="player">
              <div id="phand"></div>
            </div>
          </div>
        </div>
        <div className="bji">
          <div id="a"></div>
          <div id="b"></div>
        </div>
      </div>
    );
  }
}

export default Game;
