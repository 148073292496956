import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Bet from "./Bet";
import Engine from "./Engine";
import { isMobile, wait, Event } from "../../../Helper";
import { Helmet } from "react-helmet";
import Loading from "../Loading";
import SingleKenoBetStatsPopup from './Graph';

class Main extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            padding: 'p-0',
            showPopup: false
        };
        this.handleShowPopUp = this.handleShowPopUp.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }
    componentDidMount() {
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;
        this.state.canvas = null;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }
        if (isMobile()) {
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh } = this.state;

        const help = <><p className={'font-light text-white font-15'}>
            Single Keno is a gambling game played with cards bearing numbers in squares, from 1 to 40. You will choose as many numbers as you wish and then submit your bet. Random number will be drawn, and prizes are paid out by the casino according to how many numbers you selected were chosen.
            <br />
            <b>Keno Game Tips & Tricks</b><br />

            The more number combination chosen, the higher probability of a win or higher payout.
            The maximum possible selection and maximum possible successful5 hits is set to 10.
            Betting on both even and odd numbers betters your chances of winning.
        </p>
            <h4 className="text-white text-yellow">
                How to Play Game?
            </h4>
            <p className="text-white font-15">
                1. More the number combinations selected, higher the potential payout.
                <br />
                2.The more selections hit successfully, the higher will be the payout multiplier.
                <br />
                3.Maximum possible selection and maximum possible successful hits is 10.
            </p></>

        return (
            <>
                <Helmet>
                    <title>Single Keno - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Single Keno Game, Online Single Keno Game, Bitcoin Single Keno Game, Blockchain Single Keno Game, Best Crypto Single Keno Game, PlayCrash.com Single Keno" />
                    <meta name="description" content="Crypto Single Keno is a gambling game played with cards bearing numbers in squares, from 1 to 40. You will choose as many numbers as you wish and then submit your bet. Random number will be drawn" />
                    <meta name="og:title" content="Play Online Single Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Single Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/singlekeno" />
                </Helmet>
                <div>
                    {this._Mounted ?

                        <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {this.state.showPopup && (
                                <SingleKenoBetStatsPopup
                                    onClose={this.handleShowPopUp}
                                    engine={this.state.engine}
                                />
                            )}
                            <Row className={"animated fadeIn " + ovh}>
                                <div className="d-none">{help}</div>
                                <Col sm={12} className={'m-auto ' + col + ' ' + padding}>
                                    {/* BANKROLL */}
                                    <Card className="mb-0">
                                        <Card.Body className="px-2 py-3">
                                            <Row>
                                                <Col md={6} className={'col-6 text-left'}>
                                                    <BankRoll game={'singlekeno'} />
                                                </Col>
                                                <Col md={6} className={'col-6 text-right'}>
                                                    <div style={{ display: 'flex' }}>
                                                        <TopBarGames image={"/assets/images/two.dice.png"} help={help} />
                                                        <div onClick={this.handleShowPopUp} style={{ marginLeft: '3px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                                                            <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {/* BET */}
                                    <Card className="mb-0">
                                        <Card.Body className={'py-0 ' + padding}>
                                            <Row>
                                                <Col sm={12} md={12} className={'m-auto'}>
                                                    <Bet engine={this.state.engine} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={12} className={'mt-0 mx-auto p-0 ' + col}>
                                    {/* QUEUE */}
                                    <Card className="mb-5">
                                        <Card.Body className="p-1">
                                            <Row>
                                                <Col sm={12} md={12} className={'m-auto px-1'}>
                                                    <Queue t={this.props.t} game={'singlekeno'} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Main;
