import * as PIXI from "pixi.js";
import * as particles from '@pixi/particle-emitter'

/* global PIXI */
/* eslint-disable newline-after-var,prefer-template */
/**
*  Basic example setup
*  @class ParticleExample
*  @constructor
*  @param {String[]} imagePaths The local path to the image source
*  @param {Object} config The emitter configuration
*  @param {boolean} [testContainers=false] If changing containers should be enabled.
*  @param {boolean} [stepColors=false] If the color settings should be manually stepped.
*/
class ParticleExample {
        constructor(obj, imagePaths, config, stepColors) {
                this.stage = obj
                this.emitter = null;
                this.bg = null;
                this.updateHook = null;
                this.containerHook = null;

                // Calculate the current time
                let elapsed = Date.now();
                let updateId;

                // Update function every frame
                const update = () => {
                        // Update the next frame
                        updateId = requestAnimationFrame(update);

                        const now = Date.now();
                        if (this.emitter) {
                                // update emitter (convert to seconds)
                                this.emitter.update((now - elapsed) * 0.001);
                        }

                        // call update hook for specialist examples
                        if (this.updateHook) {
                                this.updateHook(now - elapsed);
                        }

                        elapsed = now;
                };

                // Preload the particle images and create PIXI textures from it
                let urls;
                if (imagePaths.spritesheet) {
                        urls = [imagePaths.spritesheet];
                }
                else if (imagePaths.textures) {
                        urls = imagePaths.textures.slice();
                }
                else {
                        urls = imagePaths.slice();
                }
                
                // const loader = PIXI.Loader.shared;
                // for (let i = 0; i < urls.length; ++i) {
                //         loader.add('img' + i, urls[i]);
                // }

                // Create the new emitter and attach it to the stage
                let parentType = 0;
                function getContainer() {
                        return [new PIXI.Container(), 'PIXI.Container'];
                }
                let [emitterContainer, containerName] = getContainer();
                this.stage.addChild(emitterContainer);

                window.emitter = this.emitter = new particles.Emitter(
                        emitterContainer,
                        config,
                );
                if (stepColors) {
                        // override the initialized list with our auto-stepped one
                        this.emitter.getBehavior('color').list.reset(
                                particles.ParticleUtils.createSteppedGradient(
                                        config.behaviors.find((b) => b.type === 'color').config.color.list,
                                        stepColors,
                                ),
                        );
                }

                // Center on the stage
                this.emitter.updateOwnerPos(obj.width / 2, obj.height / 2);

                // Start the update
                update();
        }
}

export default ParticleExample;