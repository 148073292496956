import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode, forceSatoshiFormat } from "../../../Helper";

/**
 * Game Engine
 */
function Engine() {

    var self = this;

    /**
     * Game Name
     */
    self.game = 'tower';

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Itialize Event
     */
    self.trigger = new MyEmitter();

    /**
     * Itialize Game
     */
    self.init = false;

    /**
     * Game Status
     */
    self.gameStatus = null;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Round Profit
     */
    self.profit = null;

    /**
     * Number of Fields
     */
    self.fields = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Selected Land
     */
    self.land = null;

    /*
     * Start Engine
    */
    self.started = true;

    if (self.started) {
        self.ws.on(C.PLAY_TOWER, (data) => {
            data = decode(data);
            switch (data.command) {
                case 'play':
                    return self.emit(C.PLAY_TOWER, data);
                case 'busted':
                    return self.emit(C.BUSTED_TOWER, data);
                case 'clicked':
                    return self.emit(C.CLICKED_TOWER, data);
                case 'cashout':
                    return self.emit(C.CASHOUT_TOWER, data);
                case 'error':
                    return self.emit(C.ERORR_TOWER, data);
            }
        });
    }
}

Engine.prototype.off = function () {
    let self = this;
    self.ws.off(C.PLAY_TOWER);
}

Engine.prototype.on = function (name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
}

/**
 * Add Player for playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if (!self.init)
        return;

    if (self.amount < 50.00) {
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_TOWER, encode({
        command: 'play',
        amount: self.amount,
        coin: self.coin
    }));
};

/**
 * Clicked On the Land
 */
Engine.prototype.clicked = function () {
    let self = this;
    self.ws.emit(C.PLAY_TOWER, encode({
        command: 'clicked',
        land: parseInt(self.land)
    }));
};

/**
 * CashOut
 */
Engine.prototype.cashout = function () {
    let self = this;
    self.ws.emit(C.PLAY_TOWER, encode({
        command: 'cashout',
        profit: self.profit
    }));
};

/** Start Game Engine **/
export default Engine
