import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import { encode, decode } from "../../../Helper";

/**
 * Game Engine
 */
function Engine() {

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = null;

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Intialize Starting Game
     */
    self.init = false;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /*
     * Bonus Result
    */
    self.bonus = null;

    /*
     * Start Engine
    */
    self.started = true;

    if (self.started) {
        self.ws.on(C.PLAY_MAGIC_WHEEL, (data) => {
            data = decode(data);

            switch (data.command) {
                case 'play':
                    return self.trigger.emit(C.PLAY_MAGIC_WHEEL, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_MAGIC_WHEEL, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_MAGIC_WHEEL, data);
            }
        });
    }
}

Engine.prototype.off = function () {
    let self = this;
    self.ws.off(C.PLAY_MAGIC_WHEEL);
}

Engine.prototype.on = function (name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
}

/**
 * Start playing game
 */
Engine.prototype.start = function () {
    let self = this;
    if (!self.init)
        return;

    self.ws.emit(C.PLAY_MAGIC_WHEEL, encode({
        amount: self.amount,
        coin: self.coin,
        command: 'play'
    }));
};

/**
 * Busted Game
 */
Engine.prototype.busted = function () {
    let self = this;
    self.ws.emit(C.PLAY_MAGIC_WHEEL, encode({
        bonus: self.bonus,
        command: 'busted'
    }));
}

/** Start Game Engine **/
export default Engine
