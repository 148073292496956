import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const GSContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 134px; // Exact height for two cards: (62px * 2) + 10px gap
  overflow: hidden;
`;

const ScrollWrapper = styled.div`
  transition: transform 0.5s ease;
  transform: translateY(${props => props.translateY}px);
`;

const GSCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: 200px;
  height: 62px; // Set exact height: 52px (icon) + 10px (padding)
  margin-bottom: 10px; // Add gap between cards
`;

const GSIconPlaceholder = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 10px;
`;

const GSTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GSProfitText = styled.span`
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const GSDescriptionText = styled.span`
  font-size: 14px;
  font-family: Arial, sans-serif;
  margin-top: -4px;
`;

const OptionalText = styled.span`
  color: #888;
  font-size: 12px;
  font-family: Arial, sans-serif;
`;

const cardData = [
  {
    iconSrc: '/assets/images/nano/gs1.webp',
    optionalText: 'Hidden',
    profitColor: '#5DDB1C',
    descriptionText: 'In Plinko',
  },
  {
    optionalText: 'Hidden',
    iconSrc: '/assets/images/nano/gs2.webp',
    descriptionText: 'In Mines',
  },
  {
    iconSrc: '/assets/images/nano/gs1.webp',
    optionalText: 'Hidden',
    profitColor: '#5DDB1C',
    descriptionText: 'In Plinko',
  },
  {
    optionalText: 'Hidden',
    iconSrc: '/assets/images/nano/gs2.webp',
    descriptionText: 'In Mines',
  },
  {
    iconSrc: '/assets/images/nano/gs1.webp',
    optionalText: 'Hidden',
    profitColor: '#5DDB1C',
    descriptionText: 'In Plinko',
  },
  {
    optionalText: 'Hidden',
    iconSrc: '/assets/images/nano/gs2.webp',
    descriptionText: 'In Mines',
  },
];

const getRandomProfitText = () => {
  const randomAmount = (Math.random() * 50000).toFixed(2); // Random amount between 0 and 50,000
  const profitType = Math.random() > 0.5 ? 'Won' : 'Profit'; // Randomly choose between 'Won' or 'Profit'
  return `${profitType} ₹${randomAmount}`;
};

const GsCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const translateY = -currentIndex * 72; // 62px card height + 10px margin

  return (
    <GSContainer>
      <ScrollWrapper translateY={translateY}>
        {[...cardData, ...cardData].map((card, index) => (
          <GSCard key={index}>
            <GSIconPlaceholder src={card.iconSrc} />
            <GSTextContainer>
              {card.optionalText && (
                <OptionalText>{card.optionalText}</OptionalText>
              )}
              <GSProfitText>
                {card.profitText ? (
                  // If the card has a predefined profitText, use it
                  card.profitText.includes('Won') ? (
                    <>
                      Won <span style={{ color: card.profitColor }}>{card.profitText.split(' ')[1]}</span>
                    </>
                  ) : (
                    card.profitText
                  )
                ) : (
                  // Otherwise, generate a random profitText
                  getRandomProfitText().includes('Won') ? (
                    <>
                      Won <span style={{ color: card.profitColor }}>{getRandomProfitText().split(' ')[1]}</span>
                    </>
                  ) : (
                    getRandomProfitText()
                  )
                )}
              </GSProfitText>
              <GSDescriptionText>{card.descriptionText}</GSDescriptionText>
            </GSTextContainer>
          </GSCard>
        ))}
      </ScrollWrapper>
    </GSContainer>
  );
};

export default GsCard;