// import React, { Component, createRef } from 'react';
// import { withRouter } from "react-router-dom";
// import {Modal, Row, Col} from "react-bootstrap";
// import  Cookies from "js-cookie";
// // import ReCAPTCHA from "react-google-recaptcha";
// import storage from "../../../Storage";
// import GoogleBtn from "./Google";
// import Forget from "./Forget";
// import socket from "../../../Socket";
// import {Event, wait, decode, encode, sendNotfication, RECAPTCHA} from "../../../Helper";
// import C from "../../../Constant";

// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLogged: storage.getKey('logged'),
//             show: this.props.show ? this.props.show : false,
//             forgetPage: false,
//             username: '',
//             password: '',
//             // recaptcha: false,
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn'
//         };
//         this.handleShow = this.handleShow.bind(this);
//         this.handleClose = this.handleClose.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);

//         // this.recaptchaRef = createRef();
//     }

//     componentDidMount() {
//         socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
//         Event.on('login_form', () => {
//             this.handleShow();
//         })
//     }

//     setLogin = (data) => {
//         if (data.status === true)
//         {
//             sendNotfication('Successfully Login, Please Wait...', 'success','bottom-left');
//             Cookies.set("session", data.token, {expires: 14});
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             })

//         } else {
//             this.setState({submitted: false, disabled: false});
//             sendNotfication(data.status, 'success','bottom-left');
//         }
//     };

//     handleShow(e){
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose(){
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: this.props.t('please_wait') });

//         const { username, password } = this.state;

//         if (!(username && password)){
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         //Execute Recaptcha Token
//         // const token = await this.recaptchaRef.current.executeAsync();

//         wait(200).then(() => {
//             socket.emit(C.LOGIN_USER, encode({
//                 username: username,
//                 password: password,
//                 recaptcha: 'google'
//             }));
//         })
//     }

//     forgetPass = (e) => {
//         this.setState({ forgetPage: !this.state.forgetPage })
//     };

//     // recaptchaChange = (value) => {
//     //     this.setState({ recaptcha: value });
//     // };

//     register = (value) => {
//         this.handleClose();
//         Event.emit('register_form');
//     };

//     back = (value) => {
//         this.setState({ forgetPage: false })
//     };

//     render() {
//         let { t } = this.props;
//         return (
//             <>
//                 <button className={'btn btn-transparent btn-round btn-sm no-shadow text-white mr-2 font-weight-bold'} onClick={e => this.handleShow(e)}>
//                     {t('login')}
//                 </button>
//                 <Modal
//                     size="md"
//                     centered={true}
//                     backdrop="static"
//                     show={this.state.show}
//                     onHide={this.handleClose}
//                     aria-labelledby="login-md-modal"
//                     className={'modalAuth animated ' + this.state.effect }
//                 >
//                     <Modal.Header>
//                          {this.state.forgetPage &&
//                             <button onClick={this.back} className="btn btn-transparent cpt hvw p-0">
//                                 <i className="fa fa-chevron-left fonts-18 aligdsn-top mr-3 mt-1" />
//                             </button>
//                          }
//                         <button type="button" className="close p-2" onClick={this.handleClose}>
//                             <i className={'mdi mdi-close'}/>
//                         </button>
//                     </Modal.Header>
//                     <Modal.Body className="auth-modal p-0">
//                         <div className="m-auto">
//                          {!this.state.forgetPage &&
//                             <div>
//                                 <div className="text-center">
//                                 {/*{this.state.recaptcha}*/}
//                                     <img src="/assets/images/ekjeet_logo.png" className={'img-fluid auth-logo'} alt="Logo"/>
//                                     <div className="text-center auth-logo-text">
//                                         <p className="mt-0 mb-3 mt-3 font-new text-white font-15">
//                                             {t('mega profit')}
//                                                 <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
//                                             {t('fair games')}
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className="px-4">
//                                     <form className="form-horizontal auth-form my-4" onSubmit={ (e) => { this.handleSubmit(e) }}>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('username')}</span>
//                                                 </div>
//                                                 <input type="text"
//                                                        className="form-control"
//                                                        value={this.state.username}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ username: e.target.value })}
//                                                        style={{ height: 40 }}

//                                                 />
//                                                 {this.state.submitted && !this.state.username &&
//                                                     <div className="help-block">{t('username_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('password')}</span>
//                                                 </div>
//                                                 <input type="password"
//                                                        className="form-control"
//                                                        value={this.state.password}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ password: e.target.value })}
//                                                        style={{ height: 40 }}
//                                                 />
//                                                 {this.state.submitted && !this.state.password &&
//                                                     <div className="help-block">{t('password_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="text-center">
//                                             <button className="btn btn-auth btn-block font-weight-bold no-shadow" disabled={this.state.disabled}>
//                                                 {
//                                                     this.state.disabled &&
//                                                     <span className="spinner-grow spinner-grow-sm mr-1" role="loading"></span>
//                                                 }
//                                                 <i className="mdi mdi-login mr-1 float-left font-18" /> {t('login to site')}
//                                             </button>
//                                         </div>
//                                         <div className="mt-3">
//                                             <a href="#" className="" onClick={this.register}>
//                                                 Or Register a new account
//                                             </a>
//                                         </div>
//                                         {/*<ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA} />*/}
//                                     </form>
//                                     <Row className="text-center mb-4">
//                                         {/* <Col md="6" className="my-1">
//                                             <GoogleBtn />
//                                         </Col> */}
//                                         <Col md="6" className="my-1">
//                                             <button onClick={this.forgetPass} className="btn btn-block btn-outline-dark shadow-none">
//                                                 <i className="mdi mdi-information mr-1" /> {t('rest password')}
//                                             </button>
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </div>
//                          }
//                         {this.state.forgetPage &&
//                              <Forget t={t} />
//                         }
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </>
//         );
//     }
// }

// export default withRouter(Login);

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Cookies from "js-cookie";
import storage from "../../../Storage";
import socket from "../../../Socket";
import { Event, wait, decode, encode, sendNotfication } from "../../../Helper";
import C from "../../../Constant";
import { GoogleLoginButton, handleGoogleLogin } from "./GoogleLoginButton";

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #43b30b;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2f7d08; // Added border
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #43b30b;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
`;

const StyledButton = styled.button`
  background-color: #8c52ff;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const SocialLoginButton = styled.button`
  background-color: #2b2f33;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledSignInButton = styled.div`
  font-size: 16px;
  color: #43b30b !important;
  background-color: transparent;
  border: none;
  border-radius: 28px;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
`;

const Labeltext = styled.div`
  color: #696f79;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  padding: 20px 30px;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SignInButton = styled(Button)`
  background-color: #8b5cf6;
  color: white;
  border: none;
  width: 100%;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-color: #7c3aed;
  }
`;

const SignUpButton = styled(Button)`
  background-color: #30353c;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120px;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-color: #374151;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: storage.getKey("logged"),
      show: this.props.show ? this.props.show : false,
      username: "",
      password: "",
      status: false,
      submitted: false,
      disabled: false,
      effect: "zoomIn",
    };
  }

  componentDidMount() {
    socket.on(C.LOGIN_USER, (data) => this.setLogin(decode(data)));
    Event.on("login_form", () => {
      this.handleShow();
    });
  }

  setLogin = (data) => {
    if (data.status === true) {
      sendNotfication(
        "Successfully Login, Please Wait...",
        "success",
        "bottom-left"
      );
      Cookies.set("session", data.token, { expires: 14 });
      storage.setKey("logged", true);
      storage.setKey("token", data.token);
      storage.setKey("name", data.name);
      storage.setKey("avatar", data.avatar);
      storage.setKey("email", data.email);
      storage.setKey("credit", data.credit);
      storage.setKey("room", data.room);
      storage.setKey("friends", data.friends);
      storage.setKey("coin", "INR");

      wait(500).then(() => {
        window.location.reload();
      });
    } else {
      this.setState({ submitted: false, disabled: false });
      sendNotfication(data.status, "success", "bottom-left");
    }
  };

  handleShow = () => {
    this.setState({ show: true, effect: "zoomIn" });
  };

  handleClose = () => {
    this.setState({
      show: false,
      effect: "zoomOut",
      disabled: false,
      status: false,
      submitted: false,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true, disabled: true, status: "Please wait" });

    const { username, password } = this.state;

    if (!(username && password)) {
      this.setState({ disabled: false, status: false });
      return;
    }

    wait(200).then(() => {
      socket.emit(
        C.LOGIN_USER,
        encode({
          username: username,
          password: password,
          recaptcha: "google",
        })
      );
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  signup = (e) => {
    e.preventDefault();
    this.handleClose();
    Event.emit("register_form");
  };

  render() {
    const { show, username, password, disabled } = this.state;

    return (
      <>
        <StyledSignInButton onClick={(e) => this.handleShow(e)}>
          Sign in
        </StyledSignInButton>
        <StyledModal
          size="md"
          centered
          show={show}
          onHide={this.handleClose}
          aria-labelledby="login-modal"
          className={`animated ${this.state.effect}`}
        >
          <ModalHeader>
            {/* <img src="/assets/images/nanogames-logo.png" alt="NanoGames" style={{height: '30px'}} /> */}
            <CloseButton onClick={this.handleClose}>×</CloseButton>
          </ModalHeader>
          <ModalBody>
            <div className="text-center mb-4">
              <h3>BUILD THE BEST CRYPTO CASINO TOGETHER</h3>
            </div>
            <StyledForm onSubmit={this.handleSubmit}>
              <Labeltext>Enter Username/Email </Labeltext>
              <StyledInput
                // type="email"
                name="username"
                value={username}
                onChange={this.handleInputChange}
                placeholder="Enter Username/Email"
                // required
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Labeltext> Login Password</Labeltext>
                <Labeltext> Forgot Password ?</Labeltext>
              </div>
              <StyledInput
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                placeholder="Login Password"
                // required
              />
              {/* <div className="text-right">
                                <a href="#" onClick={this.forgetPass}>Forgot password?</a>
                            </div> */}
              {/* <StyledButton type="submit" disabled={disabled}>
                                {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
                                Sign In
                            </StyledButton> */}
              <ButtonContainer>
                <SignInButton type="submit">Sign In</SignInButton>
                <SignUpButton onClick={(e) => this.signup(e)}>
                  Sign up
                </SignUpButton>
              </ButtonContainer>
            </StyledForm>
            {/* <div className="text-center mt-3">
                            <span>Don't have an account? </span>
                            <a href="#" onClick={this.register}>Sign up</a>
                        </div> */}
            <div className="text-center mt-3 text-lg">
              <p style={{ fontSize: "14px" }}>Log in directly with</p>
              <SocialLoginContainer>
              <GoogleLoginButton onLoginSuccess={handleGoogleLogin} />
              </SocialLoginContainer>
            </div>
          </ModalBody>
        </StyledModal>
      </>
    );
  }
}

export default Login;
