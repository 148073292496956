import React from 'react';
import styled from 'styled-components';

const UserAgreement = () => (
  <ContentSection>
    <SectionTitle>User Agreement</SectionTitle>
    
    <SectionContent>
      <h4>Definitions</h4>
      <p>ekjeet.com is referred to as 'we' or 'us'</p>
      <p>The Player is referred to as "you" or 'the Player'</p>
      <p>'The Website' means ekjeet.com through desktop, mobile or other platforms utilised by the Player</p>
    </SectionContent>

    <SectionContent>
      <h4>1. General</h4>
      <ol>
        <li>These User Agreement apply to the usage of games accessible through ekjeet.COM.</li>
        <li>These User Agreement come into force as soon as you complete the registration process, which includes checking the box stating User Agreement and successfully creating an account. By using any part of the Website following account creation, you agree to these User Agreement.</li>
        <li>You must read these User Agreement carefully in their entirety before creating an account. If you do not agree with any provision of these User Agreement, you must not create an account or continue to use the Website.</li>
        <li>We are entitled to make amendments to these User Agreement at any time and without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes to your attention (such as by email or placing a notice on a prominent position on the Website, together with the amended User Agreement) but it shall be your sole responsibility to check for any amendments, updates and/or modifications. Your continued use of ekjeet.com services and Website after any such amendment to the User Agreement will be deemed as your acceptance and agreement to be bound by such amendments, updates and/or modifications.</li>
        <li>These User Agreement may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us and in the case of any discrepancy with respect to a translation of any kind, the English version of these User Agreement shall prevail.</li>
      </ol>
    </SectionContent>

    <SectionContent>
      <h4>2. Binding Declarations</h4>
      <p>2.1. By agreeing to be bound by these User Agreement, you also agree to be bound by the ekjeet.com Rules and Privacy Policy that are hereby incorporated by reference into these User Agreement. In the event of any inconsistency, these User Agreement will prevail. You hereby represent and warrant that:</p>
      <ol>
        <li>You are over (a) 18 or (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever age is greater;</li>
        <li>You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;</li>
        <li>All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;</li>
        <li>You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;</li>
        <li>You understand that by using our services you take the risk of losing money deposited into your Member Account and accept that you are fully and solely responsible for any such loss;</li>
        <li>You are permitted in the jurisdiction in which you are located to use online casino services;</li>
        <li>In relation to deposits and Withdraws of funds into and from your Member Account, you shall only use Ethereum that are valid and lawfully belong to you;</li>
        <li>You understand that the value of Ethereum can change dramatically depending on the market value;</li>
        <li>The computer software, the computer graphics, the Websites and the user interface that we make available to you is owned by ekjeet.com or its associates and is protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, User Agreement we have established and in accordance with all applicable laws, rules and regulations;</li>
        <li>You understand that Ethereum is not considered a legal currency or tender and as such on the Website they are treated as virtual funds with no intrinsic value.</li>
        <li>You affirm that you are not an officer, director, employee, consultant or agent of ekjeet.com or working for any company related to ekjeet.COM, or a relative or spouse of any of the foregoing;</li>
        <li>You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such problem gambling arises whilst using our services, but will endeavour to inform of relevant assistance available. We reserve the right to implement cool off periods if we believe such actions will be of benefit.</li>
        <li>You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device properties, detection of geo-location and IP masking, transactions and blockchain analysis.</li>
      </ol>
    </SectionContent>

    <SectionContent>
      <h4>3. Restricted Territories</h4>
      <p>3.1. Blacklisted Territories: China, Netherlands, Dutch Caribbean Islands, Curacao, France, United States and/or any other restricted by law country or state. Note that it is strictly forbidden to play on ekjeet.com games in blacklisted countries mentioned above.your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.</p>
    </SectionContent>

    <SectionContent>
      <h4>4. General Betting Rules</h4>
      <ol>
        <li>A bet can only be placed by a registered account holder.</li>
        <li>A bet can only be placed over the internet.</li>
        <li>You can only place a bet if you have sufficient balance in your account with ekjeet.COM.</li>
        <li>The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being accepted.</li>
        <li>Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at which the bet was placed.</li>
        <li>ekjeet.com reserves the right to adjust a bet payout credited to a ekjeet.com account if it is determined by ekjeet.com in its sole discretion that such a payout has been credited due to an error.</li>
        <li>A bet, which has been placed and accepted, cannot be amended, withdrawn or cancelled by you.</li>
        <li>The list of all the bets, their status and details are available to you on the Website.</li>
        <li>When you place a bet you acknowledge that you have read and understood in full all of these User Agreement regarding the bet as stated on the Website.</li>
        <li>ekjeet.com manages your account, calculates the available funds, the pending funds, the betting funds as well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to be accurate.</li>
        <li>You are fully responsible for the bets placed.</li>
        <li>Winnings will be paid into your account after the final result is confirmed.</li>
      </ol>
    </SectionContent>

    <SectionContent>
      <h4>5. Bonuses and Promotions</h4>
      <p>5.1. ekjeet.com reserves the right to cancel any promotion, bonus or bonus program (including, but not limited to top-up rewards, invite friends to reward bonuses and loyalty programs) with immediate effect if we believe the bonus has been set up incorrectly or is being abused, and it will be withdrawn from your account. We further reserve the right to deny bonuses if you are found to be, or have been, abusing this or any other bonus scheme. Deposit bonuses can only be claimed once per Player and/or once per household unless stated otherwise. If you opt to make a Withdraw before the applicable wagering requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use of the bonus amounts before approving any Withdraw.</p>
    </SectionContent>

    <SectionContent>
      <h4>6. Live Chat</h4>
      <ol>
        <li>As part of your use of the Website we may provide you with a live chat facility, which is moderated by us and subject to controls. We reserve the right to review the chat and to keep a record of all statements made on the facility. Your use of the chat facility should be for recreational and socialising purposes.</li>
        <li>We have the right to remove the chat room functionality or immediately terminate your Member Account and refund your account balance if you:
          <ul>
            <li>(a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred or profanity;</li>
            <li>(b) make statements that are abusive, defamatory or harassing or insulting;</li>
            <li>(c) use the chat facility to advertise, promote or otherwise relate to any other online entities;</li>
            <li>(d) make statements about ekjeet.COM, or any other Internet site(s) connected to the Website that are untrue and/or malicious and/or damaging to ekjeet.COM;</li>
            <li>(e) user the chat facility to collude, engage in unlawful conduct or encourage conduct we deem seriously inappropriate. Any suspicious chats will be reported to the competent authority.</li>
          </ul>
        </li>
        <li>Live Chat is used as a form of communication between us and you and should not be copied or shared with any forums or third parties.</li>
      </ol>
    </SectionContent>

    <SectionContent>
      <h4>7. Limitation of Liability</h4>
      <ol>
        <li>You enter the Website and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever, whether expressed or implied.</li>
        <li>Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers:</li>
        <li>Do not warrant that the software, Games and the Websites are fit for their purpose.</li>
        <li>Do not warrant that the software, Games and the Websites are free from errors.</li>
        <li>Do not warrant that the software, Games and the Websites will be accessible without interruptions.</li>
        <li>Shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in the Games.</li>
        <li>You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such funds.</li>
        <li>You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.</li>
        <li>To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed €100.</li>
      </ol>
    </SectionContent>

    <SectionContent>
      <h4>8. Breaches, Penalties and Termination</h4>
      <ol>
        <li>If you breach any provision of these User Agreement or we have a reasonable ground to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Member Account, or withhold payment of your winnings and apply such funds to any damages due by you.</li>
        <li>You acknowledge that ekjeet.com shall be the final decision-maker of whether you have violated ekjeet.COM's rules, terms or conditions in a manner that results in your suspension or permanent barring from participation in our site.</li>
      </ol>
    </SectionContent>
    
  </ContentSection>
);

const ContentSection = styled.div`
  background-color: #31343C;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;

  h4 {
    color: #ffffff;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  p, li {
    margin-bottom: 10px;
  }

  ol, ul {
    padding-left: 20px;
  }
`;

export default UserAgreement;