import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #1B1D21;
  padding: 20px;
`;

const FooterWrapper = styled.div`
  background-color: #1B1D21;
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  width: 50%;
  padding-left: 40px;
  justify-content: center;
  color: #8E969f;
  font-family: Arial, sans-serif !important;
  font-size: 14px;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: #7F8893;
`;

const LinkHeader = styled.h3`
  color: #8E969f;
  font-family: Arial, sans-serif !important;
  font-size: 12px;
`;

const Link = styled.a`
  color: #8E969f;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const CurrencyIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const Flex = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FlexWrap = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 0.5px solid #8E969f;
  margin: 16px 0;
  width: 100%;
  height: 0;
`;

const BottomFlexWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const HelloFooter = () => {
  return (
    <Container>
      <FooterWrapper>
        <Section>
          <Logo src="/assets/images/ekjeet_logo.png" alt="Logo" />
          <Description>
            Ekjeet is built by Ekjeet for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more. Your greatest casino adventure is waiting for you!
          </Description>
        </Section>
        <Flex>
          <FlexWrap>
            <Section>
              <LinkHeader>HELP CENTER</LinkHeader>
              <Link href="#">USER AGREEMENT</Link>
              <Link href="#">PRIVACY POLICY</Link>
              <Link href="#">APP</Link>
            </Section>
            <Section>
              <LinkHeader>CONTACT US</LinkHeader>
              <Link href="mailto:Support@ekjeet.com">Email: <span style={{ color: '#3E9816' }}>Support@ekjeet.com</span></Link>
              {/* <LinkHeader>ACCEPTED CURRENCIES</LinkHeader>
              <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                <CurrencyIcon src="/assets/images/BTC.png" alt="Bitcoin" />
                <CurrencyIcon src="/assets/images/eth.png" alt="Ethereum" />
                <CurrencyIcon src="/assets/images/doge.png" alt="Dogecoin" />
                <CurrencyIcon src="/assets/images/TETHER.png" alt="Tether" />
                <CurrencyIcon src="/assets/images/ltc.png" alt="Litecoin" />
              </div> */}
            </Section>
          </FlexWrap>
        </Flex>
      </FooterWrapper>
      <Divider />
      <BottomFlexWrap>
        <div style={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
          <div style={{ textAlign: 'center', color: '#464B54' }}>
            <div>Ekjeet</div>
            <div>GAMING</div>
          </div>
        </div>
        <div style={{ color: '#464B54' }}>
          {/* <div>1BTC=$65750.81</div> */}
          <div>©2024 ekjeet.com ALL RIGHTS RESERVED</div>
        </div>
      </BottomFlexWrap>
    </Container>
  );
};

export default HelloFooter;