import React, { Component } from 'react'
import storage from "../../../../../Storage";
import ReactTooltip from "react-tooltip";

class Sounds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audio: (storage.getKey('audio') === 'on' || storage.getKey('audio') === null) ? 'mdi mdi-volume-high' : 'mdi mdi-volume-mute',
        };
    }

    toggleAudio = () => {
        if (storage.getKey('audio') === 'on' || storage.getKey('audio') === null) {
            this.setState({ audio: 'mdi mdi-volume-mute' })
            storage.setKey('audio', 'off')
        }
        else {
            this.setState({ audio: 'mdi mdi-volume-high' })
            storage.setKey('audio', 'on')
        }
    }

    render() {
        return (
            <>
                <ReactTooltip />
                <button onClick={this.toggleAudio} data-tip={'Toggle Sounds'} className={"btn btn-sm bg-cs2 mr-1 cpt animated fadeInDown"}>
                    <i className={this.state.audio + ' mr-1 font-15'} />
                </button>
            </>
        );
    }
}

export default Sounds;
