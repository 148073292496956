import React from 'react';
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Engine from "./Engine";
import Game from "./Game";
import Bet from "./Bet";
import { isMobile, wait, Event } from "../../../Helper";
import MinePopup from './Graph';

class Main extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1",
            showPopup: false
        };
        this.handleShowPopUp = this.handleShowPopUp.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(10).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }
        if (isMobile()) {
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh } = this.state;

        const help = <><p className={'font-light text-white font-15'}>
            Mines is a game of guessing and luck. You will first need to select a bet amount and the number of mines. The higher the mine value, the higher the payout. Once you have selected the number of mines and your bet amount you will place your bet. You will click each space, taking the chance of hitting a mine, or winning that value. You can cash out at any time!
        </p>
            <b>Players Tips & Tricks</b>
            <p className={'font-light text-white font-15'}>*These tips are submitted by actual players, so please consider with caution.
                Choose all 4 corners! <br />
                I go in a diagonal pattern, and I don’t choose more than 4 squares, then it gets a little risky.
                I always go with the center and 4 corners, seems to be lucky most of the time.
            </p>
            <h4 className="text-white text-success">
                How Can Calculate Profit ?
            </h4>
            <p className={'font-light text-white font-15'}>
                For the each clicked on the lands, number of mines multiplied by amount of the bet and result was divided by 10: <span className="text-secondary">(bet * mines) / 10</span>
            </p></>;
        return (
            <>
                <Helmet>
                    <title>Mine - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Mine Game, Online Mine Game, Bitcoin Mine Game, Blockchain Mine Game, Best Crypto Mine Game, PlayCrash.com Mine" />
                    <meta name="description" content="Crypto Mines is a game of guessing and luck. You will first need to select a bet amount and the number of mines. The higher the mine value, the higher the payout. Once you have selected the number of mines and your bet amount you will place your bet" />
                    <meta name="og:title" content="Play Online Mine - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Mine - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/mine" />
                </Helmet>
                <div>
                <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {this.state.showPopup && (
                                <MinePopup
                                    onClose={this.handleShowPopUp}
                                    engine={this.state.engine}
                                />
                            )}
                    <Row className={"animated fadeIn " + ovh}>
                        <div className="d-none">Crypto Mine Game Description: {help}</div>
                        <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                            {/* MENUS */}
                            <Card className="mb-0">
                                <Card.Body className="px-2 py-3">
                                    <Row>
                                        <Col md={6} className={'col-6 text-left'}>
                                            <BankRoll game={'mine'} />
                                        </Col>
                                        <div style={{ display: 'flex' }}>

                                                    <Col md={6} className={'col-6 text-right'}>
                                                        <TopBarGames help={help} image="/assets/images/covers_new/____hilo.png" />
                                                    </Col>
                                                    <div onClick={this.handleShowPopUp} style={{ marginLeft: '26px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                                                        <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                                                    </div>
                                                </div>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {this._Mounted ?
                                <>
                                    {/* GAME */}
                                    < Card className="mb-0">
                                        <Card.Body className={'bg-mine-options p-1'} id="roll-panel">
                                            <Row>
                                                <Col sm={12} md={10} className={'m-auto'}>
                                                    <Game engine={this.state.engine} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </>
                                :
                                < Card className="mb-0">
                                    <Card.Body className={'bg-mine-options p-1'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={10} className={'m-auto'}>
                                                <div className="game-loader  l-2 text-center">
                                                    <div className="spinner-grow text-info my-3" role="status" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            }

                            {/* BETS */}
                            <Card className="mb-0">
                                <Card.Body className={padding}>
                                    <Row>
                                        <Col sm={12} md={10} className={'m-auto'}>
                                            <Bet engine={this.state.engine} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* QUEUE */}
                            <Card className="mb-5">
                                <Card.Body className="p-1">
                                    <Row>
                                        <Col sm={12} md={12} className={'m-auto px-1'}>
                                            <Queue t={this.props.t} game={'mine'} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
