import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import coins from "../../../coins";
import { __, Event, wait, decode, encode, isValidNumber, forceSatoshiFormat, sendNotfication } from "../../../../Helper";
import { gameCoin } from "../../../../actions/gameCoin";
import C from "../../../../Constant";

class TipUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            amount: forceSatoshiFormat(0.00000050),
            coin: "BTC",
            list: [],
            disabled: false,
            currentCoin: "BTC"
        };
    }

    componentDidMount() {
        this._isMounted = true;

        wait(500).then(() => {
            this.setState({ loading: false });
        });

        this.props.gameCoin();

        socket.on(C.SEND_TIP_SELF, data => this.tipResponse(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    tipResponse = (data) => {
        if (this._isMounted) {
            let { coin, msg } = data;
            sendNotfication(__.toString(msg), 'success', 'top-right');
            wait(500).then(() => {
                socket.emit(C.CREDIT, encode({ coin: coin }));
            })
            this.setState({ disabled: false })
        }
    };

    submitForm(e) {
        if (this._isMounted) {
            e.preventDefault();

            if (this.state.amount.trim() === '')
                return;

            this.setState({ amount: forceSatoshiFormat(this.state.amount) })

            Swal.fire({
                title: 'Are you sure to send tip to this user?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.value) {
                    wait(10).then(() => {
                        this.setState({ disabled: true })
                        socket.emit(C.SEND_TIP, encode({
                            target: this.props.name,
                            amount: this.state.amount,
                            coin: this.state.currentCoin
                        }));
                    });
                }
            });
        }
    }

    handleInputChange = e => {
        let target = e.target;
        let value = target.value;
        this.setState({ coin: value });
    };

    handleChangeAmount = (event) => {
        let target = event.target;
        let value = target.value;

        if (!isValidNumber(value)) return;

        this.setState({ amount: value });
    };

    back = () => {
        Event.emit('force_modal_tip_close');
        this.setState({ loading: true, disabled: false });
        socket.emit(C.USER_INFO, encode({ id: this.props.id, coin: this.state.currentCoin, rate: null, game: 'all', first: true }));
    };

    selectCoin = (e, coin) => {
        e.preventDefault();
        this.setState({ currentCoin: coin })
    }

    render() {
        if (storage.getKey('name') === this.props.name) return null;

        let cryptos = coins.map((item, i) => (
            <Dropdown.Item key={__.toString(i)} as={'button'} className={"animated fadeIn"} onClick={(e) => this.selectCoin(e, item.preffix)}>
                <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin" /> {item.preffix}</span>
            </Dropdown.Item>
        ));

        return (
            <>
                {
                    this.state.loading ?
                        <>
                            <div className="text-center" style={{ minHeight: 150 }}>
                                <div className="spinner-grow text-white my-2 user-loader" role="status" />
                            </div>
                        </>
                        :
                        <>
                            {/* <h3 className="text-center font-17 mt-0 mb-2 text-warning bg-soft-primary">Tip {this.props.name}</h3> */}
                            <form onSubmit={(e) => this.submitForm(e)}>
                                <Row>
                                    <Col sm={12} md={12} xl={12}>
                                        <div className="form-group mb-3">
                                            <div className="input-group">
                                                <span className="input-group-append">
                                                    <div className="input-group-text">
                                                        <img src={"/assets/images/" + this.state.currentCoin + '.png'} className={"img-fluid mini-coin-12 mr-2"} alt="Coin" />
                                                        Amount
                                                    </div>
                                                </span>
                                                <input type="text" name={'amount'} className={'form-control'} onChange={this.handleChangeAmount} value={this.state.amount} />
                                                <span className="input-group-append">
                                                    <div className="input-group fw">
                                                        <Dropdown bottom="true">
                                                            <Dropdown.Toggle split variant="- h-100 text-white btn-block  tip-dropdown" id="dropdown-split-coins">
                                                                <span className="caret mr-1"></span>
                                                                <span stle={{ fontSize: 17 }}>
                                                                    {this.state.currentCoin}
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className={"droper user-dropdown-detail w-100 coins-drop"}>
                                                                {cryptos}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={6} xl={6}>
                                        <button disabled={this.state.disabled} type={'submit'} className={'btn btn-sm btn-block btn-s-2 mb-2 no-shadow'}>
                                            {this.state.loading ?
                                                <>
                                                    <div className="spinner-border spinner-border-sm mr-1" role="status" />
                                                </>
                                                :
                                                <i className={'mdi mdi-parachute mr-1'} />
                                            }
                                            Send Tip
                                        </button>
                                    </Col>
                                    <Col sm={6} md={6} xl={6}>
                                        <button type="button" className="btn btn-sm btn-block bg-cs2 ml-1 mb-2 no-shadow" onClick={this.back}>
                                            <i className="mdi mdi-refresh align-middle" /> Back
                                        </button>
                                    </Col>
                                </Row>
                            </form>
                        </>
                }
            </>
        );
    }
}

TipUser.propTypes = {
    coin: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, { gameCoin })(TipUser);
