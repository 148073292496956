import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  __,
  isMobile,
  wait,
  Event,
  decode,
  sendNotfication,
} from "../../../../Helper";
import socket from "../../../../Socket";
import C from "../../../../Constant";

const popularGames = ["crash", "classic-dice",,"singlekeno",];

class Popular extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      col: "col-12",
      mdCol: 3, // Change to 3 columns
      isMin: false,
      loading: true,
      games: [],
    };
  }

  componentDidMount() {
    if (isMobile()) {
      this.setState({ col: "col-4" });
    }

    window.addEventListener("resize", this.handleResize);

    wait(50).then(() => {
      this.handleResize();
    });

    Event.on("hide_games", () => {
      wait(50).then(() => {
        this.handleResize();
      });
    });

    socket.on(C.GAMES, (data) => {
      this.setState({ games: decode(data), loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const pageContent = document.querySelector("#page-content");
  
    if (pageContent !== null) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  
      this.setState((prevState) => ({
        mdCol: width < 700 ? 4 : 3,
        isMin: width < 700,
      }), () => {
        console.log("Updated state:", this.state);
      });
    }
  };

  render() {
    let { games, col, mdCol, isMin } = this.state;
    const popularsGames = games.filter(game => popularGames.includes(game.prefix));
    
    // Display up to 'maxGames'
    const list = popularsGames.slice(0, isMin ? popularGames.length : 4).map((game, i) => (
    
      <AddGame
        key={__.toString(i)}
        col={col}
        mdCol={mdCol}
        item={game}
        isMin={isMin}
      />
    ));
    return (
      <Row className={"home-game"}>
        {this.state.loading ? (
          <div className="ycenter text-center">
            <div className="spinner-grow text-white my-5" role="status" />
          </div>
        ) : (
          list
        )}
      </Row>
    );
  }
}

class AddGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  maintance = () => {
    sendNotfication("Under maintenance");
  };

  render() {
    let { item } = this.props;

    if (!popularGames.includes(item.prefix)) return <></>;

    let mdCol = 3;

    if (isMobile()) {
      mdCol = 4;
    }

    let disabled = false;

    return (
      <>
        {!disabled ? (
          <Col className={"col-" + mdCol + " mb-2 ovh animated fadeInUp"}>
            <Link
              to={item.prefix !== null ? "/" + item.prefix : "#"}
              className="game2-link"
            >
              <div
                className={
                  item.prefix !== null ? "game2-image" : "game2-image orange"
                }
              >
                <img
                  src={item.image}
                  className="img-fluid w-100"
                  alt={item.name}
                />
              </div>
            </Link>
          </Col>
        ) : (
          <Col className={"col-" +mdCol + " mb-2 ovh animated fadeInUp"}>
            <Link to={"#"} onClick={this.maintance} className="game2-link">
              <div
                className={
                  item.prefix !== null ? "game2-image" : "game2-image orange"
                }
              >
                <img
                  src={item.image}
                  className="img-fluid w-100"
                  alt={item.name}
                />
              </div>
            </Link>
          </Col>
        )}
      </>
    );
  }
}

export default Popular;
