import React from 'react';
import storage from "../../../Storage";
import { wait, isMobile, playAudio } from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            canvas: null,
            engine: this.props.engine,
            token: storage.getKey('token')
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        var c = new Canvas(this.state.engine);
        this.setState({ canvas: c })
        c.init();

        engine.on(C.PLAY_TOWER, () => this.play());
        engine.on(C.CLICKED_TOWER, (data) => this.clicked(data));
        engine.on(C.CASHOUT_TOWER, (data) => this.cashout(data));
        engine.on(C.BUSTED_TOWER, (data) => this.busted(data));

        if (isMobile()) {
            let canvas = document.getElementById('game');
            canvas.style.width = '100%'
            canvas.style.height = '100%'
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.canvas !== null)
            this.state.canvas.destroy();
    }


    play = () => {
        if (this._isMounted) {
            playAudio('dice.mp3');
        }
    }

    clicked(data) {
        if (this._isMounted) {
            let { id } = data;
            this.state.canvas.clicked = false;
            this.state.canvas.winner();
            wait(2000).then(() => {
                playAudio('win.mp3');
            })
        }
    }

    busted(data) {
        if (this._isMounted) {
            let { mines } = data;
            this.state.canvas.busted();
            this.state.canvas.clicked = false;
        }
    }

    cashout(data) {
        if (this._isMounted) {
            this.state.canvas.clicked = false;
            wait(2000).then(() => {
                this.state.canvas.end();
                playAudio('win.mp3');
            })
        }
    }

    render() {
        return (
            <div className="animated fadeIn">
                <div className="text-center" id="cc">
                    <canvas id="game" />
                </div>
            </div>
        );
    }
}

export default Game;
