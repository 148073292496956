import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components (reusing your existing styled components)
const HelpCenterContainer = styled.div`
  display: flex;
  color: #ffffff;
  gap: 10px;
  align-items: flex-start;
`;

const SideContent = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #31343C;
  border-radius: 20px;
  align-self: flex-start;
`;

const SideContentItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
  color: ${props => props.active ? '#57D218' : '#72767d'};
  &:hover {
    color: #ffffff;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: 100%;
`;

const ComponentContainer = styled.div`
  background-color: #36393f;
  color: #b9bbbe;
  padding: 24px;
  border-radius: 20px;
`;

const SectionContent = styled.div`
  color: #b9bbbe;
  font-size: 14px;
  line-height: 1.5;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 10px;
`;
const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
  border-bottom: 1px solid #42464D;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const SettingInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top:10px;
`;

const SettingTitle = styled.div`
  font-size: 16px;
  color: #B8B9BF;
  margin-bottom: 4px;
`;

const SettingDescription = styled.div`
  font-size: 14px;
  color: #B8B9BF;
  opacity: 0.7;
`;

const ToggleSwitch = styled.div`
  width: 40px;
  height: 24px;
  background-color: #42464D;
  border-radius: 12px;
  cursor: pointer;
  margin-left: 16px;
  flex-shrink: 0;
`;

const Privacy = () => (
  <ComponentContainer>
    <SectionTitle>Privacy</SectionTitle>
    <SectionContent>
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Hide my gameing data on profile</SettingTitle>
          <SettingDescription>Even if hidden, your avatar and username are always visible in chatrooms.</SettingDescription>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
      
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Hide my username from public lists</SettingTitle>
          <SettingDescription>If hidden, no one can view your profile by clicking on your avatar or username on public rankings or bets list.</SettingDescription>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
      
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Hide my online presence in private chat</SettingTitle>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
      
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Allow private messages from strangers</SettingTitle>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
      
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Refuse any new friend request</SettingTitle>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
      
      <SettingRow>
        <SettingInfo>
          <SettingTitle>Refuse tip from strangers</SettingTitle>
        </SettingInfo>
        {/* <ToggleSwitch /> */}
      </SettingRow>
    </SectionContent>
  </ComponentContainer>
);

// Component definitions
const General = () => (
  <ComponentContainer>
    <SectionTitle>General</SectionTitle>
    <SectionContent>General settings and information content goes here.</SectionContent>
  </ComponentContainer>
);


const Security = () => (
  <ComponentContainer>
    <SectionTitle>Security</SectionTitle>
    <SectionContent>
      <p>Manage your account security settings:</p>
      <ul>
        <li>Two-factor authentication</li>
        <li>Password management</li>
        <li>Login history</li>
        <li>Security notifications</li>
      </ul>
    </SectionContent>
  </ComponentContainer>
);

const ActiveSessions = () => (
  <ComponentContainer>
    <SectionTitle>Active Sessions</SectionTitle>
    <SectionContent>
      <p>View and manage your active sessions across different devices:</p>
      <div style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #4f545c' }}>
          <div>Current Session - Chrome on Windows</div>
          <div style={{ color: '#57D218' }}>Active</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #4f545c' }}>
          <div>Mobile App - iPhone 12</div>
          <div style={{ color: '#72767d' }}>Last active: 2 hours ago</div>
        </div>
      </div>
    </SectionContent>
  </ComponentContainer>
);

const Verify = () => (
  <ComponentContainer>
    <SectionTitle>Verify</SectionTitle>
    <SectionContent>
      <p>Verify your account to unlock additional features:</p>
      <ul>
        <li>Email verification</li>
        <li>Phone verification</li>
        <li>Identity verification</li>
      </ul>
    </SectionContent>
  </ComponentContainer>
);

const GlobalSettings = () => {
  const [activeSection, setActiveSection] = useState('General');
  
  const sideContentItems = [
    { name: 'General', component: General },
    { name: 'Privacy', component: Privacy },
    { name: 'Security', component: Security },
    { name: 'Active Sessions', component: ActiveSessions },
    { name: 'Verify', component: Verify },
  ];

  const ActiveComponent = sideContentItems.find(item => item.name === activeSection)?.component || (() => null);

  return (
    <div style={{ marginTop: '48px' }}>
      <div style={{ 
        fontSize: '16px', 
        fontWeight: 'bold', 
        margin: '10px 0', 
        color: '#ffffff', 
        display: 'flex', 
        gap: '10px', 
        alignContent: 'center', 
        alignItems: 'center' 
      }}>
        <img style={{height:'20px', width:'20px'}} src='/assets/images/hello-settings.png' alt="Help Center Icon" />
        <div>Global Settings</div>
      </div>
      <HelpCenterContainer>
        <SideContent>
          {sideContentItems.map(item => (
            <SideContentItem
              key={item.name}
              active={activeSection === item.name}
              onClick={() => setActiveSection(item.name)}
            >
              {item.name}
            </SideContentItem>
          ))}
        </SideContent>
        <MainContent>
          <ActiveComponent />
        </MainContent>
      </HelpCenterContainer>
    </div>
  );
};

export default GlobalSettings;