import * as PIXI from "pixi.js";
import Effect from "./components/Effect";
import { wait, getRandomBetween, __ } from "../../../Helper";

let loaded = false;

PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

class Game {
  constructor(engine) {
    this.app = new PIXI.Application({
      width: 500,
      height: 400,
      resolution: 1,
      transparent: true,
      view: document.getElementById('game')
    });
    this.engine = engine;
    this.started = false;
    this.roll = false;
    this.switch = 'off';
    this.numbers = [2, 3, 4];
    this.target = null;
    this.num = [];
  }

  init() {
    if (!loaded) {
      PIXI.Loader.shared
        .add('check', 'assets/images/high-low/spreetsheet/spritesheet.json')
    }
    PIXI.Loader.shared.load(e => this.load());
  }

  load() {
    loaded = true;
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true
    this.container.sortableChildren = true
    this.app.stage.addChild(this.container);

    this.configAnim()
    this.configMachine()
    this.configControl()
    this.configNumbers()
  }

  configAnim() {
    this.app.ticker.add((delta) => {

      if (this.roll) {
        for (var i = 0; i < this.num.length; i++) {
          this.num[i].text = getRandomBetween(1, 9)
        }
      }

      if (this.started) {
        for (var i = 0; i < this.num.length; i++) {
          this.num[i].text = this.target[i];
          Effect.MoveIn(this.num[i], this.num[i].x, this.num[i].y)
          if (i === 2) {
            this.started = false;
            this.roll = false;
            this.control.removeChild(this.animControl);
            this.control.texture = PIXI.Texture.from('assets/images/high-low/off.png')
          }
        }
      }
    })
  }

  configMachine() {
    this.machine = new PIXI.Sprite.from('assets/images/high-low/machine.png');
    this.machine.x = 80;
    this.machine.y = 20;
    this.container.addChild(this.machine)
    Effect.FadeIn(this.machine);
  }

  configControl() {
    this.control = new PIXI.Sprite.from('assets/images/high-low/off.png');
    this.control.x = 90;
    this.control.y = 265;
    this.machine.addChild(this.control)
    Effect.FadeIn(this.control);
  }

  configNumbers() {
    this.numbers.forEach((num, i) => {
      let style = new PIXI.TextStyle({ fontFamily: 'Inter', fontSize: 35, fontWeight: 700, fill: [0xFFFFFF] });
      this.num[i] = new PIXI.Text(num, style);
      this.num[i].x = i * 57 + 110;
      this.num[i].y = 155;
      this.machine.addChild(this.num[i])
    })
  }

  play(target) {
    if (this.switch === 'off') {
      this.roll = true;
      this.target = target;
      const checkTextures = []
      for (var i = 0; i < 4; i++) {
        const texture = PIXI.Texture.from(`${i + 1}.png`);
        checkTextures.push(texture);
      }
      this.animControl = new PIXI.AnimatedSprite(checkTextures);
      this.animControl.animationSpeed = .2;
      this.animControl.gotoAndPlay(0);
      this.control.addChild(this.animControl);

      wait(1300).then(() => {
        this.animControl.stop();
        this.started = true;
        this.switch = 'off'
      })
    }
    else {
      this.control.texture = PIXI.Texture.from('assets/images/high-low/off.png')
      this.switch = 'off'
    }
  }

  destroy() {
    this.app = null;
  }
}

export default Game;