import MyEmitter from "events";
import storage from "../../../Storage";
import socket from "../../../Socket";
import { encode, decode, forceSatoshiFormat } from "../../../Helper";
import C from "../../../Constant";

/**
 * Wheel Engine
 */
function Engine() {

    var self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'wheel';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = null;

    /**
     * Game Status
     * 1 type: started
     */
    self.gameStatus = null;

    /**
     * Intialize Auto Mode
     */
    self.init = false;

    /**
     * Current User Coin
     */
    self.coin = null;

    /**
     * Round amount
     */
    self.amount = null;

    /**
     * Risk
     */
    self.risk = null;

    /**
     * Segment
     */
    self.segment = null;

    /**
     * Win or Lost
     */
    self.win = null;

    /**
     * Busted Game Hash
     */
    self.hash = null;

    /**
     * Bet Number
     */
    self.betNumber = null;

    /**
     * Increase Amount on Win
     */
    self.onWin = 0;

    /**
     * Increase Amount on Lost
     */
    self.onLost = 0;

    /**
     * Played Game Rounds
     */
    self.played = 0;

    /**
     * Player is the Win or Lost
     */
    self.isWin = false;

    /**
     * Stop Playing if Won Amount
     */
    self.stopOnWin = 0;

    /**
     * Round Profit
     */
    self.roundProfit = 0;

    /**
     * Result Number
     */
    self.resultNumber = null;

    /*
     * Start Engine
    */
    self.started = false;

    self.intialize = function () {
        self.trigger = new MyEmitter();
        self.started = true;

        self.ws.on(C.PLAY_WHEEL, (data) => {
            data = decode(data);

            switch (data.command) {
                case 'play':
                    return self.trigger.emit(C.PLAY_WHEEL, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_WHEEL, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_WHEEL, data);
            }
        });
    }
}

Engine.prototype.stop = function () {
    let self = this;
    self.started = false;
    self.trigger = null;
    self.ws.off(C.PLAY_WHEEL);
}

Engine.prototype.on = function (name, callback) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []) {
    let self = this;
    if (!self.started) return;
    if (self.trigger === null) return;
    return self.trigger.emit(name, data);
}

/**
 * Start Playing Game
 */
Engine.prototype.start = function () {
    let self = this;
    if (!self.init)
        return;

    if (self.amount < 50.00) {
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_WHEEL, encode({
        command: 'play',
        risk: self.risk,
        coin: self.coin,
        amount: self.amount,
        segment: self.segment
    }));
};

/**
 * End Playing Game
 */
Engine.prototype.busted = function () {
    let self = this;

    if (!self.init)
        return;

    self.ws.emit(C.PLAY_WHEEL, encode({
        command: 'busted',
        win: self.win,
        result: self.resultNumber
    }));
};

/**
 * Auto Start Playing Game
 */
Engine.prototype.autoStart = function () {
    let self = this;

    if (!self.init)
        return;

    self.played++;

    if (parseInt(self.betNumber) <= 0) {
        self.played = 0;
        self.trigger.emit('stop_wheel');
        return;
    }

    // Stop On Bet Number
    if (self.played === parseInt(self.betNumber)) {
        self.played = 0;
        self.trigger.emit('stop_wheel');
        return;
    }

    // Increase Amount on Win (player set increase amount on win in %)
    if (self.onWin !== 0) {
        // It Mean Player has win in this round
        if (self.isWin) {
            self.amount *= parseInt(self.onWin);
        }
    }

    // Increase Amount on Lost (player set increase amount on lost in %)
    if (self.onLost !== 0) {
        // It Mean Player has lost in this round
        if (!self.isWin) {
            self.amount *= parseInt(self.onLost);
        }
    }

    //Stop On Win (player set amount to stop on win)
    if (self.stopOnWin !== 0) {
        if (forceSatoshiFormat(self.stopOnWin) >= forceSatoshiFormat(self.roundProfit)) {
            self.played = 0;
            self.roundProfit = 0;
            self.trigger.emit('stop_wheel');
            return;
        }
    }

    if (self.amount < 50.00) {
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_WHEEL, encode({
        command: 'play',
        risk: self.risk,
        coin: self.coin,
        amount: self.amount,
        segment: self.segment
    }));
};

/** Start Game Engine **/
export default Engine;
