import MyEmitter from "events";
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {Event, forceSatoshiFormat, encode, decode} from "../../../Helper";

/**
 * Game Engine
 */
function Engine(){

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = 'videopoker';

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Itialize Game
     */
    self.init = false;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /**
     * Choosed Cards
     */
    self.cards = [];

    /**
     * dealCards Cards
     */
    self.dealCards = [];

    /**
     * Winning Score
     */
    self.winning = null;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_VIDEOPOKER, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_VIDEOPOKER, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_VIDEOPOKER, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_VIDEOPOKER, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_VIDEOPOKER);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }
    
    self.ws.emit(C.PLAY_VIDEOPOKER, encode({
        command: 'play',
        amount: self.amount,
        coin: self.coin
    }));
};

/**
 * Deal game ( Busted )
 */
Engine.prototype.deal = function () {
    let self = this;

    if(!self.init)
        return;

    self.ws.emit(C.PLAY_VIDEOPOKER, encode({
        command: 'busted',
        cards: self.cards,
        dealCards: self.dealCards,
        winning: self.winning
    }));
};

/** Start Game Engine **/
export default Engine
