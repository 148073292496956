import React, { useState } from 'react';
import styled from 'styled-components';
import HelpCenter from './HelpCenter';
import UserAgreement from './UserAgreement';
import CoinAccuracyLimit from './CoinsAndCurrencyLimit';
import SupportHelp from './SupportHelp';
import FeeComponent from './FeeHelp';
import GoogleAuthenticatorComponent from './GoogleAuthenticator';
import FAQComponent from './FAQ';
import RegistrationAndLogin from './RegisterAndLogin';
import CryptocurrencyInfo from './CryptCurrencyInfo';
import PrivacyPolicyHelp from './PrivacyPolicyHelp';
import HGSwap from './HGSwapPolicy';
import TermsOfService from './TermsofServiceHelp';
import ProvidersHelp from './ProvidersHelp';
import { MdKeyboardArrowRight } from "react-icons/md";

// Styled components
const HelpCenterContainer = styled.div`
  display: flex;
  color: #ffffff;
  gap: 10px;
  align-items: flex-start;
position:relative;

`;

const SideContent = styled.div`
  width: 200px;
  padding: 20px;
  background-color: #31343C;
  border-radius: 20px;
  align-self: flex-start;

  @media (max-width: 968px) {
    display: none;
  }
`;

const SideContentItem = styled.div`
  padding: 10px 0;
  cursor: pointer;
  color: ${props => props.active ? '#57D218' : '#72767d'};
  &:hover {
    color: #ffffff;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-height: 100%;
`;

const ResponsiveSelect = styled.div`
  display: none;
   button {
    width: 250px; 
    height: 7vh;  
    color: red;  
    background: #2B2E35;  
    border: none;  
    border-radius: 20px; 
    cursor: pointer; 
    font-size: 1rem; 
    transition: background 0.3s;  
    font-weight: 600;
    padding: 10px;

  }

  @media (max-width: 968px) {
    display: block;  
  }
`;
 
const ProvablyFairSelect = styled.div`
  display: none;
  border-radius: 1.25rem;
  padding: 0.125rem 0.375rem;
  background-color: rgb(23, 24, 27);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 8px 0px;
  height: auto;
  max-height: 16.25rem;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 9999;
  touch-action: pan-y;
  overscroll-behavior: contain;

  @media (max-width: 968px) {
    display: block;
    width: 100%; 
    position: absolute; 
    top:3.5rem;
  }
`;

const List = styled.div`
  margin-top: 0;
  color: ${props => props.active ? '#ffffff' : 'rgba(245, 246, 247, 0.6)'};
  font-weight: bold;
  height: 2.25rem;
  border: ${props => props.active ? '1px solid rgba(91, 174, 28, 1)' : ''};
  font-size: 0.875rem;
  display: flex;
  align-items: center; 
  position: relative;
  padding: 0 0.625rem;
  margin: 0.25rem 0;
  border-radius: 1.0625rem;
  cursor: pointer;
  white-space: nowrap; 
  background-color: transparent; 

  &:hover {
    border-color: rgba(91, 174, 28, 1);  
  }
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ProvablyFair = () => {
    const [activeListItem, setActiveListItem] = useState('Provably fair');
    const [activeSection, setActiveSection] = useState('Provably fair');
    const [isSelectOpen, setSelectOpen] = useState(false);  

    const sideContentItems = [
        { name: 'Provably fair', component: HelpCenter },
        { name: 'User Agreement', component: UserAgreement },
        { name: 'Coin Accuracy Limit', component: CoinAccuracyLimit },
        { name: 'Support', component: SupportHelp },
        { name: 'Fee', component: FeeComponent },
        { name: 'Google Authenticator', component: GoogleAuthenticatorComponent },
        { name: 'FAQ', component: FAQComponent },
        { name: 'Cryptocurrency', component: CryptocurrencyInfo },
        { name: 'Privacy Policy', component: PrivacyPolicyHelp },
        { name: 'Registration and Lo...', component: RegistrationAndLogin },
        { name: 'Swap Policy', component: HGSwap },
        { name: 'Terms of Service', component: TermsOfService },
        { name: 'Providers', component: ProvidersHelp }
    ];

    const ActiveComponent = sideContentItems.find(item => item.name === activeSection)?.component || (() => null);

    const handleListItemClick = (item) => {
        setActiveListItem(item);
        setActiveSection(item);
        setSelectOpen(false); 
    };

    const handleButtonClick = () => {
        setSelectOpen(!isSelectOpen);  
    };

    return (
        <div style={{ marginTop: '48px' }}>

            <div style={{ fontSize: '16px', fontWeight: 'bold', margin: '10px 0', color: '', display: 'flex', gap: '10px', alignContent: 'center', alignItems: 'center' }}>
                <img style={{height:'20px', width:'20px'}} src='/assets/images/taraju.png' />
                <div>Help Center</div>
            </div>

            <HelpCenterContainer>

                <SideContent>
                    {sideContentItems.map(item => (
                        <SideContentItem
                            key={item.name}
                            active={activeSection === item.name}
                            onClick={() => setActiveSection(item.name)}
                        >
                            {item.name}
                        </SideContentItem>
                    ))}
                </SideContent>

                <ResponsiveWrapper>
                    <ResponsiveSelect>
                        <button 
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                            onClick={handleButtonClick}
                        >
                            {activeListItem}
                            <MdKeyboardArrowRight  style={{ transform: isSelectOpen ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}  fontSize={"25px"}/>
                        </button>
                    </ResponsiveSelect>

                    {isSelectOpen && (  
                        <ProvablyFairSelect>
                            {sideContentItems.map(item => (
                                <List 
                                    key={item.name}
                                    active={activeListItem === item.name}
                                    onClick={() => handleListItemClick(item.name)}
                                >
                                    {item.name}
                                </List>
                            ))}
                        </ProvablyFairSelect>
                    )}

                    <MainContent>
                        <ActiveComponent />
                    </MainContent>
                </ResponsiveWrapper>
                
            </HelpCenterContainer>

        </div>
    );
};

export default ProvablyFair;
