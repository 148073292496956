import { Row, Col, Card } from "react-bootstrap";

export default function Loading() {
    return (<Row className={"animated fadeIn ovh"}>
        <Col sm={12} className={'m-auto  col-xl-9  p-0'}>

            <Card className="mb-0">
                <Card.Body className={'p-1'} id="roll-panel">
                    <Row>
                        <Col sm={12} md={10} className={'m-auto'}>
                            <div className="game-loader  l-2  text-center">
                                <div className="spinner-grow text-purple text-info my-3" role="status" />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </Col>
    </Row>)
}