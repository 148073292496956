import React from 'react';
import styled from 'styled-components';
import GsCard from './BannerCard';
import GsCard1 from './BannerCard2';

const Container = styled.div`
  background-color: rgb(25, 27, 30);
  color: white;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);  
  gap: 10px;
  border-radius: 8px;
  margin-bottom:12px;

  /* Responsive grid columns for larger screens */
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr); /* Four columns for screens wider than 1200px */
  }

  @media(max-width:415px){
gap: 0px;
margin:0px;
display:flex;
justify-content:space-between;
  }
`;



const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.2em;

  /* Hide the last two GridItems on smaller screens */
  &:nth-last-child(-n+2) {
    @media (max-width: 768px) {
      display: none; /* Hide the last two grid items on screens smaller than 768px */
    }
  }

   @media(max-width:415px){
   width:120px
  }
`;


const TopHeading = styled.div`
  display: flex;
  align-items: center;
`;

const HeadingContainer = styled.div`
  padding: 8px 6px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
`;

const PinkText = styled.span`
  color: #DA1341;
  font-size: 14px;
  font-family: Arial, sans-serif;

   @media(max-width:415px){
  font-size: 10px !important;  
    }
`;

const YellowText = styled.span`
  color: #FFA800;
  font-size: 14px;
  font-family: Arial, sans-serif;

  
  @media(max-width:415px){
  font-size: 10px !important;  
    }

`;

const WhiteText = styled.span`
  color: white;
  font-size: 14px;
  font-family: Arial, sans-serif;
  margin-left: 4px;


  @media(max-width:415px){
  font-size: 8px !important;  
    }

`;

const GreenText = styled.span`
  color: #14C455;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const GSContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GSCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: 200px;
`;

const GSIconPlaceholder = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 10px;

    @media(max-width:415px){
  width:30px;
  height:30px;
  }
`;

const GSTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GSProfitText = styled.span`
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const GSDescriptionText = styled.span`
  color: #888;
  font-size: 14px;
  font-family: Arial, sans-serif;
`;

const OptionalText = styled.span`
  color: #888;
  font-size: 12px;
  font-family: Arial, sans-serif;
`;

const BannerGames = () => {
  return (
    <Container>
      <GridItem>
        <TopHeading>
          <GSIconPlaceholder src='/assets/images/nano/bigprofit.webp' />
          <HeadingContainer>
            <PinkText>Much</PinkText>
            <WhiteText>Wow Wins</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
      <GridItem>
        <TopHeading>
          <img className='crad-2-img' src='/assets/images/nano/topprofit.webp' style={{ height: '36px', width: '36px', objectFit: 'contain' }} />
          <HeadingContainer>
            <YellowText>Top</YellowText>
            <WhiteText>Winning Games</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard1 />
      </GridItem>
      <GridItem>
        <TopHeading>
          <img src='/assets/images/nano/popular.webp' style={{ height: '36px', width: '36px', objectFit: 'contain' }} />
          <HeadingContainer>
            <GreenText>Trending</GreenText>
            <WhiteText>Right Now</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
      <GridItem>
        <TopHeading>
          <img src='/assets/images/nano/recent.webp' style={{ height: '36px', width: '36px', objectFit: 'contain' }} />
          <HeadingContainer>
            <WhiteText>Recently Added</WhiteText>
          </HeadingContainer>
        </TopHeading>
        <GsCard />
      </GridItem>
    </Container>
  );
};

export default BannerGames;