import _ from "lodash";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import Effect from "./components/Effect";

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
PIXI.settings.ROUND_PIXELS = true;
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;

class Game {

  constructor(engine) {
    this.app = new PIXI.Application({
      width: 750,
      height: 200,
      resolution: 1,
      backgroundAlpha: false,
      view: document.getElementById('game')
    });
    this.engine = engine;
    this.place = [];
    this.diamond = [];
    this.onStart = true;
  }

  init() {
    PIXI.Loader.shared.load(e => this.load());
  }

  load() {
    this.container = new PIXI.Container();
    this.app.stage.sortableChildren = true
    this.container.sortableChildren = true
    this.app.stage.addChild(this.container);

    this.configPlace();
    this.start();
  }

  setStart() {
    this.onStart = false;
  }

  configPlace() {
    for (var i = 0; i < 5; i++) {
      var place = PIXI.Sprite.from('assets/images/diamond/place.png');
      place.y = this.app.screen.height - 70;
      place.x = 150 + i * 150 - 150;
      this.container.addChild(place);
      this.place.push(place)
    }
  }

  start(target = [1, 2, 3, 4, 5]) {
    for (var i = 0; i < this.diamond.length; i++) {
      let place = this.place[i];
      let diamond = this.diamond[i];
      wait(i * 200).then(() => {
        gsap.to(diamond, { alpha: 0, y: -50 });
        wait(200).then(() => {
          if (!_.isUndefined(place)) {
            place.removeChild(diamond)
            place.texture = PIXI.Texture.from('assets/images/diamond/place.png');
          }
          this.diamond.splice(0, 1)
        })
      })
    }

    wait(this.onStart ? 100 : 1800).then(() => {
      this.play(target);
    })
  }

  play(target) {
    for (var i = 0; i < 5; i++) {
      let random = target[i]
      let sprite = PIXI.Sprite.from(`assets/images/diamond/${random}.png`);
      sprite.y = -60;
      sprite.x = 25;
      sprite.alpha = 0
      sprite.id = random;
      this.diamond[i] = sprite;
      this.place[i].addChild(sprite);
      wait(i * 300).then(() => {
        gsap.to(sprite, { alpha: 1, y: -80 })
      })
    }

    wait(1800).then(() => {
      if (this.onStart) return;

      let diamond0 = this.place[0].children[0].id;
      let diamond1 = this.place[1].children[0].id;
      let diamond2 = this.place[2].children[0].id;
      let diamond3 = this.place[3].children[0].id;
      let diamond4 = this.place[4].children[0].id;

      if (diamond0 === diamond1) {
        this.place[0].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        this.place[1].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');

        Effect.HeartBeat(this.place[0].children[0], 3)
        Effect.HeartBeat(this.place[1].children[0], 3)
        Effect.Particle(this.place[0].children[0], diamond1)
        Effect.Particle(this.place[1].children[0], diamond1)
      }
      if (diamond1 === diamond2) {
        this.place[1].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        this.place[2].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        Effect.HeartBeat(this.place[1].children[0], 3)
        Effect.HeartBeat(this.place[2].children[0], 3)
        Effect.Particle(this.place[1].children[0], diamond2)
        Effect.Particle(this.place[2].children[0], diamond2)
      }
      if (diamond2 === diamond3) {
        this.place[2].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        this.place[3].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        Effect.HeartBeat(this.place[2].children[0], 3)
        Effect.HeartBeat(this.place[3].children[0], 3)
        Effect.Particle(this.place[2].children[0], diamond3)
        Effect.Particle(this.place[3].children[0], diamond3)
      }
      if (diamond3 === diamond4) {
        this.place[3].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        this.place[4].texture = PIXI.Texture.from('assets/images/diamond/place-on.png');
        Effect.HeartBeat(this.place[3].children[0], 3)
        Effect.HeartBeat(this.place[4].children[0], 3)
        Effect.Particle(this.place[3].children[0], diamond4)
        Effect.Particle(this.place[4].children[0], diamond4)
      }
    })
  }

  destroy() {
    this.app = null;
  }
}

export default Game;