import React from 'react';
import {Row, Col, Card} from "react-bootstrap";
import {__, wait, isMobile, isTablet} from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state ={
            engine: this.props.engine,
            canvas: null,
            interval: []
        }
    }

    componentDidMount(){
        this._isMounted = true;
        let { engine } = this.state;
		
        engine.on(C.PLAY_THREE_CARD_MONTE, () => this.play());
        engine.on(C.BUSTED_THREE_CARD_MONTE, (data) => this.busted(data));
		
        var canvas = new Canvas(engine);
            canvas.init();
            
        this.setState({ canvas: canvas })

        if(isMobile() || isTablet()){
            let c = document.getElementById('game');
            c.style.width = '100%'
            c.style.height = '100%'
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    play(){
        if(this._isMounted)
        {
            this.state.canvas.play();
        }
    }

    busted(data){
        if(this._isMounted)
        {
            this.state.canvas.busted(data.result, data.win);
        }
    }

    render() {
        return <>
              <div className="text-center animated fadeIn">
                 <div id="loading" className="ycenter" />
                 <canvas id="game" />
              </div>
        </>;
    }
}

export default Game;
