import React from 'react';
import { __, Event, isMobile, isTablet, playAudio } from "../../../Helper";
import C from "../../../Constant";
import Canvas from "./Canvas";

class Game extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: this.props.engine,
            canvas: null,
            interval: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let { engine } = this.state;

        engine.on(C.PLAY_HIGHLOW, (data) => this.play(data));

        var canvas = new Canvas(engine);
        canvas.init();

        this.setState({ canvas: canvas });

        if (isMobile() || isTablet()) {
            let c = document.getElementById('game');
            c.style.width = '100%'
            c.style.height = '100%'
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.state.canvas !== null)
            this.state.canvas.destroy();
    }

    play(data) {
        if (this._isMounted) {
            this.state.canvas.play(data.target);
            playAudio('dice.mp3');
        }
    }

    render() {
        return <>
            <div className="text-center animated fadeIn">
                <div id="loading" className="ycenter" />
                <canvas id="game" />
            </div>
        </>;
    }
}

export default Game;
