import MyEmitter from "events";
import socket from "../../../Socket";
import C from "../../../Constant";
import {encode, decode, forceSatoshiFormat} from "../../../Helper";

/**
 * Game Engine
 */
function Engine(){

    let self = this;

    /**
     * Socket Connection
     */
    self.ws = socket;

    /**
     * Game Name
     */
    self.game = null;

    /**
     * Event Trigger
     * @type {module:events.internal}
     */
    self.trigger = new MyEmitter();

    /**
     * Intialize Starting Game
     */
    self.init = false;

    /**
     * Round Amount
     */
    self.amount = null;

    /**
     * Player Coin
     */
    self.coin = null;

    /*
     * Target Card
    */
    self.target = null;

    /*
     * Start Engine
    */
    self.started = true;

    if(self.started)
    {
        self.ws.on(C.PLAY_THREE_CARD_MONTE, (data) => {
            data = decode(data);

            switch(data.command)
            {
                case 'play':
                    return self.trigger.emit(C.PLAY_THREE_CARD_MONTE, data);
                case 'busted':
                    return self.trigger.emit(C.BUSTED_THREE_CARD_MONTE, data);
                case 'error':
                    return self.trigger.emit(C.ERROR_THREE_CARD_MONTE, data);
            }
        });
    }
}

Engine.prototype.off = function (){
    let self = this;
    self.ws.off(C.PLAY_THREE_CARD_MONTE);
}

Engine.prototype.on = function (name, callback){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.on(name, (data) => callback(data));
}

Engine.prototype.emit = function (name, data = []){
    let self = this;
    if(!self.started) return;
    if(self.trigger === null) return;
        return self.trigger.emit(name, data);
}

/**
 * Start playing game
 */
Engine.prototype.start = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_THREE_CARD_MONTE, encode({
        amount: self.amount,
        coin: self.coin,
        command: 'play'
    }));
};


/**
 * Click on the card
 */
Engine.prototype.clicked = function () {
    let self = this;

    if(!self.init)
        return;

    if(self.amount < 50.00){
        self.amount = forceSatoshiFormat(50.00)
    }

    self.ws.emit(C.PLAY_THREE_CARD_MONTE, encode({
        amount: self.amount,
        coin: self.coin,
        target: self.target,
        command: 'clicked'
    }));
};

/** Start Game Engine **/
export default Engine
