// googleLoginHelper.js
import { GoogleLogin } from 'react-google-login';
import socket from "../../../Socket";
import { CLIENT_ID, encode, randomString } from "../../../Helper";
import C from "../../../Constant";

// Handle Google login
export const handleGoogleLogin = (response) => {
    if (response.accessToken) {
        const profile = response.profileObj;

        socket.emit(C.LOGIN_USER_GOOGLE, encode({
            username: profile.givenName + profile.familyName,
            email: profile.email,
            token: response.accessToken,
            user_token: randomString(50),
            security_key: randomString(10),
            secret_user: randomString(44),
            secret_realtime: randomString(50),
            client_system: randomString(23),
            token_key: randomString(23),
            secret_token: randomString(25),
        }));
    }
};

// GoogleLoginButton with custom design
export const GoogleLoginButton = ({ onLoginSuccess }) => (
    <GoogleLogin
        clientId={CLIENT_ID}
        buttonText='Login with Google'
        onSuccess={onLoginSuccess}
        cookiePolicy={'single_host_origin'}
        responseType='code,token'
        render={renderProps => (
            <button 
                onClick={renderProps.onClick} 
                disabled={renderProps.disabled} 
                style={customButtonStyle}
            >
                <img 
                    src="https://cdn1.iconfinder.com/data/icons/logos-brands-in-colors/544/Google__G__Logo-1024.png" 
                    alt="Google Logo" 
                    style={googleLogoStyle}
                />
                <span style={buttonTextStyle}>Login with Google</span>
            </button>
        )}
    />
);

// Custom styles for the button
const customButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    color: 'black',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '40px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease',
};

const googleLogoStyle = {
    width: '20px',
    height: '20px',
    marginRight: '10px',
};

const buttonTextStyle = {
    fontSize: '16px',
    color:"gray"
};

// Adding hover effect with JavaScript inline styles
const hoverEffect = () => {
    customButtonStyle.backgroundColor = '#357ae8';
};

const leaveEffect = () => {
    customButtonStyle.backgroundColor = '#4285F4';
};

// Add hover and leave effects
document.querySelectorAll('button').forEach(button => {
    button.addEventListener('mouseover', hoverEffect);
    button.addEventListener('mouseleave', leaveEffect);
});
