import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import { __, Event, encode } from "../../../../Helper";
import C from "../../../../Constant";
import Gif from "./Gif";

class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            isLogged: storage.getKey('logged') !== null ? true : false,
            country: "GLOBAL"
        }
    }

    componentDidMount() {
        Event.on('call_user', data => {
            this.setState({ message: data })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.gif) {
            this.submitGif(nextProps.gif);
        }
    }

    handleChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        this.setState({ [name]: value });
    };

    submit = (e) => {
        e.preventDefault();

        if (this.state.message.trim() === '')
            return;

        socket.emit(C.ADD_CHAT, ({ message: this.state.message, country: this.state.country }));

        this.setState({ message: '' });
        Event.emit('scrolldone');
    };

    submitGif = (image) => {
        socket.emit(C.ADD_CHAT, ({ message: image, country: this.state.country }));
    };

    handleSubmit = (e) => {
        let char = e.which || e.keyCode;
        if (char === 13) {
            this.submit(e);
        }
    };

    render() {
        const { t } = this.props;
        const style = {
            backgroundColor: 'rgb(27 35 54)',
            color: '#F8F8F8',
            fontSize: 14,
            height: 90,
            padding: 10,
            fontFamily: 'Titillium Web'
        }
        return (
            <>
                <div className="chat-input">
                    <form onKeyPress={e => this.handleSubmit(e)}>
                        <div className="form-group mb-0 bordering">
                            <div className="input-group">
                                {(this.state.isLogged) ?
                                    <>
                                        <textarea style={style}
                                            onChange={this.handleChange} autoComplete={'off'} name={'message'}
                                            className="form-control" placeholder={t('message')} value={this.state.message} />
                                    </>
                                    :
                                    <>
                                        <textarea style={style}
                                            disabled={true} autoComplete={'off'} className="form-control" placeholder={t('message')} value={t('please_login_to_use_chat')} />
                                    </>
                                }
                            </div>
                            <div className="d-">
                                <button className="btn btn-send-chat btn-bldock px-4 mr-1 btn-xs" onClick={e => this.submit(e)}>
                                    <i className={"mdi mdi-code-less-than font-20"} />
                                </button>
                                <Gif />
                                <button className="btn btn-send-chat btn-bldock px-4 mr-1 btn-xs" disabled={true}>
                                    <i className={"mdi mdi-console font-20"} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

Submit.propTypes = {
    name: PropTypes.string,
    country: PropTypes.string,
    gif: PropTypes.string
};

const mapStateToProps = state => ({
    name: state.items.name,
    country: state.items.country,
    gif: state.items.gif
});

export default connect(mapStateToProps, {})(Submit);
