import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { __, wait, isMobile } from "../../Helper";
import slots from './Providers/Evo';

class Slots extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            posts: 17,
            loader: false,
            disabled: false,
            search: false,
            page: 1,
            slots: [],
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.setState({ slots: slots, loading: false })
        this.setupGames();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.home) return;

        let self = this;

        if (nextProps.search !== null && nextProps.search !== "") {
            this.setState({ games: [] });
            var find = __.find(slots, function (o) { return __.includes(__.lowerCase(o.gamename), __.lowerCase(nextProps.search)); });
            if (__.isUndefined(find)) return;
            this.setState(state => ({ games: [find, ...state.games] }));
            this.setState({ search: true })
        }
        else {
            this.setState({ search: false })
            this.setupGames();
        }
    }

    setupGames() {
        this.setState({ games: [] });

        var i = 0;

        if (this.props.req) {
            slots.forEach((item, c) => {
                if (c > 24) {
                    if (c <= 30) {
                        this.setState(state => ({ games: [item, ...state.games] }));
                    }
                }
            });
        }
        else {
            slots.forEach((item, i) => {
                if (this.props.home) {
                    if (i <= 5) {
                        this.setState(state => ({ games: [item, ...state.games] }));
                    }
                }
                else {
                    if (i <= this.state.posts) {
                        this.setState(state => ({ games: [item, ...state.games] }));
                    }
                }

                i++;
            });
        }
    }

    loadMore = (e) => {
        this.setState({ loader: true, disabled: true })
        let games = []

        slots.forEach((item, i) => {
            if (i <= this.state.posts + 6) {
                var find = __.find(this.state.games, function (o) {
                    return __.includes(__.lowerCase(o.gamename), __.lowerCase(item.gamename));
                });
                if (!find) {
                    games.push(item)
                }
            }
            i++;
        });

        wait(500).then(() => {
            this.addGames(games)
        })
    }

    addGames(games) {
        let add = this.state.games.concat(games);
        this.setState({ games: add });
        this.setState({ page: this.state.page + 1, loader: false, disabled: false, posts: this.state.posts + 6 })
    }

    render() {
        let col = !isMobile() ? 'col-6' : 'col-4';
        const games = this.state.games.map((item, i) =>
            <Col key={i.toString()} xl={2} sm={3} md={3} className={col + ' mb-2 ovh animated fadeInUp'}>
                <Link to={item.gamename !== null ? '/slots/' + item.gameid : '#'} className="slot-link">
                    <div className={item.gamename !== null ? 'slot-image' : 'slot-image orange'}>
                        <img src={item.default_image} className="img-fluid" alt={item.gamename} />
                    </div>
                    <div className="slot-title">
                        {item.gamename}
                    </div>
                </Link>
            </Col>
        );

        return (
            <>
                {this._isMounted &&
                    <>
                        {this.state.loading ?
                            <>
                                <div className="text-center ycenter">
                                    <div className="spinner-grow text-white my-1 user-loader" role="status" />
                                </div>
                            </>
                            :
                            <>
                                <Row id="allslots">
                                    {games}
                                </Row>
                                {(!this.props.home && !this.state.search && !this.props.req) &&
                                    <div className="text-center">
                                        <span className="badge btn-s-2 px-2 py-1 font-12">{this.state.page} / {slots.length}</span>
                                        <br />
                                        <button disabled={this.state.disabled} className="mt-2 btn btn-more my-3 px-4 shadow-none btn-sm animated fadeIn" onClick={this.loadMore}>
                                            {this.state.loader ?
                                                <div class="spinner-grow spinner-grow-sm align-middle mr-2" />
                                                :
                                                <i className="mdi mdi-arrow-right-bold-box align-middle mr-2" />
                                            }
                                            Load More
                                        </button>
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </>
        );
    }
}

export default Slots;
